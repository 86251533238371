	/*
  	Flaticon icon font: Flaticon
  	Creation date: 05/11/2019 13:58
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-home:before { content: "\f100"; }
.flaticon-upload:before { content: "\f101"; }
.flaticon-attach:before { content: "\f102"; }
.flaticon-shopping-cart:before { content: "\f103"; }
.flaticon-log-out:before { content: "\f104"; }
.flaticon-checklist:before { content: "\f105"; }
.flaticon-settings:before { content: "\f106"; }
.flaticon-user:before { content: "\f107"; }
.flaticon-users:before { content: "\f108"; }
.flaticon-clock-shop:before { content: "\f109"; }