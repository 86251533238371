html {
    font-size: 14px;
}
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #2f4576;
    border-radius: 1rem;
}
* {
    scrollbar-width: thin;
}
.left {
    align-items: left !important;
}
.reportContainer {
    width: 70% !important;
}
.transparentColor::-webkit-color-swatch {
    background-color: transparent !important;
}
.appSettings_icon {
    background: url("../img/app-settings.png") no-repeat 10px center;
    padding-left: 30px;
}
.faqs_icon {
    background: url("../img/faqs.png") no-repeat 10px center;
    padding-left: 30px;
}
.generalSettings_icon {
    background: url("../img/general-settings.png") no-repeat 10px center;
    padding-left: 30px;
}

.selectIndexFix > div {
    z-index: 1000;
}

.visibilty_hidden {
    visibility: hidden;
}
#nprogress .spinner {
    display: block;
    position: fixed;
    background: url("../img/main_loader.gif") no-repeat center,
        rgba(255, 255, 255, 0.5);
    z-index: 1031;
    top: 0px;
    right: 0px;
    left: 0;
    bottom: 0;
}
/*start Barcode color css with priority*/
.barcode-color-yellow {
    color: yellow;
}
.barcode-color-red {
    color: red;
}
/*end Barcode color css with priority*/
.backbutton + .content {
    padding-top: 0;
}

.backbutton {
    /* padding: 0 29px; */
    padding: 0 15px;
}
div.backbutton {
    margin-top: 10px;
    margin-bottom: 10px;
}
.backbutton .backButton {
    background: rgba(63, 63, 68, 0.1) !important;
    color: #666 !important;
    margin-bottom: 1px;
}

.backbutton .backButton img {
    transform: rotate(90deg);
}

.backbutton .backButton:hover img {
    animation: back 0.3s ease-in infinite alternate;
}

.initialsLogo {
    display: inline-flex;
    height: 35px;
    width: 35px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
}
.btnDark {
    background-color: #2c3356;
    color: #fff;
    border-radius: 4px !important;
    width: 100%;
    border-color: #2c3356;
    transition: cubic-bezier(0, 0.68, 1, 0.56) 0.4s;
}
.btnDark:hover,
.btnDark:focus {
    background-color: transparent;
    color: #2c3356 !important;
    border-color: #2c3356 !important;
}
.btnPurple {
    background-color: #806bff;
    color: #fff;
    border-radius: 6px !important;
    width: 100%;
    border-color: #806bff;
    transition: cubic-bezier(0, 0.68, 1, 0.56) 0.4s;
}
.btnPurple:hover,
.btnPurple:focus {
    background-color: transparent;
    color: #806bff !important;
    border-color: #806bff !important;
}
.backBtn {
    background-color: #2c3356;
    color: #fff;
    border-radius: 6px !important;
    border-color: #2c3356;
    position: absolute;
    left: 0;
    bottom: 0;
    /* top: 0;
    right: 0; */
    z-index: 10;
    transition: cubic-bezier(0, 0.68, 1, 0.56) 0.4s;
}
.backBtn:hover,
.backBtn:focus {
    background-color: transparent;
    color: #2c3356;
    border-color: #2c3356 !important;
}
.backBtnFix {
    padding-right: 80px;
}

.btnLightBlue {
    background-color: #0ed4ff;
    color: #2c3356 !important;
    border-radius: 6px !important;
    width: 100%;
    border-color: #0ed4ff;
    transition: all 0.4s ease-out 0.2s;
}
.btnLightBlue:hover,
.btnLightBlue:focus {
    background-color: #2c3356;
    color: #0ed4ff !important;
}
#editOrder .modal-dialog {
    max-width: 700px;
}

#editOrder .modal-dialog .table > tbody > tr > td {
    vertical-align: top !important;
}

.orderStatus > div + div {
    margin-left: 5px;
}

.orderStatus .cancelled {
    color: red;
    margin-left: 10px;
}

.orderStatus .onHold {
    color: orange;
    margin-left: 10px;
}

.orderStatus .unresolved {
    color: purple;
    margin-left: 10px;
}

#nprogress .spinner * {
    display: none !important;
}
.display_ib {
    display: inline-block !important;
}
#tabs li.selected a {
    color: blue !important;
}
.cardH110 {
    min-height: 110px;
}
.card {
    border-radius: 0;
    margin-top: 15px;
}
.card label {
    text-transform: capitalize;
    font-size: 14px;
    color: #393939 !important;
}
.capitalize {
    text-transform: capitalize;
}
.card .captilize-reset {
    text-transform: none;
}
.card.innerCard {
    border: none;
    box-shadow: none;
    background: #eaeef1;
    border-radius: 0;
}

.customBtn,
.customBtn:hover,
.customBtn:focus,
.customBtn:active {
    background: #2f4576;
    color: #fff;
    border: none;
}

.headerUserIcon {
    vertical-align: bottom;
    height: 28px;
}

.dropdown-toggle {
    cursor: pointer;
    font-weight: 300;
}

.headerLogin {
    margin-top: 15px;
}

.headerLogin .dropdown-menu {
    border-radius: 0;
}

.headerLogin.open .dropdown-menu {
    margin-top: 11px;
}

.headerLogin .dropdown-menu li,
.headerLogin .dropdown-menu li a {
    border-radius: 0;
}

.headerLogin .dropdown-menu li a img {
    margin-left: 5px;
}

.sideNavToggle.navbar-link {
    margin-top: 22px;
}

.react-datepicker-popper {
    z-index: 1000;
}

.flexElem.datesContainer > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.flexElem.datesContainer > div + div .form-control {
    border-left: none !important;
}

.react-date-picker .react-date-picker__wrapper {
    border: none;
    font-size: 14px;
}
.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled].active,
fieldset[disabled] .btn,
fieldset[disabled] .btn:hover,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn.active {
    background: #e1e1e8;
    color: #8a9bc1;
}
.customBtn[disabled] {
    background: #999;
    color: #fff;
    border: none;
}

.customBtn[disabled]:hover {
    background: #ccc;
    color: #fff;
    border: none;
}

.orderSettingText p.text-muted {
    font-size: 14px;
    line-height: 1.6;
    margin-top: 10px;
}

.title.headerTitle.collapsClosed {
    padding-bottom: 20px;
}

.card.innerCard .header .title.headerTitle {
    padding-left: 10px;
    margin-top: 12px;
    font-size: 17px;
}

.flexStyling {
    padding: 0;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    width: 100%;
}

.flex-center {
    justify-content: center;
}

.flexStyling .flexStylingChild {
    padding: 10px 15px;
}

.permission_label {
    width: 100%;
    height: 45px;
    background-color: #f8fcff;
    border: 1px solid #f8fcff;
    padding: 12px;
    margin: -5px;
    resize: none;
    overflow-y: hidden;
}
.search_field {
    margin-left: 20px;
    margin-top: -5px;
    margin-bottom: 3px;
    font-weight: normal;
    text-align: left;
    height: 40px !important;
    resize: none;
    overflow-y: hidden;
}
.permission_label:focus,
.permission_label:hover {
    border-color: #ddd;
    background: #fff;
}
.permissions tr th,
.permissions tr td {
    text-align: center;
}
.permissions tr td img {
    cursor: pointer;
    width: 13px;
}
.permissions thead tr th:first-child,
.permissions tbody tr td:first-child {
    text-align: left;
    background: #f8fcff;
    /*width: 40%;*/
    font-weight: 600;
}

.subNavi {
    position: fixed;
    top: 52px;
    right: 0;
    z-index: 1000;
    transition: all 0.3s;
}
.subNavi.fixedNavi {
    top: -10px;
}

.subNavi .backButton:last-child {
    background: #d94c3f !important;
}
.subNavi .backButton {
    margin-left: 0 !important;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper div {
    width: 100%;
}
.container-fluid .form-group {
    margin-bottom: 10px;
}

.flexStyling .flexStylingChild:nth-child(even) {
    border-left: 1px solid #ccc;
}

.flexStyling .flexStylingChild:last-child {
    border-left: 1px solid #ccc;
}

.customTable.tableLayoutFix.table tbody tr td {
    word-break: keep-all !important;
}

.tableLayoutFix {
    table-layout: fixed;
    width: 100%;
}
#nprogress .bar {
    background: red !important;
    z-index: 1000000000 !important;
}

.arrow_toggle {
    border: none;
    background: none;
    padding: 3px 10px;
    margin-right: 6px;
    float: left;
    border-radius: 0;
    margin-top: 7px;
    border-right: 1px solid #ccc !important;
}
.arrow_toggle > img {
    width: 16px;
}
.form-control {
    border-color: hsl(0, 0%, 80%);
    border-radius: 2px;
}
#add_agency .noHeightFormGroup .form-group {
    min-height: 10px;
}
[class$="-control"]:not(.form-control) {
    min-height: 40px;
}
textarea.form-control[name="mosaic_letter_text"] {
    height: 48px;
}

.header .title.headerTitle {
    padding-left: 39px;
    margin-top: 20px;
}

[class^="css-"] {
    border-radius: 0 !important;
}

.arrow_toggle img {
    transition: 0.3s all;
}

.arrow_toggle .arrow_up {
    transform: rotate(180deg);
}
#nprogress .peg {
    box-shadow: 0 0 10px red, 0 0 5px red !important;
}
#nprogress .spinner {
    z-index: 1000000000 !important;
}
#nprogress .spinner-icon {
    border-top-color: red !important;
    border-left-color: red !important;
}

.modal-header .close {
    background: tomato;
    opacity: 0.8;
    color: #fff;
    width: 21px;
    line-height: 20px;
    display: inline-flex;
    border-radius: 100%;
    outline: none;
    align-items: center;
    justify-content: center;
}

.modal-header .close:hover {
    background: tomato;
    opacity: 1;
    color: #fff;
}

.css-df17o1 {
    background: rgba(255, 255, 255, 0.7);
}

.password_icon {
    background: url("../img/password.png") no-repeat 10px center, #fff;
    padding-left: 30px;
}
.giudeTrans {
    padding: 10px;
    font-size: smaller;
    color: #666;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 0;
    margin-left: 20px;
    margin-top: 20px;
}

[name="transmittal_letter_text"] {
    min-height: 178px;
}

.giudeTrans li {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
}

.prevVidContainer {
    background: #f7f6f6;
    width: 100%;
    max-width: 320px;
    position: relative;
    transition: all 0.3s;
}

.prevImgContainer {
    background: #f7f6f6;
    /* padding: 10px;
    margin: 10px; */
    width: 120px;
    /* border: 1px solid #fff; */
    position: relative;
    transition: all 0.3s;
}
/* .prevImgContainer:hover,
.prevVidContainer:hover {
    border: 1px solid tomato;
} */
.prevImgContainer .prevRemove,
.prevVidContainer .prevRemove {
    position: absolute;
    top: -7px;
    right: -7px;
}

.prevImgContainer .prevRemove > span,
.prevVidContainer .prevRemove > span {
    display: inline-block;
    height: 15px;
    width: 15px;
    background: tomato;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    border-radius: 100%;
    text-align: center;
    line-height: 15px;
}

.prevImgContainer .prevName,
.prevVidContainer .prevName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.uploadImages {
    flex-wrap: wrap;
    justify-content: center;
    max-height: 400px;
    overflow-y: auto;
}

.uploadImages > span {
    flex-basis: 25%;
}

.lbl_container {
    text-align: left;
    padding-top: 0 !important;
    padding-left: 10px !important;
    padding-bottom: 0 !important;
    align-items: center;
}

.lbl_container label {
    margin-bottom: 0;
}

#add_agency .form-group {
    margin-bottom: 2px;
    min-height: 90px;
}

#add_agency .srv-validation-message {
    position: absolute;
}

.backButton {
    color: #fff !important;
    border: none !important;
    opacity: 1;
}
.backButton:hover {
    -webkit-filter: saturate(1.6);
    filter: saturate(1.6);
}
.backButton.success,
.backButton.success:focus,
.backButton.success:active {
    background: #4dc19c;
}
.backButton.warning,
.backButton.warning:focus,
.backButton.warning:active {
    background: #f29d21;
}
.backButton.danger,
.backButton.danger:focus,
.backButton.danger:active {
    background: #cd412f;
}
.backButton.primary,
.backButton.primary:focus,
.backButton.primary:active {
    background: #2f4576;
}
.backButton.info,
.backButton.info:focus,
.backButton.info:active {
    background: #20ace5;
}
.customTable.table thead tr th {
    white-space: nowrap;
    background: #2f4576 !important;
    color: #fff;
    font-weight: 500;
}

.search-input > input {
    width: inherit;
    height: inherit;
    box-sizing: border-box;
    margin-top: -9px;
    border: none;
    border-top: 1px solid hsl(0, 0%, 80%);
    border-bottom: 1px solid hsl(0, 0%, 80%);
    outline: none !important;
}

.customTable.table tbody tr td.dontWrap {
    white-space: nowrap !important;
}
.customTable.table tbody tr td {
    border-bottom-width: 1px !important;
    font-size: 12px !important;
    /*text-transform: uppercase !important;*/
    color: #9a9a9a !important;
    /*font-weight: 400;*/
    padding-bottom: 5px !important;
    word-break: break-all !important;
}
.email_icon {
    background: url("../img/email_icon.png") no-repeat 10px center, #fff;
    padding-left: 30px;
}
.phone_icon {
    background: url("../img/phone_icon.png") no-repeat 10px center, #fff;
    padding-left: 30px;
}
.fax_icon {
    background: url("../img/fax_icon.png") no-repeat 10px center, #fff;
    padding-left: 30px;
}
.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
    color: #fff;
}
.btn {
    opacity: 1 !important;
}
label {
    color: #5d5d5d !important;
    font-weight: 500 !important;
    font-size: 12px;
}
.headerPagination {
    display: none !important;
}
.actionBtn {
    padding: 5px 7px;
    border: none;
    background: #eee;
    color: #333;
    display: inline-flex;
    align-items: center;
    outline: none;
    border-radius: 4px;
}
.actionBtn svg {
    font-size: 16px;
    margin-right: 5px;
}

.actionBtn.primaryBtn {
    background: #2f4576;
    color: #fff;
}
.actionBtn.infoBtn {
    background: #1684e5;
    color: #fff;
}
.question_mark {
    display: inline-block;
    height: 18px;
    width: 18px;
    text-align: center;
    line-height: 18px;
    background: #fff;
    color: #999;
    border-radius: 100%;
    margin-left: 20px;
    cursor: default;
    border: none;
}
.question_mark:hover {
    background: #2f4576;
    color: #fff;
}
.badge {
    margin: 5px !important;
}
.sidebar .nav p {
    text-transform: capitalize;
    font-weight: 400;
}
.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
    text-transform: capitalize !important;
}
.CustomHeader {
    background-color: #2f4576;
}

.relativeCard {
    position: relative;
}
.absolute {
    position: absolute;
}

.ReactTable,
.customTable {
    color: #666;
}

.ReactTable .rt-thead {
    background-color: #2f4576 !important;
    color: #ffffff;
}
.ReactTable.-striped .rt-tr.-even {
    background: #f5f5f5;
}
/* .ReactTable.-striped .rt-tr.-even .commentUserDate{
  border-color: #ccc;
} */
.ReactTable.-striped .rt-tr.-odd {
    background: #fff;
}
.btn-toolbar .action-button-size,
.form-group .outOfTableButton,
button.outOfTableButton,
.form-group .outOfTableButton:active,
button.outOfTableButton:active {
    color: #363c77 !important;
    background: transparent !important;
}
.content a,
#tooltip a {
    color: #444;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    border-color: rgba(255, 255, 255, 0.2);
}
.ReactTable .rt-thead.-headerGroups {
    border-color: rgba(255, 255, 255, 0.2);
}
input {
    color: #333 !important;
}
.inputCustom,
.inputCustom > div {
    background-color: #e8edf2 !important;
    color: #333 !important;
    z-index: 1000;
}
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a0a0a0 !important;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: #a0a0a0 !important;
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: #a0a0a0 !important;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: #a0a0a0 !important;
}

.headerTitle {
    color: #2f4576 !important;
    font-weight: 600 !important;
}
.navbar-brand a {
    color: #ffffff;
}
.navbar-nav a {
    color: #ffffff !important;
}
.navbar-default .navbar-link {
    color: #ffffff !important;
}
/* .rt-tr {
    font-size: 10px !important;
} */
.span-chekBox {
    display: block;
    font-size: 9px;
    line-height: 10px;
    text-transform: capitalize;
}

.span-chekBoxLH {
    display: block;
    font-size: 9px;
    line-height: 3px;
    text-transform: capitalize;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
    white-space: pre-wrap;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-whiteF {
    background-color: white !important;
}
.bg-lightGray {
    background-color: #f2f2f2;
}
.bg-lightGray2 {
    background-color: #d9d9d9;
}
.bg-dark-blue {
    background-color: #2c3356;
}
body.modal-open .modalLG.modal-lg {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
}
body.modal-open .modalLG.modal-lg .modal-dialog {
    width: 100%;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-50 {
    margin-bottom: 0.5rem !important;
}
.mb-1 {
    margin-bottom: 1rem !important;
}
.mb-2 {
    margin-bottom: 2rem !important;
}
.mt-2-5 {
    margin-top: 2.5rem !important;
}
.mt-1 {
    margin-top: 1rem !important;
}
.my-1 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}
.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.me-50 {
    margin-right: 0.5rem !important;
}
.me-1 {
    margin-right: 1rem !important;
}
.ms-2 {
    margin-left: 1.5rem !important;
}
.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-2 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.px-2-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.py-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.px-0 {
    padding-left: 0;
    padding-right: 0;
}
.px-0F {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.py-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-50 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.p-1 {
    padding: 1rem;
}
.selectYear {
    height: 32px;
    padding: 0.25rem 1.25rem 0.25rem 0.5rem;
}
.stepCalculator {
    position: relative;
    top: 15px;
}
.stepActivated,
.stepVerified {
    position: relative;
}

.stepCalculator .bgLine {
    width: 75%;
    height: 2px;
    background: url(../img/grayline.svg) repeat-x;
    top: 16px;
    left: 12.5%;
    position: absolute;
}
.stepCalculator.step3 .bgLine {
    width: 67.5%;
    left: 16.25%;
}
.stepActivated::before,
.stepVerified::before {
    content: "";
    width: 100%;
    height: 2px;
    background: url(../img/blueline.svg) repeat-x;
    position: absolute;
    top: 16px;
    left: 50%;
    z-index: 10;
}
.stepActivated .vLine::after,
.stepVerified .vLine::after {
    content: "";
    background: url(../img/blueLineVertical.svg) repeat-y;
    position: absolute;
    top: 0px;
    left: 0;
    width: 2px;
    height: 100%;
}
.borderLR,
.borderRight,
.borderLeft {
    padding-bottom: 25px;
    position: relative;
}
.borderLeft::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 2px;
    /* background-color: blue; */
    top: 16px;
    left: 0;
}
.borderRight::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 2px;
    background: url(../img/blueline.svg) repeat-x;
    top: 16px;
    right: 0;
}
/* .borderLR::after,
.borderLR::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 16px;
}
.borderLR::before {
    left: 0;
}
.borderLR::after {
    right: 0;
} */
.borderLR span,
.borderRight span,
.borderLeft span {
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    background-color: #e1e1e8;
    border-radius: 10rem;
    position: relative;
    z-index: 50;
}
.borderLR span::before,
.borderLR span::after {
    content: "";
    position: absolute;
    top: 100%;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: linear-gradient(
            180deg,
            transparent,
            transparent 50%,
            red 50%,
            red 100%
        ),
        linear-gradient(180deg, #69d2e7, #a7dbd8, #e0e4cc, #f38630, #fa6900);
}
.borderLR span:nth-child(2),
.borderRight span:nth-child(2),
.borderLeft span:nth-child(2) {
    position: absolute;
    width: 2px;
    height: 80%;
    top: 20%;
    left: 50%;
    z-index: 0;
    background: url(../img/graylineVertical.svg) repeat-y;
}

.flexElemInline {
    display: inline-flex;
}
.flexElem {
    display: flex !important;
}
.flexColumn {
    flex-direction: column;
}
.flexRowF {
    flex-direction: row !important;
}
.flexNoWrap {
    flex-wrap: nowrap;
}
.flexWrap {
    flex-wrap: wrap;
}
.alignStart {
    align-items: flex-start;
}
.alignCenter {
    align-items: center;
}
.alignEnd {
    align-items: flex-end;
}
.justifyCenter {
    justify-content: center;
}
.justifyStart {
    justify-content: flex-start !important;
}
.justifyEnd {
    justify-content: flex-end !important;
}
.spaceBetween {
    justify-content: space-between;
}
.gap5 {
    gap: 5px;
}
.gap10p {
    gap: 10px;
}
.gap25 {
    gap: 25px;
}
.gridAd {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
    margin: 0 auto;
}
.gridSplash {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 12px;
    margin: 0 auto;
}
.adWrap {
    cursor: pointer;
    position: relative;
    min-height: 140px;
}
.splashWrap {
    cursor: pointer;
    position: relative;
    min-height: 200px;
    border: 1px solid #f8f7f7;
}
.splashDummyBG {
    background: url(../img/caret-video.svg) no-repeat center / cover;
}
.splashTitle {
    font-size: 11px;
    text-transform: capitalize;
}
.splashSubTitle {
    font-size: 13px;
    text-transform: capitalize;
    margin-top: 6px;
    font-weight: 600;
    margin-bottom: 10px;
}
.bgBlur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    filter: blur(5px) opacity(0.3);
}
.bgBlur + .adImage {
    object-fit: scale-down;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    padding: 0px;
    top: 50%;
    height: auto;
    width: auto;
    max-width: 100%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.adImage {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.adContent {
    width: 100%;
    height: 100%;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    transition: all 0.3s ease-out 0.2s;
}
.adWrap:hover .adContent,
.splashWrap:hover .adContent {
    background: rgba(255, 255, 255, 0.85);
    visibility: visible;
}
.packagePlan {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
}
.package,
.boxPlan {
    transition: all 0.4s ease-out 0.2s;
    cursor: pointer;
}
.package h4 {
    text-transform: capitalize;
}
.package:hover,
.boxPlan:hover {
    background-color: #eae7fc;
}
.package.current {
    background-color: #eae7fc;
}

.boxPlan {
    background-color: #eae7fc;
}
.paymentInfo {
    border-left: 1px solid #ababab;
}
.modal800 .modal-dialog,
.modal800 .modal-content {
    width: 100%;
    max-width: 800px;
}
.shadow {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}
.verticalTabs {
    border: none;
    list-style-type: none;
    counter-reset: counter;
}
.verticalTabs li {
    position: relative;
    cursor: pointer;
}
.verticalTabs li::before {
    content: counter(counter);
    counter-increment: counter;
    margin-right: 0.5em;
    position: absolute;
    left: 0;
    top: 8px;
    background-color: #a8a8a8;
    color: #fff;
    width: 24px;
    height: 24px;
    border-radius: 5rem;
    font-size: 13px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.verticalTabs li.active::before {
    content: "";
    background: #0078ff url(../img/checkWhite.svg) no-repeat center;
    background-size: 16px;
}
.verticalTabs.nav-tabs li a.nav-link {
    color: #212121;
    margin: 0;
    border: none;
    margin-left: 24px;
}
.verticalTabs.nav-tabs li.active a.nav-link {
    color: #0078ff;
}
.verticalTabs.nav-tabs li a:hover {
    border: none;
}
.trafficCard {
    background-color: #f2c1b5;
    border-radius: 8px;
}
.conversionCard {
    background-color: #bbb6f6;
    border-radius: 8px;
}
.appCard {
    background-color: #ffabc1;
    border-radius: 8px;
}
.leftAligned.ReactTable .rt-th,
.leftAligned.ReactTable .rt-td {
    justify-content: start;
}
.actionMenu.show.dropdown .dropdown-menu {
    visibility: visible;
    opacity: 1;
    height: 38px;
    right: 0;
    left: auto !important;
    transform: translate3d(-40px, -1px, 0px) !important;
    display: flex !important;
    gap: 6px;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.4s;
}
.actionMenu.show.dropdown .dropdown-menu .dropdown-item {
    border: 0;
    padding: 0px 10px;
    background: transparent;
}
.actionMenu.show.dropdown .dropdown-menu .dropdown-item:hover img,
.actionMenu.show.dropdown .dropdown-menu .dropdown-item:focus img,
.actionMenu.show.dropdown .dropdown-menu .dropdown-item:active img {
    filter: brightness(0.4);
}
.signupForm {
    /* max-width: 760px; */
    max-width: 400px;
    margin: 0 auto;
}
.industryForm,
.industryFormEdit {
    max-width: 760px;
    margin: 0 auto;
}
.inputTransparentRound,
.inputGroup .inputTransparentRound {
    border-color: #dfdfdf;
    line-height: 2.3 !important;
    height: auto;
    background: rgba(255, 255, 255, 0.8);
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2rem;
    margin-bottom: 20px;
    color: #12064b !important;
    font-weight: 600;
    letter-spacing: 1px;
}
.industryFormEdit .inputTransparentRound {
    color: #212121 !important;
}
.inputTransparentRound:focus,
.inputTransparentRound:hover,
.inputTransparentRound:active,
.inputGroup .inputTransparentRound:hover,
.inputGroup .inputTransparentRound:focus,
.inputGroup .inputTransparentRound:active {
    background-color: white;
    color: #212121 !important;
}
.industryFormEdit .inputGroup .input-group-text,
.industryFormEdit .inputGroup2 .input-group-text,
.inputGroup .input-group-text,
.inputGroup2 .input-group-text {
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 5rem 0 0 5rem;
    font-size: 1.6rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-color: white;
    border-width: 1px 0 1px 1px;
    background-color: rgba(255, 255, 255, 0.6);
}
.industryFormEdit .inputGroup .input-group-text {
    background: #e5e4e4;
    border: 1px solid #e3e3e3;
}
.inputGroup2 .input-group-text {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.3rem;
}
.inputGroup2 .input-group-text:nth-child(2) {
    border-radius: 0;
    border-style: solid;
    border-color: white;
    border-width: 0;
}
.industryFormEdit .inputGroup2 .input-group-text {
    background: #e5e4e4;
    border: 1px solid #e3e3e3;
}
.industryFormEdit .inputGroup2 .input-group-text:nth-child(2) {
    border-radius: 0;
    border-style: solid;
    border-color: #e3e3e3;
    border-width: 0;
    padding: 0 0.5rem;
    background-color: #e3e3e3;
}
.colorPickerRound {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border: 1px solid #fff;
    margin-bottom: 5px;
}

.colorPickerRound .inputColor {
    height: 40px !important;
    width: 40px !important;
    padding: 0px !important;
    border: none !important;
}

.colorPickerRound .inputColor > span {
    border: none;
    padding: 0;
    margin: 0;
    width: 30px;
    height: 30px;
}

/*.colorPickerRound input{
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
   cursor: pointer;
   transform: scale(1.5);
}*/
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    font-weight: 400;
}
.videoUploaderSection {
    align-items: flex-start;
}

.videoUploaderSection > div {
    width: 100%;
}

.blue_label {
    color: #1684e5;
}

.blue_link,
.linkColor {
    color: #1684e5 !important;
    display: inline-block;
}

.blue_link.target_blank {
    background: url("../img/external-link-symbol.png") no-repeat 0 3px;
    background-size: 16px;
    padding-left: 25px;
    transition: all 0.2s;
}

.blue_link.target_blank:hover {
    background-position: 3% 2px;
}

.blue_link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.orderContent {
    border: 1px solid #ccc;
    margin-top: 30px;
}
.font-boldF {
    font-weight: 700 !important;
}
.fontNormal {
    font-weight: 400;
}
.text-left {
    text-align: start;
}
.text-capitalize {
    text-transform: capitalize;
}
.text-48 {
    font-size: 48px !important;
}
.text-36 {
    font-size: 36px !important;
}
.text-24 {
    font-size: 24px !important;
}
.text-20 {
    font-size: 20px !important;
}
.text-18 {
    font-size: 18px !important;
}
.text-16 {
    font-size: 16px !important;
}
.text-15 {
    font-size: 15px;
}
.text-14 {
    font-size: 14px;
}
.text-12 {
    font-size: 12px;
}
.text-10 {
    font-size: 10px;
}
.text-primary {
    color: #2f4576 !important;
}
.text-dark {
    color: #212121;
}
.text-wrap {
    word-wrap: break-word !important;
}
.text-shadow {
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.9);
}
.highlight_blue {
    background: #a9d1eb;
}

.highlight_green {
    background: #e3f4d0;
}

.orderTable.table > tbody + tbody {
    border: none;
}

.sepSections .orderContent.hasChild {
    margin-top: 0;
    border: none;
}
.sepSections .orderContent.hasChild .orderContent {
    width: 100%;
}

.secHeading {
    font-size: 28px;
    color: #2f4576;
    margin: 15px 0;
    padding: 0px;
    margin-bottom: 25px;
    font-weight: 600;
}

.orderTopNav {
    font-size: 16px;
    color: #2f4576;
    margin: 25px 0;
}

.orderTopNav .barcodeContainer {
    display: inline-block;
    margin-left: 10px;
    padding: 10px;
    color: #2f9ae4;
}

.sepSections.flexElem {
    justify-content: space-between;
}

.sepSections .orderContent:first-child {
    width: calc(35% - 15px);
}

.sepSections .orderContent:last-child {
    width: calc(65% - 15px);
}

.orderDetailsHeader {
    font-size: 16px;
    color: #2f4576;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.commentsContainer .ReactTable {
    border: none;
}

.editAdmin > div {
    width: 100%;
}

.editAdmin a {
    color: #666;
    cursor: pointer;
}

.last_three_rows tr:nth-last-child(1),
.last_three_rows tr:nth-last-child(3) {
    background: #dde3f1;
}

.last_three_rows tr:nth-last-child(2) {
    background: #f2f5fa;
}

.coomentAvatar {
    border-radius: 100%;
    height: 50px;
    opacity: 1;
}

.commentUserDate {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.commentUserDate > span {
    margin-left: 10px;
}

.commentUserDate > span + span {
    margin-left: 30px;
}

.commentUserDate + div {
    padding-left: 10px;
}

.flexElem.flexResponsive.quickLink input {
    width: 100%;
    background: transparent;
    text-indent: 7px;
    padding-right: 7px;
    color: #1684e5 !important;
    border: none;
    outline: none;
}

.block {
    width: 100%;
}
.table.blank,
.table.blank tbody tr,
.table.blank tbody tr td {
    border: none;
    padding: 5px;
}
.table.orderStatusContainer thead tr th {
    background: #cae0ee;
    color: #2f4576;
    font-weight: 600;
    font-size: 13px;
}
.agencyLogoContainer {
    min-width: 130px;
    max-width: 130px;
    width: 100%;
    padding: 7px;
    text-align: center;
}

.agencyLogoContainer img {
    max-width: 100%;
    width: auto;
    height: auto;
}

.agencyLogoContainer .agencyLogo.secondaryLogo {
    width: 80%;
    margin-top: 7px;
}

.orderDetailsAction.button_disabled {
    background-color: transparent !important;
    border: none !important;
    opacity: 0.5;
    cursor: not-allowed;
}

.orderDetailsAction.editAction {
    background: url("../img/edit_order_icon.png") no-repeat left center;
    padding-left: 22px;
}

.orderDetailsAction.copyAction {
    background: url("../img/copy.svg") no-repeat left center; /*url('../img/copy_icon.png') no-repeat left center;*/
    padding-left: 22px;
}

.orderDetailsAction.copyColorAction {
    background: url("../img/copy.svg") no-repeat left center;
    padding-left: 22px;
}

.hasInputIcons {
    position: relative;
}

.hasInputIcons .inputIcons {
    position: absolute;
    right: 7px;
    top: calc(50% - 19px);
    z-index: 1000;
}

.editComment {
    background: url("../img/comments_edit_action.png") no-repeat left center;
    padding-left: 22px;
}

.deleteComment {
    background: url("../img/comments_delete_action.png") no-repeat left center;
    padding-left: 22px;
}

.highlightComment {
    background: url("../img/comments_highlight_action.png") no-repeat left
        center;
    padding-left: 22px;
}

.trackIcon {
    background: url("../img/tracker_icon.png") no-repeat left center;
    padding-left: 22px;
}

.editIcon {
    background: url("../img/edit_order_icon.png") no-repeat left center;
    padding-left: 22px;
}

.closeIcon {
    background: url("../img/close_icon.png") no-repeat left center;
    padding-left: 22px;
}

.addIcon {
    background: url("../img/add_icon.png") no-repeat left center;
    padding-left: 22px;
}

.adjustIcon {
    background: url("../img/adjust_icon.png") no-repeat left center;
    padding-left: 22px;
}

.assignIcon {
    background: url("../img/assign_icon.png") no-repeat left center;
    padding-left: 22px;
}

.clearIcon {
    background: url("../img/clear_all_icon.png") no-repeat left center;
    padding-left: 18px;
}

.finalizeIcon {
    background: url("../img/finalize_icon.png") no-repeat left center;
    padding-left: 18px;
}

.downloadIcon {
    background: url("../img/download_icon.png") no-repeat left center;
    padding-left: 22px;
}
.downloadIcon.downloadVideo {
    background: url("../img/download_video.png") no-repeat left center;
    height: 25px;
    padding-left: 16px;
}
.downloadIcon.downloadHighlightVideo {
    background: url("../img/download_highlight_video.png") no-repeat left center;
    height: 25px;
    padding-left: 16px;
}
.deleteIcon {
    background: url("../img/delete_icon.png") no-repeat left center;
    padding-left: 20px;
}

.uploadOnly {
    background: url("../img/eq_not_returning.png") no-repeat left center;
    padding-left: 22px;
}

.returningIcon {
    background: url("../img/returning_icon.png") no-repeat left center;
    padding-left: 22px;
}

.reprintIcon {
    background: url("../img/reprint_lab.png") no-repeat left center;
    padding-left: 22px;
}

.flagIcon {
    background: url("../img/flag_order_icon.png") no-repeat left center;
    padding-left: 22px;
}

.caretIcon {
    background: url("../img/carets.png") no-repeat left center;
    padding-left: 22px;
}
.breakText {
    word-break: break-all;
    word-wrap: break-word;
}

.orderDetailsAction.refreshAction {
    background: url("../img/refresh_order_icon.png") no-repeat left center;
    padding-left: 22px;
}

.orderDetailsAction.small_icon {
    background-size: 24%;
    padding-left: 16px;
    font-size: 12px;
}

.orderDetailsAction {
    color: #333;
    font-size: 14px;
    cursor: pointer;
    border: none;
    outline: none;
}

.orderDetailsAction[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
}

.orderDetailsBox {
    border: 1px solid #ccc;
    border-bottom: none;
    position: relative;
}
.shadowNone {
    box-shadow: none;
}
.borderNone {
    border: none;
}
.border {
    border: 1px solid #ccc;
}
.borderR {
    border-right: 1px solid #ccc;
}
.borderL {
    border-left: 1px solid #ccc;
}
.borderB {
    border-bottom: 1px solid #ababab;
}
.borderPurple {
    border: 1px solid #806bff;
}
.border-x-0 {
    border-left: none;
    border-right: none;
}
.border-0F {
    border: none !important;
}

.sepSections .orderDetailsBox {
    width: 100%;
    border-left: none;
    border-right: none;
}

.orderDate {
    display: inline-block;
    background: url("../img/calender_icon.png") no-repeat left center;
    padding-left: 20px;
}

.orderStatusContainer {
    justify-content: space-evenly;
    margin-top: 0;
}

.orderStatusContainer:last-child {
    margin-bottom: 20px;
}

.orderStatusContainer > div {
    width: calc(33.333333% - 25px);
    padding: 10px 20px;
    margin-top: 15px;
    text-align: center;
    cursor: pointer;
}

.processCamera {
    background: #e3f4d0;
}
.receiveOrder {
    background: #eac3fc;
}
.videoShipment {
    background: #a9d1eb;
}
.awarenessFlyer {
    background: #f9ddb5;
}
.returnEquipment {
    background: #aac9f8;
}
.printOrder {
    background: #f8aac9;
}

.innerLabel {
    font-weight: normal;
    color: #1684e4;
}

.orderDetailsBox.box_small {
    width: calc(35% - 15px);
    border-left: none;
}

.orderDetailsBox.box_large {
    width: calc(65% - 15px);
    border-right: none;
}

.orderDetailsBox .orderTable tr td {
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}

.iconOutlineBtn {
    display: inline-block;
    background: none;
    color: rgb(47, 69, 118) !important;
    box-shadow: 0 0 0 1px rgb(47, 69, 118);
    border-radius: 2px;
    padding: 3px 5px;
    transition: 0.2s all;
}

.iconOutlineBtn i {
    margin-right: 3px;
}

.iconOutlineBtn:hover {
    color: #fff !important;
    background: rgb(47, 69, 118);
}

.customStrips tbody tr:nth-child(odd) {
    background-color: #eee;
}

.orderDetailsBox .orderTable tr td .resetOuterPadding tr td:first-child {
    padding-left: 0;
    padding-right: 5px;
}

.orderDetailsBox .orderTable tr td .resetOuterPadding tr td:last-child {
    padding-right: 0;
    padding-left: 5px;
}

.orderDetailsBox .orderTable tr td.td_heading {
    background: #dde3f1;
    padding-top: 7px;
    padding-bottom: 7px;
}

.orderTabs .react-tabs > ul {
    display: flex;
    border: none;
    align-items: flex-end;
    min-height: 47px;
}

.orderTabs .react-tabs > ul > li {
    width: 100%;
    margin: 0 1px;
    text-align: center;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #2f4576;
    border-radius: 2px;
    color: #fff;
    transition: all 0.3s;
}

.orderTabs .react-tabs > ul > li.react-tabs__tab--selected {
    background: #1684e5;
    padding-top: 13px;
    padding-bottom: 13px;
}

.orderTabs.colored .react-tabs > ul {
    align-items: flex-start;
    min-height: 75px;
}

.orderTabs.colored .react-tabs > ul > li {
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 0;
}

.orderTabs.colored .react-tabs > ul > li.react-tabs__tab--selected {
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0 7px 0 0px #f29d21;
}

.orderTabs.colored .react-tabs > ul > li:nth-child(1),
.alert-info {
    background: #79b6dd !important;
}

.orderTabs.colored .react-tabs > ul > li:nth-child(2),
.alert-primary {
    background: #6d73ed !important;
}

.orderTabs.colored .react-tabs > ul > li:nth-child(3),
.alert-danger {
    background: #f181ad !important;
}

.orderTabs.colored .react-tabs > ul > li:nth-child(4),
.alert-warning {
    background: #e6bd82 !important;
}

.orderTabs.colored .customUploader + div > div,
.customUploader + div > div {
    border: 1px solid #bebebe !important;
    background: url("../img/dorpzon_placeholder.png") no-repeat center 20px,
        #eaeef6 !important;
    padding-top: 100px;
    padding-bottom: 20px;
}

.orderTabs.colored .customUploader + div > div:hover,
.customUploader + div > div:hover {
    border-style: dashed !important;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
}

.appCardActions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.dropzone_profileImage > div > div {
    margin-right: 10px;
    border: 1px dashed #bebebe !important;
    background: url("../img/dorpzon_placeholder.png") no-repeat center 20px,
        #fff !important;
    background-size: contain;
    padding: 10px;
    text-align: center;
    min-height: 130px;
    padding-top: 83px;
}

.pofilePicContainer .content {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pofilePicContainer .content .prevImgContainer {
    margin: 0;
    background: #fff;
}

.blueStyle thead {
    background: #2f4576;
    color: #fff;
    font-size: 14px;
}

.blueStyle thead tr td {
    padding: 5px 10px;
    border-bottom: none;
}

.blueStyle tbody tr td {
    border: none;
}

.blueStyle tbody tr:nth-child(odd) {
    background: #fff;
}

.blueStyle tbody tr:nth-child(even) {
    background: #f5f5f5;
}

.dropzone_profileImage > div > div p.hello {
    font-size: 12px !important;
}

.dropzone_profileImage > div > div:hover {
    border-style: dotted !important;
}
.dropzone_profileImage {
    padding: 0.75rem;
    background-color: #f7f6f6;
    cursor: pointer;
}
#playVid {
    max-width: 100%;
}
.videoPreview {
    width: 100%;
    max-width: 270px;
    height: 100%;
    max-height: 360px;
    min-height: 320px;
    display: flex;
    margin: 0 auto;
    background-color: #f3f3f3;
}
.videoPreviewSmall {
    margin: 2.5rem auto 1.5rem;
    width: 100%;
    max-width: 270px;
    height: 100%;
    max-height: 360px;
    min-height: 320px;
    display: flex;
    margin: 0 auto;
    background-color: #f3f3f3;
}
.appCardActions > div {
    flex-basis: calc(33.3333% - 10px);
    margin: 5px;
}

.appCardActions > div .appCardBtn {
    padding: 15px;
    min-height: 100px;
    display: flex;
    background: #666;
    color: #fff;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.appCardActions > div .appCardBtn > img {
    margin-right: 10px;
}

.appCardActions > div .appCardBtn > span {
    flex-basis: 70%;
}

.appCardActions > div:nth-child(1) .appCardBtn {
    background: #79b6dd;
}

.appCardActions > div:nth-child(2) .appCardBtn {
    background: #6d73ed;
}

.appCardActions > div:nth-child(3) .appCardBtn {
    background: #f181ad;
}

.appCardActions > div:nth-child(4) .appCardBtn {
    background: #b075dd;
}

.appCardActions > div:nth-child(5) .appCardBtn {
    background: #d9ad6e;
}

.appCardActions > div:nth-child(6) .appCardBtn {
    background: #5e7fc7;
}

.appCardActions > div:nth-child(7) .appCardBtn {
    background: #ed6d6d;
}

.appCardActions > div:nth-child(8) .appCardBtn {
    background: #73ce91;
}

span.td_barcode {
    color: #1684e5;
    display: inline-block;
    width: 100%;
}

span.td_orderId {
    background: #f9aac9;
    display: inline-block;
    width: 100%;
    max-width: 150px;
    text-align: center;
    padding: 5px 7px;
}

.orderDetailsBox .orderTable {
    margin: 10px auto;
}

.orderSettingText .flexElem strong {
    align-self: center;
}

.speech-bubble-container {
    position: absolute;
    top: calc(-9% - 24px);
    right: 5px;
}

.speech-bubble {
    position: relative;
    background: #ff0000;
    border-radius: 5em;
    display: block;
    height: auto;
    width: auto;
    padding: 2px 8px;
}

.speech-bubble:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 0.7em solid transparent;
    border-top-color: #ff0000;
    border-bottom: 0;
    border-left: 0;
    margin-left: -15%;
    top: calc(100% - 2px);
}

.orderTable.table-striped > tbody > tr:nth-of-type(odd) {
    background: #dde3f1;
}

.orderTable.table-striped > thead {
    background: #e3f4d0;
    color: #333;
}

.orderTable.table-striped > thead > tr > th {
    color: #333;
}

.no_bg {
    background: transparent !important;
}

.orderTable.table-striped.evenly > tbody > tr {
    background: #fff;
}

.orderTable.table-striped.evenly > tbody > tr:nth-of-type(even) {
    background: #dde3f1;
}

.orderSettingText .react-datepicker-wrapper,
.react-datepicker-wrapper div {
    width: auto;
}
.fullCalendar .react-datepicker-wrapper div {
    width: 100%;
}

.orderSettingText .flexElem > *:not(:first-child) {
    margin-left: 10px;
}

.imgIconMarRight {
    margin-right: 5px;
    width: 20px;
}

.sidebar .nav li > span {
    color: #ffffff;
    margin: 5px;
    opacity: 0.86;
    border-radius: 4px;
    position: relative;
    display: block;
    padding: 12px 15px;
    align-items: flex-start;
    width: 100%;
    border-radius: 0;
    transition: 0.2s all;
}

.sidebar .nav li > span > img {
    margin-top: 5px;
}

.sidebar .nav li > span a svg {
    transition: 0.2s all;
    margin-right: 7px;

    position: absolute;
    right: 15;
}

.sidebar .nav li > span a {
    font-size: 15px;
    font-weight: 200;
    font-family: "Poppins", sans-serif;
    width: 100%;
}

.sidebar .nav li > span a.collapsed svg {
    transform: rotate(-90deg);
}
.sidebar .nav li > span.collapsed svg {
    transform: rotate(-90deg);
}

.sidebar .nav > li > span:hover {
    background: rgba(255, 255, 255, 0.1);
}

.sideChildNav {
    background: rgba(26, 28, 39, 0.7);
    margin: 0 15px;
}

.sidebar .nav .sideChildNav li > span {
    padding: 5px 5px;
}

.sidebar .nav li > span.hasChildNav a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.sidebar .nav li.hasChildNav a {
    display: flex;
    align-items: center;
    width: 100%;
}

.sidebar .nav .sideChildNav li + li {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.navIcon {
    font-size: 18px;
    margin-right: 5px;
}

.downloadCsv {
    text-align: right;
}

.downloadCsv button {
    background: url("../img/download_icon.png") no-repeat 13px center, #eee;
    border: 1px solid #ccc;
    color: #3b3b3b;
    padding: 10px 13px;
    padding-left: 40px;
    transition: 0.3s all;
}

.downloadCsv button:hover {
    background-color: #ccc;
}

.sidebar .nav li > span button {
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 1.5em;
    margin-left: 5px;
    transition: 0.2s all;
}

.sidebar .nav li.openSubMenu > span button {
    transform: rotate(180deg);
}
.sidebar .nav li {
    border-radius: 0 !important;
    cursor: pointer;
}
.sidebar .nav li.active {
    background: #1f2140 !important;
}
.sidebar .nav li > a {
    margin: 6px 12px 6px 3px !important;
}
.sidebar .nav li.active > a {
    background: #2b2d3a !important;
}
.sidebar .nav li > .subnav {
    list-style: none;
    display: none;
    padding-left: 0px;
    transition: 0.2s all;
}

.sidebar.showSideNav .nav li > .subnav {
    padding-left: 15px;
}

.sidebar .nav li > .subnav li {
    background: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.3s all;
}

.sidebar .nav li > .subnav li:last-child {
    border-bottom: none;
}

.sidebar .nav li > .subnav li:hover {
    background: rgba(255, 255, 255, 0.1);
}

.sidebar .nav li.openSubMenu > .subnav {
    display: block;
    overflow: hidden;
    animation: show 0.5s;
}

.sidebar .nav li.openSubMenu > .subnav > li a {
    text-transform: lowercase !important;
    font-size: 12px;
}

.sidebar .nav li.openSubMenu > .subnav > li a::first-letter {
    text-transform: uppercase !important;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
    transition: all 0.34s;
    -webkit-transition: all 0.34s;
    -moz-transition: all 0.34s;
}

.all-icons [class*="pe-"] {
    font-size: 40px;
}

.dateSelector .react-datepicker-wrapper,
.dateSelector .react-datepicker__input-container {
    display: block;
    width: 100%;
}
.all-icons input {
    border: 0;
}

/* The container */
.container-check {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    /*font-size: 12px;*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 2;
}

/* Hide the browser's default checkbox */
.container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.container-check .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 0;
    background-color: #fff;
    border: 1px solid #ccc;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
    background-color: #2196f3;
}

.container-check input.disabled:checked ~ .checkmark {
    background-color: #cccccc;
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container-check.checkbox_down {
    padding-left: 0;
}

.container-check.checkbox_down .checkmark {
    left: 3px;
    position: relative;
    display: block;
}

.loaderCustom {
    display: inline-block;
    margin-bottom: 15px;
}

.innerUiSettingsCard .header .title.headerTitle {
    padding-left: 0;
}

.innerUiSettingsCard ul {
    padding-left: 0;
    list-style: none;
}

.innerUiSettingsCard ul li {
    padding: 5px 7px;
    border-radius: 4px;
}

.innerUiSettingsCard ul li + li {
    margin-top: 10px;
}

.innerUiSettingsCard ul li:nth-child(1) {
    background: red;
    color: #fff;
}

.innerUiSettingsCard ul li:nth-child(2) {
    background: green;
    color: #fff;
}

.innerUiSettingsCard ul li:nth-child(3) {
    background: pink;
    color: #000;
}

.innerUiSettingsCard ul li:nth-child(4) {
    background: lightblue;
    color: #000;
}

/* The container rd */
.container-radio {
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-start;
    width: auto;
}

.container-radio.no_label {
    padding-left: 0;
}

/* Hide the browser's default checkbox */
.container-radio input {
    position: absolute !important;
    opacity: 0;
    z-index: -11;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 22px;
    border-radius: 100%;
    border: 1px solid #ccc;
    width: 22px;
    background-color: #eee;
}

.container-radio .checkmark {
    position: relative;
    top: 0;
    margin-right: 7px;
}
/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
    background-color: #2196f3;
    border-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-radio .checkmark:after {
    left: calc(50% - 5px);
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #fff;
}

.all-icons .font-icon-detail {
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}
.all-icons .font-icon-detail input {
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}
.btnBlue {
    background-color: #2f4576;
    color: white;
    border: 1px solid #2f4576;
    transition: all 0.3s ease-out;
}
.btnBlue:hover,
.btnBlue:focus,
.btnBlue:active {
    background-color: white;
    color: #2f4576;
}
.btnLogin {
    background-color: #806bff;
    line-height: 2.2;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    border-radius: 5rem;
    border-color: #806bff;
    transition: all 0.4s ease-out;
}

.btnSignup,
a.btnSignup {
    background: #3472f7 !important;
    border-color: #3472f7;
    text-transform: uppercase;
    color: #fff !important;
    font-size: 16px !important;
    border-radius: 5rem;
    padding: 1rem 2rem !important;
}
.btnLogin:hover,
.btnSignup:hover {
    border-color: #806bff;
    color: #fff;
    background-color: transparent !important;
}
.btn_login {
    background: #3472f7;
    border-color: #3472f7;
    color: #fff !important;
    width: 100%;
    line-height: 2.2;
    font-size: 15px;
    background: #2f9ae4;
    border-color: #2f9ae4;
    border-width: 1px;
    border-radius: 5rem;
}
.btn_block {
    width: 100% !important;
}

.btn_login:hover,
.btn_login:focus {
    background: #fff;
    border-color: #2f9ae4;
    color: #2f9ae4 !important;
}
.yellowBar {
    width: 100%;
    height: 10px;
    background-color: #ffc90e;
}
#map {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
}
.nav li.active {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}
.places-buttons .btn {
    margin-bottom: 30px;
}
.sidebar .nav > li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
}
.sidebar .nav > li.active-pro a {
    background: rgba(255, 255, 255, 0.14);
    opacity: 1;
    color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
    text-align: center;
}

.fixed-plugin {
    position: absolute;
    top: 180px;
    right: 0;
    width: 64px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
}
.fixed-plugin .fa-cog {
    color: #ffffff;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
}
.fixed-plugin .dropdown-menu {
    right: 80px;
    left: auto;
    width: 290px;
    border-radius: 10px;
    padding: 0 10px;
}
.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
    right: 10px;
    margin-left: auto;
    left: auto;
}
.fixed-plugin .fa-circle-thin {
    color: #ffffff;
}
.fixed-plugin .active .fa-circle-thin {
    color: #00bbff;
}

.footer-dropdown {
    top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
    top: 300px !important;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
    color: #777777;
    text-align: center;
}

.fixed-plugin img {
    border-radius: 0;
    width: 100%;
    max-height: 175px;
    margin: 0 auto;
}

.fixed-plugin .badge {
    border: 3px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
}
.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
    border-color: #00bbff;
}

.fixed-plugin .badge-white,
.badge-white {
    background-color: #ffffff;
}
.fixed-plugin .badge-black,
.badge-black {
    background-color: #1dc7ea;
}
.fixed-plugin .badge-azure,
.badge-azure {
    background-color: #1dc7ea;
}
.fixed-plugin .badge-green,
.badge-green {
    background-color: #87cb16;
}
.fixed-plugin .badge-orange,
.badge-orange {
    background-color: #ffa534;
}
.fixed-plugin .badge-purple,
.badge-purple {
    background-color: #9368e9;
}
.fixed-plugin .badge-red,
.badge-red {
    background-color: #fb404b;
}

.minWidthSelect {
    min-width: 150px !important;
}
.badge-white,
.badge-white a {
    color: #000;
}
.badge-black,
.badge-black a {
    color: #fff;
}
.badge-azure,
.badge-azure a {
    color: #fff;
}
.badge-green,
.badge-green a {
    color: #fff;
}
.badge-orange,
.badge-orange a {
    color: #fff;
}
.badge-purple,
.badge-purple a {
    color: #fff;
}
.badge-red,
.badge-red a {
    color: #fff;
}
.fixed-plugin h5 {
    font-size: 12px;
    margin: 10px;
}
.fixed-plugin .dropdown-menu li {
    display: block;
    padding: 5px 2px;
    width: 25%;
    float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
    width: 100%;
    height: 50px;
    min-height: inherit;
}
.fixed-plugin .pro-title {
    margin: 10px 0 5px 0;
    text-align: center;
}

.fixed-plugin #sharrreTitle {
    text-align: center;
    padding: 10px 0;
    height: 50px;
}

.fixed-plugin li.header-title {
    height: 30px;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
    /* float: left; */
    display: inline-block;
    margin-bottom: 0;
    font-size: 1em;
}
.fixed-plugin .adjustments-line .switch {
    float: right;
}
.fixed-plugin .dropdown-menu > li.adjustments-line > a {
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    margin: 0;
}
.fixed-plugin .dropdown-menu > li > a.img-holder {
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
    border: 3px solid #fff;
    padding-left: 0;
    padding-right: 0;
    opacity: 1;
    cursor: pointer;
    max-height: 86px;
    overflow: hidden;
    padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover,
.fixed-plugin .dropdown-menu > li > a.switch-trigger:focus {
    background-color: transparent;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
    border-color: rgba(0, 187, 255, 0.53);
}
.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
    border-color: #00bbff;
    background-color: #ffffff;
}

.fixed-plugin .dropdown-menu > li > a img {
    margin-top: auto;
}

.fixed-plugin .btn-social {
    width: 50%;
    display: block;
    width: 48%;
    float: left;
    font-weight: 600;
}
.fixed-plugin .btn-social i {
    margin-right: 5px;
}
.fixed-plugin .btn-social:first-child {
    margin-right: 2%;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    opacity: 1;
}
.heroSection {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.wrapperOverride.tutorialPage {
    padding-left: 1.4rem !important;
}
.tutorialPage .content.container,
.tutorialPage .container {
    max-width: 100%;
}
.LMheroSection {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 3rem 0;
}
.LMvideoGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}
.LMvideoGrid img {
    width: 100%;
    max-height: 270px;
    object-fit: cover;
    object-position: center;
}
.licensedCarets {
}
.LMmainSection {
    background: url(../img/licensing-module/LMT-bg.png) no-repeat center / cover;
    padding: 2.5rem 0 !important;
}
.BLGridStep,
.stepTitle {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
    text-align: center;
}
.stepTitle figcaption::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 24px;
    /* border: 1px dashed #707070; */
    background: url(../img/licensing-module/linesV.svg) repeat-y center /
        contain;
}
.BLGridStep figure figcaption {
    display: none;
}
.BLGridStep figure > img {
    width: 110px;
    height: 150px;
    object-fit: cover;
    object-position: center;
    padding: 0.6rem;
    border: 1px solid #0080ff;
    border-radius: 6px 6px 0 0;
}
.stepOne {
    border: 1px dashed #707070;
    border-radius: 1rem;
    padding: 0.5rem 0 2rem 0;
    margin-top: 3rem;
}
.stepOne.stepThree .BLGridStep figure > img {
    width: 240px;
    height: 280px;
    object-fit: cover;
    object-position: center;
}
.stepOne.stepThree .BLGridStep,
.stepOne.stepThree + .stepTitle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    text-align: center;
}

.stepOne .BLGridStep .assetVideo,
.downloadApp,
.publishApp {
    width: 48px;
    background: #ffc90e;
    height: 48px;
    border-radius: 10rem;
}
.downloadApp,
.publishApp {
    width: 100px;
    height: 100px;
}
.assetVideo,
.assetWatermark,
.assetAudio,
.assetPromo,
.downloadApp,
.publishApp {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.currentStep {
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    background: #806bff;
    color: white;
    padding: 0.75rem 2.25rem;
    border-radius: 6px;
    font-size: 18px;
}
/* ADDED  BY  KALEEM */
.audioBG {
    /* background: url(../img/carets-logo-v4.jpg) no-repeat center / cover;
    padding: 1rem; */
    padding: 1rem 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.audioAVT {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center top;
    border: 1px solid #f1f3f4;
}
.max-w-md {
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.modal-overlay.fade-in {
    opacity: 1;
}

.modal-overlay.fade-out {
    opacity: 0;
}

.modal-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 96%;
    max-width: 760px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    transform: translateY(50px);
    transition: transform 0.3s ease;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.modal-box.slide-up {
    transform: translateY(0);
}
/* ADDING LOGO PAGE  FIX */
.addLogoModal .App .flexElem {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    flex-direction: row;
}
.addLogoModal .App .flexElem .ReactCrop {
    flex-basis: 50%;
    max-width: 50%;
}
.addLogoModal .App .flexElem .flexElem {
    flex-direction: column;
    max-width: 50%;
}
/* ADDING LOGO PAGE  FIX */
.fixBotNegative {
    margin-bottom: -40px;
    margin-top: 1.5rem;
    z-index: 111;
    position: relative;
}
.cartCounter {
    position: absolute;
    top: 5px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: rgba(18, 6, 75, 0.8);
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 11px;
    line-height: normal;
}
/* RADIO  BUTTONS  UPDATED */
.radio {
    display: block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
}
.radio + .radio {
    margin-top: 12px;
}
.radio input {
    display: none;
}
.radio input + span {
    display: inline-block;
    position: relative;
    padding-left: 30px;
}
.radio input + span:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    margin-right: 5px;
    width: 16px;
    height: 16px;
    border: 1px solid #2c3356;
    background: #fff;
}
.radio input + span:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background: #2c3356;
    position: absolute;
    border-radius: 50%;
    top: 3px;
    left: 3px;
    opacity: 0;
    transform: scale(0, 0);
    transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}
.radio input:checked + span:after {
    opacity: 1;
    transform: scale(1, 1);
}
/* SWITCH  WITH   CHECKBOX */
.checkSwitch {
    padding: 0.3rem 0;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.checkSwitch input#checkbox {
    display: none;
}
.checkSwitch input#checkbox:checked + .button {
    filter: none;
}
.checkSwitch input#checkbox:checked + .button .dot {
    left: calc(100% - 1.5rem - 0.1rem);
    background-color: #12064b;
}

.checkSwitch .button {
    position: relative;
    width: 4rem;
    height: 1.8rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3),
        inset -2px -2px 5px rgba(255, 255, 255, 0.8);
    /* box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3),
        inset -2px -2px 5px rgba(255, 255, 255, 0.8); */
    cursor: pointer;
}
.checkSwitch .button .dot {
    position: absolute;
    width: 1.4rem;
    height: 1.4rem;
    left: 0.2rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3),
        -3px -3px 6px rgba(255, 255, 255, 0.8);
    transition: all 0.3s;
    background-color: #aab7d9;
    will-change: left, background-color;
}

@keyframes deco-move {
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
@media all and (max-width: 767px) {
    .gridAd,
    .packagePlan {
        grid-template-columns: repeat(1, 1fr);
    }
    .paymentInfo {
        border-left: 0;
    }
    .bgLine,
    .borderLR::after,
    .borderLR::before,
    .borderRight::before,
    .borderRight::after,
    .borderLeft::before,
    .borderLeft::after {
        display: none;
    }
    .stepCalculator {
        position: absolute;
        left: -20px;
        top: 0;
        height: 100%;
    }
    .borderLR,
    .borderRight,
    .borderLeft {
        height: 116px;
    }
    .main_login {
        min-height: calc(100vh - 148px);
    }
    .main_login.flexRowF {
        flex-direction: column !important;
        padding-bottom: 50px;
    }
    body.modal-open .modalLG.modal-lg .modal-dialog {
        max-width: 94%;
    }
    .loginAdWrapper {
        width: 100%;
        max-height: 420px;
    }
    .mx-auto-mobile {
        margin: 0 auto;
    }
    .heroSection .col-md-3,
    .heroSection .col-md-6 {
        width: 100%;
    }
    .BLGridStep {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 3rem;
        column-gap: 1rem;
    }
    .stepOne {
        border: none;
        row-gap: 3rem;
        column-gap: 1rem;
    }
    .BLGridStep figure figcaption {
        display: block;
    }
    .stepTitle figcaption::before {
        content: none;
    }
    .stepTitle {
        display: none;
    }
    .stepOne.stepThree .BLGridStep figure > img {
        width: 160px;
        height: 200px;
    }
    .assetVideo,
    .assetWatermark,
    .assetAudio,
    .assetPromo {
        top: 70px;
    }
    .downloadApp,
    .publishApp {
        top: 100px;
    }
    .wrapperOverride.tutorialPage {
        padding-left: 0 !important;
    }
    .addLogoModal .App .flexElem .ReactCrop,.addLogoModal .App .flexElem .flexElem {
        flex-basis: 100%;
        max-width: 100%;
    }
}
@media (min-width: 768px) {
    .advanceSearch .modal-dialog,
    .editOrderDetail .modal-dialog {
        width: auto;
        max-width: calc(100% - 30px);
        margin: 30px auto;
    }
    .modal-dialog {
        width: 740px;
    }
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
        margin-left: 0;
    }
    .navbar .navbar-brand {
        /* padding: 10px 15px; */
        padding: 5px 0;
    }
    .mb-md-0 {
        margin-bottom: 0 !important;
    }
    .me-md-0 {
        margin-right: 0 !important;
    }
    .ms-md-0 {
        margin-left: 0 !important;
    }
}
@media (min-width: 992px) {
    .custom-search-input {
        width: 100%;
        max-width: 440px;
    }
    .fixed-plugin .dropdown .dropdown-menu {
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 27px;
        opacity: 0;

        transform-origin: 0 0;
    }
    .fixed-plugin .dropdown.open .dropdown-menu {
        opacity: 1;

        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        transform-origin: 0 0;
    }

    .fixed-plugin .dropdown-menu:before,
    .fixed-plugin .dropdown-menu:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        width: 16px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
    }
    .fixed-plugin .dropdown-menu:before {
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid rgba(0, 0, 0, 0.2);
        border-top: 16px solid rgba(0, 0, 0, 0);
        right: -16px;
    }

    .fixed-plugin .dropdown-menu:after {
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid #ffffff;
        border-top: 16px solid rgba(0, 0, 0, 0);
        right: -15px;
    }

    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }

    .fixed-plugin {
        top: 300px;
    }
    .paymentInfo {
        padding-left: 35px;
    }
    .modal-dialog {
        width: 960px;
    }
}
@media (max-width: 991px) {
    .flexResponsive .custom-search-input {
        margin-top: 15px;
    }

    .orderTabs .react-tabs > ul {
        flex-direction: column;
        align-items: center;
    }

    .orderTabs .react-tabs > ul > li {
        margin-top: 10px;
    }

    .orderTopNav {
        align-items: center;
        justify-content: center;
    }

    .orderTopNav > .navButtons {
        margin-top: 15px;
    }
    .marginLeft991 {
        margin-left: 0px !important;
        margin-top: 10px !important;
    }
    .flexResponsive {
        flex-direction: column;
        align-items: center;
    }
    .flexResponsive > div {
        width: 100% !important;
    }

    .fixed-plugin .dropdown-menu {
        right: 60px;
        width: 220px;
    }
    .fixed-plugin .dropdown-menu li {
        width: 50%;
    }

    .fixed-plugin li.adjustments-line,
    .fixed-plugin li.header-title,
    .fixed-plugin li.button-container {
        width: 100%;
        height: 55px;
        min-height: inherit;
    }

    .fixed-plugin .adjustments-line .switch {
        float: right;
        margin: 0 0px;
    }

    .fixed-plugin li.header-title {
        height: 40px;
    }
    .fixed-plugin .dropdown .dropdown-menu {
        top: -170px;
    }
    .BLGridStep {
        grid-template-columns: repeat(3, 1fr);
    }
    .fixBotNegative {
        margin-bottom: 0;
    }
    .flexWrap-sm {
        flex-wrap: wrap;
    }
    .mobileHidden {
        display: none;
    }
    .cropFix {
        min-width: 100%;
        width: 100%;
        max-width: fit-content;
        min-height: fit-content;
        border: 4px solid red;
        background-color: blue;
    }
}
.customTable.table tbody tr td {
    color: inherit !important;
}

.customLink:hover {
    color: inherit;
}

.customLink:hover {
    text-decoration: underline;
}

.flexResponsive {
    flex-wrap: wrap;
}

.flexEnd {
    align-items: flex-end;
}

.btn-social {
    opacity: 0.85;
    padding: 8px 9px;
}
.btn-social .fa {
    font-size: 18px;
    vertical-align: middle;
    display: inline-block;
}
.btn-social.btn-round {
    padding: 9px 10px;
}
.btn-social.btn-simple {
    padding: 9px 5px;
    font-size: 16px;
}
.btn-social.btn-simple .fa {
    font-size: 20px;
    position: relative;
    top: -2px;
    width: 24px;
}

.btn-facebook {
    border-color: #3b5998;
    color: #3b5998;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
    background-color: transparent;
    color: #3b5998;
    border-color: #3b5998;
    opacity: 1;
}
.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
    background-color: transparent;
    border-color: #3b5998;
}
.btn-facebook.btn-fill {
    color: #ffffff;
    background-color: #3b5998;
    opacity: 0.9;
}
.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open > .btn-facebook.btn-fill.dropdown-toggle {
    background-color: #3b5998;
    color: #ffffff;
    opacity: 1;
}
.btn-twitter {
    border-color: #55acee;
    color: #55acee;
}
.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
    background-color: transparent;
    color: #55acee;
    border-color: #55acee;
    opacity: 1;
}
.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
    background-color: transparent;
    border-color: #55acee;
}
.btn-twitter.btn-fill {
    color: #ffffff;
    background-color: #55acee;
    opacity: 0.9;
}
.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open > .btn-twitter.btn-fill.dropdown-toggle {
    background-color: #55acee;
    color: #ffffff;
    opacity: 1;
}

@media (min-width: 992px) {
    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

.all-icons [class*="pe-"] {
    font-size: 40px;
}
.all-icons input {
    border: 0;
}
.all-icons .font-icon-detail {
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}
.all-icons .font-icon-detail input {
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}

#map {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
}

.places-buttons .btn {
    margin-bottom: 30px;
}
.sidebar .nav > li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
}
.sidebar .nav > li.active-pro a {
    background: rgba(255, 255, 255, 0.14);
    opacity: 1;
    color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
    text-align: center;
}
.ReactTable .-pagination .-btn {
    height: auto !important;
}

.modal-open .modal {
    display: block;
}

.action-center {
    text-align: center !important;
}

.action-button-size {
    font-size: 17px;
    margin-left: 10px;
    border: none !important;
    background-color: transparent !important;
    color: #444;
}

.outOfTableButton {
    font-size: 15px !important;
    line-height: 10px;
    color: #1dc7ea !important;
    margin-top: 12px;
}

.custom_btn {
    font-size: 15px !important;
    line-height: 10px;
    margin-top: 12px;
}

.marginFromTopPopUp {
    margin-top: 10px;
}

.myTable tr td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.react-confirm-alert-body {
    max-width: 400px;
    width: 100%;
}

.customHalf > .col-md-6 {
    width: calc(50% - 28px);
    padding-top: 0 !important;
}

.card.innerCard .table-striped > tbody > tr:nth-of-type(odd) {
    background: rgba(255, 255, 255, 0.7);
}

.card.innerCard .table-striped > thead > tr > th {
    padding-top: 13px;
    padding-bottom: 13px;
    color: #333;
}

.card.innerCard .table-striped > thead > tr {
    background: #aac9f8;
}

.card.innerCard .table > tbody > tr > td {
    padding-top: 6px;
    padding-bottom: 6px;
}

.lessSpace.customHalf > .col-md-6 {
    width: calc(50% - 10px);
}
.imagePreview {
    height: auto;
    width: 70px;
    margin: 10px 0;
    border: 1px solid #eee;
}
.imageUploadWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.buttonHide {
    display: none;
}

.buttonShow {
    display: block;
}
.login_form_container {
    width: 100%;
    max-width: 600px;
    /* margin: 0 auto; */
    /* background: #f8f8f8; */
    padding: 50px 10px;
}

.login_form_container h1 {
    font-size: 30px;
    margin: 0 0 35px 0;
    font-weight: 500;
}

.logo_container {
    margin-bottom: 35px;
}
.fixLoginWrap {
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%; */
    height: 100vh;
    background-image: linear-gradient(
            to bottom,
            rgba(113, 13, 13, 0.55),
            rgba(18, 6, 75, 0.75)
        ),
        url(../img/loginBG.jpg);
    /* background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.85),
            rgba(0, 0, 0, 0.95)
        ),
        url(../img/loginBG.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
}
.main_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* min-height: 650px; */
    /* background-image: url(../img/loginBG.jpg), linear-gradient(#eb01a5, #d13531); background-position: center; background-repeat: no-repeat; background-attachment: fixed; background-size: cover;  */
}
.main_login_landing {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login_form {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
.main_login .adminLogin input.form-control {
    color: #251700 !important;
}
.login_form input.form-control,
.login_form input {
    border-color: #dfdfdf;
    line-height: 2.3 !important;
    height: auto;
    background: rgba(255, 255, 255, 0.2);
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 2rem;
    margin-bottom: 20px;
    color: #212121 !important;
    font-weight: 600;
    letter-spacing: 1px;
}
.login_form input:focus,
.login_form input:hover,
.login_form input.form-control:focus,
.login_form input.form-control:hover {
    color: #413f3f !important;
}
.login_form input + .help-block {
    margin-top: -13px;
}

.login_form .password_ctrl {
    background-image: url("../img/password.png");
    background-repeat: no-repeat;
    background-position: 95% center;
}

.login_form .email_ctrl {
    background: url(../img/user.png) no-repeat center right 10px / contain;
    /* background-image: url('../img/user.png');
  background-repeat: no-repeat;
  background-position: 95% center; */
}

.login_form ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #7b7b7b;
    font-size: 15px;
}
.login_form ::-moz-placeholder {
    /* Firefox 19+ */
    color: #7b7b7b;
    font-size: 15px;
}
.login_form :-ms-input-placeholder {
    /* IE 10+ */
    color: #7b7b7b;
    font-size: 15px;
}
.login_form :-moz-placeholder {
    /* Firefox 18- */
    color: #7b7b7b;
    font-size: 15px;
}

.logoImageLogin {
    width: 140px;
}

.select_height_adjust > div[class^="css-"] > div[class^="css-"] {
    min-height: 40px;
}
.css-kj6f9i-menu {
    z-index: 16777271;
    /*text-transform: lowercase;*/
}

.rt-td.showSelect {
    overflow: visible !important;
    position: relative !important;
}

/* .css-kj6f9i-menu *::first-letter {
    text-transform: uppercase;
} */

.btn_link {
    border: none;
    background: none;
    padding: 0;
    outline: none;
}
.customBorder {
    border: 1px solid hsl(0, 0%, 80%);
}
.customBorder:focus {
    border: 2px solid #2782fc;
}
.btn_link.button_disabled {
    color: #999 !important;
    border: none !important;
    background: none !important;
    cursor: not-allowed !important;
    outline: none;
    padding: 0;
}
.srv-validation-message {
    color: tomato;
    font-size: 12px;
    margin-top: 3px;
}
.requiredClass {
    color: tomato;
}
.customButton {
    margin-top: 0px;
    line-height: 20px;
}
table.actions_table {
    margin-bottom: 0;
}

table.actions_table tr:first-child td {
    border: none;
}

.iconNavSidebar > a {
    display: flex !important;
    align-items: center;
}
.react-confirm-alert-overlay {
    z-index: 1060;
}

.iconNavSidebar > a > img {
    width: auto;
    height: 20px;
    margin-right: 10px;
}
.sidebar:before,
body > .navbar-collapse:before,
.sidebar:after,
body > .navbar-collapse:after {
    background: #2c3356 !important;
}

.sidebar .logo .logo-img {
    width: 100%;
}

.iconNavSidebar > a > img {
    width: auto;
    height: 20px;
    margin-right: 10px;
}
.sidebar:before,
body > .navbar-collapse:before,
.sidebar:after,
body > .navbar-collapse:after {
    background: #2b2d3a !important;
}

.sidebar .nav li > span {
    display: inline-flex;
    /* justify-content: space-between; */
    width: "100%";
}
.sidebar .nav p {
    white-space: nowrap;
    align-items: center;
    display: flex;
}
.dollar_icon {
    background: url("../img/dollar_icon_sm.png") no-repeat 10px center, #fff;
    padding-left: 30px;
}

.calender_icon {
    background: url("../img/calender.png") no-repeat 10px center, #fff;
    padding-left: 40px;
}

.search_icon {
    background: url("../img/search_icon.png") no-repeat 10px center, #fff;
    padding-left: 40px;
}

.fileContainer .deleteImage {
    background: #322e58 !important;
}

.alignFlexButtons {
    justify-content: center;
}

.alignFlexButtons .btn {
    font-size: 18px;
}

.alignFlexButtons .btn-danger {
    background: red;
    border: 1px solid rgb(255, 255, 255);
}
.alignFlexButtons .btn-danger:hover {
    background: #fff;
    border: 1px solid red;
    color: red;
}

.alignFlexButtons .btn-success {
    background: #049f0c;
    border: 1px solid rgb(255, 255, 255);
}
.alignFlexButtons .btn-success:hover {
    background: #fff;
    border: 1px solid #049f0c;
    color: #049f0c;
}

.button_disabled {
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
}

.mb-20-test {
    margin-bottom: 20px !important;
}

@keyframes show {
    from {
        max-height: 0px;
        opacity: 0;
    }
    to {
        max-height: 500px;
        opacity: 1;
    }
}
.sideNavToggle,
.sideNavClose {
    display: none;
}

.sidebar .nav li > span {
    display: inline-flex;
}
.sidebar .nav p {
    white-space: nowrap;
    margin-left: 20px;
    transition: 0.2s all;
}
.sidebar.showSideNav .nav p {
    margin-left: 3px;
}

@media (min-width: 991.99px) {
    .videoUploaderSection > div:first-child {
        margin-right: 10px;
    }
    .videoUploaderSection > div:last-child {
        margin-left: 10px;
    }
    .sidebar .sidebar-wrapper {
        margin-top: 100px;
        width: 290px;
    }
    .navbar-header {
        display: flex;
        align-items: center;
    }
    .videoUploaderSection > div:first-child {
        margin-right: 10px;
    }
    .videoUploaderSection > div:last-child {
        margin-left: 10px;
    }
    .sidebar {
        position: fixed;
        z-index: 100;
        left: 0px;
        top: 0px;
        display: block;
        bottom: 0px;
        width: 60px;
        overflow: hidden;
    }
    .showSideNav {
        display: block;
        overflow: visible;
        width: auto;
    }
    .main-panel {
        width: 100%;
        background: #fff;
        width: calc(100% - 50px);
    }
    /* #main-panel.main-panel {
        width: calc(100% - 230px) !important;
    } */
    .showSideNav + .main-panel {
        transition: 0.2s all;
    }

    .sidebar .sidebar-wrapper {
        overflow: hidden;
        overflow-y: auto;
    }

    .sideNavToggle {
        float: left;
        margin: 22px 13px 0;
        cursor: pointer;
        display: block;
    }
    .sideNavClose {
        cursor: pointer;
        z-index: 1000;
        color: #fff;
        display: inline-flex;
        font-size: 2rem;
        position: relative;
        margin: 0px 10px;
        margin-top: 60px;
    }
}

@media (max-width: 991.98px) {
    .gridAd,
    .packagePlan {
        grid-template-columns: repeat(2, 1fr);
    }
    #main-panel {
        width: 100% !important;
    }
    div#sidebar,
    #sidebar div.sidebar-wrapper {
        width: auto;
    }
    ul.nav.pull-right {
        float: none !important;
    }
    .fixLoginWrap {
        position: static;
        height: auto;
    }
    .copyRights {
        background-color: #1f2140;
        margin-top: auto;
    }
}

@keyframes showNav {
    from {
        max-width: 50px;
    }
    to {
        max-width: 500px;
    }
}

.p-10 {
    padding: 10px;
}
.pl-6pF {
    padding-left: 6px !important;
}
.pr-6pF {
    padding-right: 6px !important;
}
.p-15 {
    padding: 15px;
}
.p-25 {
    padding: 25px;
}
.pl-btn-fix {
    padding-left: 80px;
    padding-top: 20px;
}
.align-items-center {
    align-items: center;
}
.ship-status-table tr td:nth-child(2) {
    background-color: whiteSmoke;
}
.innerScrollWrap {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
}
.innerScrollModal {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
}
.h-full {
    height: 100%;
}
.mt-0F {
    margin-top: 0 !important;
}
.mt-0 {
    margin-top: 0;
}
.mt-10 {
    margin-top: 10px;
}
.mt-5 {
    margin-top: 5px;
}
.mt-20 {
    margin-top: 20px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.my-20 {
    margin: 20px 0;
}
.mx-20 {
    margin: 0 20px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-10 {
    margin-right: 10px;
}
.m-0 {
    margin: 0 !important;
}
.p-0 {
    padding: 0 !important;
}
.ml-auto {
    margin-left: auto;
}
.videoTitle {
    color: #806bff;
    font-size: 24px !important;
}
.caretAdvertising {
    background: url(../img/ad-bg-gradient.png) no-repeat center/ cover !important;
    padding: 2.5rem 0 !important;
    margin-top: 5rem !important;
}
.advertisingTitle,
.advertisingSubTitle {
    font-weight: 600 !important;
    font-size: 36px !important;
    color: #12064b;
}
.advertisingSubTitle {
    color: #e42a61;
}
.box {
    border-radius: 3px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.14),
        -1px 0px 5px rgba(0, 0, 0, 0.14);
}
.box-primary {
    border-top: 3px solid #596a91;
}

.box-info {
    border-top: 3px solid #596a91;
}

.box-danger {
    border-top: 3px solid salmon;
}

.box-success {
    border-top: 3px solid #4dbd74;
}
.d-inline-block {
    display: inline-block;
}
.w-autoF {
    width: auto !important;
}
.w-100 {
    width: 100%;
}
.w-25 {
    width: 25%;
}
.w-50 {
    width: 50%;
}
.listNumber {
    padding-left: 20px;
}
.listNumber > li {
    font-weight: 600;
}
.listAplhaCap {
    list-style: upper-alpha;
    padding-left: 16px;
}
.listAplha {
    list-style: lower-alpha;
    padding-left: 16px;
}
.listAplhaCap li,
.listAplha li {
    margin-top: 10px;
    font-weight: normal;
}
.loginAdWrapper {
    width: 250px;
    height: 420px;
}
.table-striped-custom > tbody > tr:nth-of-type(2n + 1) {
    background-color: #eee;
}

.table-striped-custom td {
    padding: 2px 5px !important;
}

.d-inline-block {
    display: inline-block;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.6);
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.6);
}
.text-black {
    color: #333 !important;
}
.topNav li a:hover {
    background-color: #2f4576;
    transform: scale(1.03);
}

@keyframes back {
    from {
        transform: translateX(25%) rotate(90deg);
    }
    to {
        transform: translateX(-25%) rotate(90deg);
    }
}

.docLoader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.notCard {
    border: none;
    box-shadow: none;
}
.notCard .content {
    border: none;
    padding: 0 !important;
}
.btn-info:disabled:hover {
    background-color: #2f4576;
}
.btn-dark {
    background-color: #4d4d4d;
    border: 2px solid #4d4d4d;
    color: #fff;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
    background-color: #323131;
}
.roundedFull {
    border-radius: 10rem;
}
.rounded5p {
    border-radius: 5px;
}
.rounded-none {
    border-radius: 0;
}
.barcodePrint {
    position: absolute;
    top: 67%;
    right: calc(110pt - 2vw);
}

.orangeText,
.orangeText * {
    color: darkorange !important;
}

.semibold {
    font-weight: 500 !important;
    color: inherit !important;
}

.headingPrint {
    font-size: 18pt !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    color: white !important;
    background-color: #666 !important;
    text-align: center !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.headingPrint.heading1 {
    font-size: 20pt !important;
    background-color: #fff !important;
    color: #666 !important;
}

.printTable {
    /* table-layout: fixed !important; */
    width: 100% !important;
}
.printTable tr td,
.printTable tr th {
    padding: 3pt 5pt !important;
}

/* .printTable tr td:first-child{
  width: 40% !important;
}

.printTable tr td:last-child{
  width: 60% !important;
} */

.printTable tr:nth-child(even) {
    background: #eee !important;
}

@media print {
    @page {
        size: letter;
    }
    .barcodePrint {
        top: 65.9%;
        right: calc(70pt - 5.5vw);
        width: auto;
    }

    .col-md-6 {
        width: 50%;
        float: left;
    }

    .orangeText h1 {
        margin-top: 5pt !important;
    }

    p {
        line-height: normal !important;
    }

    .well {
        page-break-inside: avoid;
        box-shadow: none;
        border: none;
        background: lightblue !important;
        margin-bottom: 10px;
    }
    h4 {
        margin-top: 15px;
    }
    .well h6 {
        margin: 0 !important;
    }
    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
        padding-top: 5pt;
        padding-bottom: 5pt;
    }

    .container.fullPrint {
        width: 100% !important;
    }
    .container.fullPrint > .printRow {
        margin: 0 !important;
        display: flex !important;
    }
    .container.fullPrint > .printRow > .col-md-5 {
        flex-basis: 50%;
    }
    .container.fullPrint > .printRow > .col-md-7 {
        flex-basis: 50%;
    }
    .container.fullPrint a[href]:after {
        content: none !important;
    }

    .innerUiSettingsCard {
        /* border: none; */
        -webkit-box-shadow: none;
        box-shadow: none;
        /* background: #eaeef1; */
        border-radius: 0;
        border: solid #eaeef1 1px;
    }
}

#ReportPage {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11pt;
}

#ReportPage table.blankStyle,
#ReportPage table.blankStyle tr,
#ReportPage table.blankStyle tr td {
    border: none !important;
}
#ReportPage table.blankStyle tr td {
    padding: 1.5pt 3pt;
}

#ReportPage .mainReportTable {
    border: 1pt solid #666;
    width: 100%;
    margin-top: 10pt;
    box-sizing: border-box;
}

#ReportPage .mainReportTable > tbody > tr + tr {
    border-top: 1pt solid #666;
}

#ReportPage .styledReport tr td,
#ReportPage .styledReport tr th {
    padding: 3pt 5pt !important;
}

#ReportPage .styledReport {
    margin-top: 7pt;
}

#ReportPage .styledReport thead tr th {
    background-color: #eee !important;
    font-size: 13pt;
}

#ReportPage .styledReport tbody tr td:nth-last-child(1),
#ReportPage .styledReport tbody tr td:nth-last-child(2),
#ReportPage .styledReport thead tr th:nth-last-child(1),
#ReportPage .styledReport thead tr th:nth-last-child(2),
#ReportPage .styledReport tfoot tr td:nth-last-child(1),
#ReportPage .styledReport tfoot tr td:nth-last-child(2) {
    text-align: right;
}

#ReportPage .styledReport tfoot tr:first-child {
    border-top: 1pt solid #999;
}

#ReportPage .styledReport tfoot tr:last-child td {
    font-size: 13pt;
    font-weight: bold;
}

.sidebar:before,
body > .navbar-collapse:before,
.sidebar:after,
body > .navbar-collapse:after {
    background: #2c3356 !important;
}
.adCost {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    padding: 0.5rem 4rem;
    border: 1px solid #eaeff3;
    border-radius: 3px;
    margin-left: 0.5rem;
}
/* CUSTOMIZED  RADIO  BUTTONS */
.videoPosition {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.videoPositionCheck .input-hidden {
    position: absolute;
    left: -9999px;
}
.videoPositionCheck input[type="radio"]:checked + label > img {
    border: 1px solid #fff;
}
.videoPositionCheck input[type="radio"] + label.vidPos {
    width: 144px;
    height: 72px;
    background: #eaeff3 url(../img/dummy.svg) no-repeat center top 15px / 36px;
    color: #fff !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 14px;
}
.videoPositionCheck input[type="radio"]:checked + label.vidPos {
    background: #0078ff url(../img/dummywhite.svg) no-repeat center top 15px /
        36px;
}
.videoPosition.secondRow .videoPositionCheck input[type="radio"] + label.vidPos,
.videoPosition.thirdRow .videoPositionCheck input[type="radio"] + label.vidPos {
    width: 76px;
    height: 62px;
}
.videoPosition.thirdRow
    .videoPositionCheck.wrapped
    input[type="radio"]
    + label.vidPos {
    width: 36px;
    background: #eaeff3;
    color: #eaeff3 !important;
}
.videoPosition.thirdRow
    .videoPositionCheck.wrapped
    input[type="radio"]:checked
    + label.vidPos,
.videoPosition.thirdRow
    .videoPositionCheck.wrapped
    input[type="radio"]
    + label.vidPos:hover {
    width: 76px;
    height: 62px;
    color: #fff !important;
    transition: width 0.5s;
    background: #0078ff url(../img/dummywhite.svg) no-repeat center top 15px /
        36px;
}
.file-upload {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.file-upload input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.file-upload label {
    display: block;
    width: 100%;
    padding: 25px 20px;
    background-color: #f8f9fc;
    color: #585858 !important;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
}

.file-upload label:hover {
    background-color: #2980b9;
}

.file-upload label:active {
    background-color: #1f6698;
}

/* Compaigns */
.bg-comp-cards {
    background-color: #e1e1e8;
}
.bg-after-cards {
    background-color: #e1e1e8;
}
.plan-bg-color {
    background-color: #e9f3f5;
}
