.text-xxxs-rem {
	font-size: 0.7rem !important;
}

.text-xxs-rem {
	font-size: 0.8rem !important;
}

.text-xs-rem {
	font-size: 1rem !important;
}

.text-sm-rem {
	font-size: 1.3rem !important;
}

.text-md-rem {
	font-size: 1.6rem !important;
}
 
.text-lg-rem {
	font-size: 1.9rem !important;
}

.text-xl-rem {
	font-size: 2.2rem !important;
}

.text-xs {
	font-size: 16px !important;
}

.text-sm {
	font-size: 20.8px !important;
}

.text-md {
	font-size: 25.6px !important;
}
 
.text-lg {
	font-size: 30.4px !important;
}

.text-xl {
	font-size: 35.2px !important;
}

.text-muted {
	color: #999 !important;
}


.text-semi-muted {
	color: #666 !important;
}

html.dark .text-muted {
	color: #505461 !important;
}

.text-dark {
	color: #1d2127 !important;
}

.text-light {
	color: #FFF !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.no_radius{
	border-radius: 0 !important;
}
.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-weight-extra-bold {
	font-weight: 900 !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.rounded {
	border-radius: 5px !important;
}

.b-thin {
	border-width: 3px !important;
}

.b-normal {
	border-width: 5px !important;
}

.b-thick {
	border-width: 7px !important;
}

/* Spacements */
/* spacement top & bottom */
.m-none {
	margin: 0 !important;
}

.m-auto {
	margin: 0 auto !important;
}

.m-xs {
	margin: 5px !important;
}

.m-sm {
	margin: 10px !important;
}

.m-md {
	margin: 15px !important;
}

.m-lg {
	margin: 20px !important;
}

.m-xl {
	margin: 25px !important;
}

.m-xlg {
	margin: 30px !important;
}

/* spacement top	*/
.mt-none {
	margin-top: 0 !important;
}

.mt-xs {
	margin-top: 5px !important;
}

.mt-sm {
	margin-top: 10px !important;
}

.mt-md {
	margin-top: 15px !important;
}

.mt-lg {
	margin-top: 20px !important;
}

.mt-xl {
	margin-top: 25px !important;
}

.mt-xlg {
	margin-top: 30px !important;
}

.mt-xxlg {
	margin-top: 40px !important;
}

.mt-xxxlg {
	margin-top: 50px !important;
}

/* spacement bottom	*/
.mb-none {
	margin-bottom: 0 !important;
}

.mb-xs {
	margin-bottom: 5px !important;
}

.mb-sm {
	margin-bottom: 10px !important;
}

.mb-md {
	margin-bottom: 15px !important;
}

.mb-lg {
	margin-bottom: 20px !important;
}

.mb-xl {
	margin-bottom: 25px !important;
}

.mb-xlg {
	margin-bottom: 30px !important;
}

.mb-xxlg {
	margin-bottom: 40px !important;
}

.mb-xxxlg {
	margin-bottom: 50px !important;
}

/* spacement left	*/
.ml-none {
	margin-left: 0 !important;
}

.ml-xs {
	margin-left: 5px !important;
}

.ml-sm {
	margin-left: 10px !important;
}

.ml-md {
	margin-left: 15px !important;
}

.ml-lg {
	margin-left: 20px !important;
}

.ml-xl {
	margin-left: 25px !important;
}

.ml-xlg {
	margin-left: 30px !important;
}

.ml-xxlg {
	margin-left: 40px !important;
}

.ml-xxxlg {
	margin-left: 50px !important;
}

/* spacement right	*/
.mr-none {
	margin-right: 0 !important;
}

.mr-xs {
	margin-right: 5px !important;
}

.mr-sm {
	margin-right: 10px !important;
}

.mr-md {
	margin-right: 15px !important;
}

.mr-lg {
	margin-right: 20px !important;
}

.mr-xl {
	margin-right: 25px !important;
}

.mr-xlg {
	margin-right: 30px !important;
}

.mr-xxlg {
	margin-right: 40px !important;
}

.mr-xxxlg {
	margin-right: 50px !important;
}

/* Spacement Padding */
.p-none {
	padding: 0 !important;
}

.p-xs {
	padding: 5px !important;
}

.p-sm {
	padding: 10px !important;
}

.p-md {
	padding: 15px !important;
}

.p-lg {
	padding: 20px !important;
}

.p-xl {
	padding: 25px !important;
}

.p-xlg {
	padding: 30px !important;
}

/* spacement top	*/
.pt-none {
	padding-top: 0 !important;
}

.pt-xs {
	padding-top: 5px !important;
}

.pt-sm {
	padding-top: 10px !important;
}

.pt-md {
	padding-top: 15px !important;
}

.pt-lg {
	padding-top: 20px !important;
}

.pt-xl {
	padding-top: 25px !important;
}

.pt-xlg {
	padding-top: 30px !important;
}

/* spacement bottom	*/
.pb-none {
	padding-bottom: 0 !important;
}

.pb-xs {
	padding-bottom: 5px !important;
}

.pb-sm {
	padding-bottom: 10px !important;
}

.pb-md {
	padding-bottom: 15px !important;
}

.pb-lg {
	padding-bottom: 20px !important;
}

.pb-xl {
	padding-bottom: 25px !important;
}

.pb-xlg {
	padding-bottom: 30px !important;
}

/* spacement left	*/
.pl-none {
	padding-left: 0 !important;
}

.pl-xs {
	padding-left: 5px !important;
}

.pl-sm {
	padding-left: 10px !important;
}

.pl-md {
	padding-left: 15px !important;
}

.pl-lg {
	padding-left: 20px !important;
}

.pl-xl {
	padding-left: 25px !important;
}

.pl-xlg {
	padding-left: 30px !important;
}

/* spacement right	*/
.pr-none {
	padding-right: 0 !important;
}

.pr-xs {
	padding-right: 5px !important;
}

.pr-sm {
	padding-right: 10px !important;
}

.pr-md {
	padding-right: 15px !important;
}

.pr-lg {
	padding-right: 20px !important;
}

.pr-xl {
	padding-right: 25px !important;
}

.pr-xlg {
	padding-right: 30px !important;
}

.ib {
	display: inline-block;
	vertical-align: top;
}

.va-middle {
	vertical-align: middle;
}

.ws-nowrap {
	white-space: nowrap;
}

.ws-normal {
	white-space: normal;
}
.bg_white{
	background: #fff !important;
}
.bg_gray{
	background: #f5f5f5 !important;
}
.text_left_center_767,.text_left_center_991{
	text-align: left !important;
}

.text_right_center_767,.text_right_center_991{
	text-align: right !important;
}


::-webkit-input-placeholder{
	color: #666;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
::-moz-placeholder{
	color: #666;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
:-ms-input-placeholder{
	color: #666;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
:-moz-placeholder{
	color: #666;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

*:focus::-webkit-input-placeholder, *:hover::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #a3a3a3 !important;
}

*:focus::-moz-placeholder, *:hover::-moz-placeholder {
    /* Firefox 19+ */
    color: #a3a3a3 !important;
}

*:focus:-ms-input-placeholder, *:hover:-ms-input-placeholder {
    /* IE 10+ */
    color: #a3a3a3 !important;
}

*:focus:-moz-placeholder, *:hover:-moz-placeholder {
    /* Firefox 18- */
    color: #a3a3a3 !important;
}

.error{
	color: red;
}

.no_float{
	float: none !important;
}

@media screen and (max-width: 991px) {

	.hide_md {
		display: none !important;
	}

	.show_md {
		display: inline-block !important;
	}

	.text_left_center_991,.text_right_center_991 {
		text-align: center !important;
	}
}

@media screen and (max-width: 767px) {

	.hide_sm {
		display: none !important;
	}

	.show_sm {
		display: inline-block !important;
	}

	.text_left_center_767,.text_right_center_767 {
		text-align: center !important;
	}
}

@media screen and (max-width: 441px) {

	.hide_xs {
		display: none !important;
	}

	.show_xs {
		display: inline-block !important;
	}
}