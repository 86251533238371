@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,600i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i&display=swap");
@import url("fonts/flaticon.css");
@font-face {
    font-family: "GOTHIC";
    src: url("fonts/GOTHIC.eot");
    src: url("fonts/GOTHIC.eot") format("embedded-opentype"),
        url("fonts/GOTHIC.woff2") format("woff2"),
        url("fonts/GOTHIC.woff") format("woff"),
        url("fonts/GOTHIC.ttf") format("truetype"),
        url("fonts/GOTHIC.svg#GOTHIC") format("svg");
}
@font-face {
    font-family: "GOTHICB";
    src: url("fonts/GOTHICB.eot");
    src: url("fonts/GOTHICB.eot") format("embedded-opentype"),
        url("fonts/GOTHICB.woff2") format("woff2"),
        url("fonts/GOTHICB.woff") format("woff"),
        url("fonts/GOTHICB.ttf") format("truetype"),
        url("fonts/GOTHICB.svg#GOTHICB") format("svg");
}
@font-face {
    font-family: "PERTILI";
    src: url("fonts/PERTILI.eot");
    src: url("fonts/PERTILI.eot") format("embedded-opentype"),
        url("fonts/PERTILI.woff2") format("woff2"),
        url("fonts/PERTILI.woff") format("woff"),
        url("fonts/PERTILI.ttf") format("truetype"),
        url("fonts/PERTILI.svg#PERTILI") format("svg");
}
@font-face {
    font-family: "PERTIBD";
    src: url("fonts/PERTIBD.eot");
    src: url("fonts/PERTIBD.eot") format("embedded-opentype"),
        url("fonts/PERTIBD.woff2") format("woff2"),
        url("fonts/PERTIBD.woff") format("woff"),
        url("fonts/PERTIBD.ttf") format("truetype"),
        url("fonts/PERTIBD.svg#PERTIBD") format("svg");
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-weight: 400;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
    body {
        font-weight: lighter !important;
    }
}
.section-frontEnd .panel_heading {
    font-family: "GOTHICB", sans-serif;
    font-size: 36px !important;
    color: #4a4a4a;
    text-align: center;
}
.section-frontEnd .panel_heading.small {
    font-size: 24px !important;
}

.section-frontEnd {
    font-family: "Source Sans Pro", sans-serif;
    color: #464646;
    box-sizing: border-box;
}

.section-frontEnd .login_form_container h1 {
    font-size: 30px;
    margin: 0 0 35px 0;
    font-weight: 500;
}

.section-frontEnd #header {
    border-bottom: 1px solid #ececec;
}

.section-frontEnd .affix {
    width: 100%;
}

.section-frontEnd .selectAdjudicator > div {
    border-radius: 0;
    border: 1px solid #dedede;
    padding-top: 4px;
    padding-bottom: 4px;
    z-index: 100;
}

.section-frontEnd #header .navbar-brand {
    padding: 6px;
    height: auto;
    margin: 0;
}

.section-frontEnd #header .logo {
    display: inline-block;
    width: 202px;
    height: 40px;
    background-size: cover;
}

.section-frontEnd #header .affix .logo {
    background-position: center bottom;
}

.section-frontEnd #header .navbar-default {
    background: none;
    margin: 0;
    border-radius: 0;
    border: none;
}

.section-frontEnd .navbar-default .navbar-nav li a {
    color: #606060 !important;
    background: #fff;
    position: relative;
    margin: 0;
    border-radius: 0;
    line-height: 30px;
    font-weight: 300;
}

.section-frontEnd .navbar-default .navbar-nav li a:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #557ceb; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#557ceb', endColorstr='#9e71e4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    height: 2px;
    width: 0;
    transition: all 0.3s;
    margin: 0 auto;
}

.section-frontEnd .navbar-nav > li > .dropdown-menu {
    margin-top: 12px;
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: 2px 5px 10px -3px rgba(0, 0, 0, 0.3);
    transform-origin: 50% 50%;
}

.section-frontEnd .navbar-nav > li > .dropdown-menu li:hover {
    background: #557ceb; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#557ceb', endColorstr='#9e71e4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.section-frontEnd .navbar-nav > li > .dropdown-menu li a {
    z-index: 1111;
}

.section-frontEnd .navbar-nav > li > .dropdown-menu li a:hover {
    background: #557ceb; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#557ceb', endColorstr='#9e71e4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    color: #fff !important;
}

.section-frontEnd .navbar-default .navbar-nav > li > a:hover:after,
.section-frontEnd .navbar-default .navbar-nav > li > a:active:after,
.section-frontEnd .navbar-default .navbar-nav > li > a:focus:after {
    width: 100%;
    height: 2px;
}

.section-frontEnd .navbar-default .navbar-toggle:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    height: 1px;
}

.section-frontEnd #header .affix {
    background: rgba(39, 54, 60, 0.8);
    z-index: 1000;
}

.section-frontEnd .main_slider_img {
    min-height: 250px !important;
}

.forgetModal .modal-content {
    border-radius: 3px;
}

.forgetModal .custom_btn {
    border-radius: 1px;
    text-transform: uppercase;
    letter-spacing: 0.5pt;
}

.forgetModal .custom_btn.forgetBlue {
    background: #42458a;
    border-color: #42458a;
    color: #fff;
}

.forgetModal p {
    color: #777;
    font-size: 16px;
    margin-top: 20px;
}

.forgetModal .forget_text img {
    margin-top: 35px;
}

.forgetModal .form-control {
    border-radius: 1px;
    height: auto;
    line-height: 30px;
    color: #777;
}

.forgetModal .forget_text p {
    font-size: 18px;
    margin-bottom: 20px;
}

.section-frontEnd .download_btn {
    text-indent: -1000px;
    height: 44px;
    width: 44px;
    margin-left: 20px;
    border: none;
    /* background: url("") no-repeat center; */
    overflow: hidden;
}

/* .section-frontEnd .download_btn:hover {
    background: url("") no-repeat center;
} */

.section-frontEnd .download_all {
    float: right;
    border: none;
    background: #43468b;
    padding: 4px 12px;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
}

.section-frontEnd .main_slider_uk {
    background: #3690a3 !important;
}

.section-frontEnd .main_slider_aus {
    background: #00008b !important;
}

.section-frontEnd .main_slider_can {
    background: #b30028 !important;
}

.section-frontEnd #main_slider h1 {
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    text-transform: capitalize;
}

.section-frontEnd #header.no_slider .navbar-brand .gray,
.section-frontEnd #header.no_slider .affix .navbar-brand .default {
    display: block;
}

.section-frontEnd #header.no_slider .navbar-brand .default,
.section-frontEnd #header.no_slider .affix .navbar-brand .gray {
    display: none;
}

.section-frontEnd .audio_player {
    width: 80%;
    position: relative;
}

.section-frontEnd #main_slider h2 {
    font-size: 24px;
    color: #fff;
    margin-top: 55px;
}

.section-frontEnd #main_slider .banner_img {
    margin-top: 126px;
}

.section-frontEnd #main_slider .banner_text_big {
    font-family: "PERTIBD";
    font-weight: bold;
    font-size: 48px;
    color: #071f37;
    position: relative;
}

.banner_text_big:after {
    content: "";
    width: 70px;
    height: 10px;
    background: #785ea9;
    display: block;
}

.section-frontEnd #main_slider .banner_text_small {
    font-size: 24px;
    margin-top: 15px;
    width: 100%;
    color: #333;
    font-family: "Poppins", sans-serif;
}

.downloadBtns {
    margin-top: 40px;
}
.downloadBtns > a {
    margin-top: 10px;
}

.downloadBtns + p {
    font-size: 18px;
    margin-top: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.section-frontEnd #main_slider .download_btns {
    margin-top: 50px;
}

.section-frontEnd #main_slider .download_btns .download_btn:not(:first-child) {
    margin-left: 30px;
}

.section-frontEnd #main_slider h3 {
    font-size: 36px;
    color: #fff;
    margin-top: 15px;
}

.section-frontEnd .btn-info {
    background-color: #029daf;
    border-color: #029daf;
}

.section-frontEnd .btn_blue {
    background-color: #24a4ab;
    border-color: #24a4ab;
    color: #fff !important;
}

.section-frontEnd .btn_blue:hover,
.section-frontEnd .btn_blue:focus,
.section-frontEnd .btn_blue:active {
    background-color: #05c1d7;
    border-color: #05c1d7;
}

.section-frontEnd .btn_orange {
    background-color: #ee892c;
    border-color: #ee892c;
    color: #fff !important;
}

.section-frontEnd .btn_orange:hover,
.section-frontEnd .btn_orange:focus,
.section-frontEnd .btn_orange:active {
    background-color: #faa65f;
    border-color: #faa65f;
}

.section-frontEnd .btn_pink {
    background-color: #e33f5c;
    border-color: #e33f5c;
    color: #fff !important;
}

.section-frontEnd .btn_pink:hover,
.section-frontEnd .section-frontEnd .btn_pink:focus,
.section-frontEnd .section-frontEnd .btn_pink:active {
    background-color: #f75178;
    border-color: #f75178;
}

.section-frontEnd .btn_container h4 {
    font-size: 18px;
    color: #fff;
    margin-top: 15px;
}

.section-frontEnd #main_slider .btn_container .btn-lg {
    font-size: 24px;
    margin-top: 50px;
    max-width: 100%;
}

.section-frontEnd .first_section {
    margin-top: 160px;
}

.section-frontEnd .first_section.not_home {
    margin-top: 0px;
    padding-top: 160px;
}

.section-frontEnd .grey {
    background: #f5f5f5;
}

.section-frontEnd .comments_container {
    margin-top: 25px;
    float: left;
    width: 100%;
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
}

.section-frontEnd .view_comments {
    font-size: 24px;
    padding: 15px 0;
    color: #43468b;
}

.section-frontEnd .gallery_title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 60px;
}

.section-frontEnd .all_comments {
    font-size: 18px;
    padding: 10px 20px;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
}

.section-frontEnd .user_details {
    display: flex;
    color: #5e5e5e;
    align-items: center;
    font-size: 18px;
}

.section-frontEnd .user_details > img {
    height: 65px;
    border-radius: 5px;
    max-width: inherit;
}

.section-frontEnd .load_more_btn {
    background: #4d308b;
    color: #fff;
    border: none;
    padding: 15px;
    margin: 15px auto;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
}

.section-frontEnd .user_details > p {
    margin-left: 30px;
}

.section-frontEnd .pos_abs {
    position: absolute;
    width: 100%;
}

.section-frontEnd .pos_abs.affix {
    position: fixed;
}

.section-frontEnd .user_comment {
    float: right;
    color: #43458b;
}

.section-frontEnd .audio_comment {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border-bottom: 1px solid #dedede;
    flex-wrap: wrap;
}

.section-frontEnd .audio_comment .comment_name {
    text-align: left;
    width: 100%;
    padding-left: 10px;
    padding-top: 7;
    font-size: 14px;
    text-transform: capitalize;
}

.section-frontEnd .audio_comment .dated {
    position: absolute;
    color: #43728b;
    font-size: 14px;
    right: 0;
    top: 0;
}

.section-frontEnd .audio_comment .timer_st {
    position: absolute;
    color: #43728b;
    font-size: 14px;
    left: 10px;
    bottom: 0px;
    color: #fff;
    padding: 1px 6px;
    background: #91b1b8;
    z-index: 10;
}

.section-frontEnd .audio_comment .timer_en {
    position: absolute;
    color: #43728b;
    font-size: 14px;
    right: 10px;
    bottom: 0px;
    color: #fff;
    padding: 1px 6px;
    background: #43468b;
    z-index: 10;
}

.section-frontEnd .audio_comment .play {
    margin: 0 20px;
}

.section-frontEnd .audio_comment .play > button {
    display: block;
    border: none;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    background: none;
    outline: none !important;
}

/*.section-frontEnd .audio_comment.firstPlayer{margin-top: 30px;}*/
.section-frontEnd .btn_container h4 {
    margin-bottom: 30px;
    font-size: 16px;
}

.section-frontEnd .gen_text {
    font-size: 20px;
    line-height: 35px;
    margin-top: 20px;
}

.section-frontEnd .section {
    padding: 40px 0;
}

.section-frontEnd .section_title {
    text-align: center;
}

.section-frontEnd .section_title h2 {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    text-transform: uppercase;
}

.section-frontEnd .sec_line {
    background: #e8e8e8;
    height: 1px;
    width: 100%;
    margin: 25px 0;
}

.section-frontEnd .sec_line.dark {
    background: #cdcdcd;
}

.section-frontEnd .video_container img {
    max-width: 100%;
}

.section-frontEnd .video_container {
    margin-top: 40px;
}

.section-frontEnd .service_container {
    border: 1px solid #e4e4e4;
    min-height: 400px;
    padding: 50px 30px;
    margin-top: 40px;
}

.section-frontEnd .about_text {
    width: 100%;
    max-width: 980px;
    margin: 20px auto 0 auto;
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    margin-top: 40px;
    letter-spacing: 1px;
}

.section-frontEnd #footer .footer_heading {
    font-size: 16px;
    color: #e38525;
    text-transform: uppercase;
    letter-spacing: 0.5pt;
}

.section-frontEnd #footer .footer_menus {
    margin-top: 30px;
    letter-spacing: 0.5pt;
}

.section-frontEnd #footer .social_icons {
    margin-top: 30px;
    display: flex;
}

.section-frontEnd #footer .social_icons li a {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    transition: all 0.3s;
    text-align: center;
    line-height: 30px;
    color: #fff;
    background: #5e5e5e;
}

.section-frontEnd #footer .social_icons li a > i {
    line-height: inherit;
}

.section-frontEnd #footer .social_icons li a:active {
    transform: translateY(2px) scale(0.95);
}

.section-frontEnd #footer .social_icons li:nth-child(1) a:hover {
    background: #475993;
    animation: hover_fb 0.5s;
}

.section-frontEnd #footer .social_icons li:nth-child(2) a:hover {
    background: #ca2c41;
    animation: hover_dr 0.5s;
}

.section-frontEnd #footer .social_icons li:nth-child(3) a:hover {
    background: #76a9ea;
    animation: hover_tw 0.5s;
}

.section-frontEnd #footer .social_icons li:nth-child(4) a:hover {
    background: #f61c0d;
    animation: hover_yt 0.5s;
}

.section-frontEnd #footer .social_icons li:nth-child(5) a:hover {
    background: #69b4e5;
    animation: hover_vm 0.5s;
}

.section-frontEnd #footer .social_icons li:not(:last-child) a {
    margin-right: 15px;
}

.section-frontEnd .footer_menus li {
    margin-bottom: 7px;
}

.section-frontEnd .footer_menus li a {
    color: inherit;
}

.section-frontEnd #footer .privacy {
    display: flex;
    float: right;
    padding-bottom: 30px;
}

.section-frontEnd #footer .privacy li:not(:last-child) {
    margin-right: 20px;
}

.section-frontEnd #footer .privacy li a {
    color: inherit;
}

.section-frontEnd .screen_imgs .owl-item {
    text-align: center;
}

.section-frontEnd .service_container h3 {
    font-size: 24px;
    letter-spacing: 1px;
    margin-top: 30px;
}

.section-frontEnd .service_container p {
    font-size: 16px;
    margin-top: 30px;
}

.section-frontEnd .section_title .line {
    display: inline-block;
    height: 1px;
    background: #ccc;
    max-width: 500px;
    width: 100%;
    margin-top: 33px;
}

.section-frontEnd .section_content {
    margin-top: 80px;
}

.section-frontEnd .icon_box {
    margin-top: 20px;
    padding: 0 2vw;
    min-height: 190px;
}

.section-frontEnd .icon_box img {
    width: 90px;
}

.section-frontEnd .icon_box h3 {
    margin-top: 5px;
    line-height: 30px;
    font-size: 18px;
}

.section-frontEnd .portfolio_title h3 {
    font-size: 30px;
    text-align: center;
}

.section-frontEnd #portfolio .section_content {
    margin-top: 50px;
}

.section-frontEnd .portfolio_img,
.section-frontEnd .portfolio_txt {
    margin-top: 50px;
}

.section-frontEnd .portfolio_img img {
    max-width: 100%;
}

.section-frontEnd .portfolio_txt p {
    font-size: 1.5vw;
    line-height: 30px;
}

.section-frontEnd .portfolio_control i {
    margin-top: calc(300px - 8vw);
    font-size: 5vw;
    color: #666;
}

.section-frontEnd .portfolio_control i:hover {
    color: #d5870d;
    cursor: pointer;
}

.section-frontEnd .technologies {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.section-frontEnd .technologies li {
    display: inline-block;
    vertical-align: middle;
    margin: 15px 15px 15px 0;
}

.section-frontEnd .owl-theme .owl-controls {
    margin-top: 70px;
}

.section-frontEnd .owl-theme .owl-controls .owl-page span {
    width: 35px;
}

.section-frontEnd .owl-theme .owl-controls .owl-page.active span,
.section-frontEnd .owl-theme .owl-controls.clickable .owl-page:hover span {
    background: #d5870d;
}

.section-frontEnd .testi_txt {
    font-size: 18px;
    font-weight: lighter;
    color: #fff;
    line-height: 40px;
    margin-top: 20px;
    text-align: left;
}

.section-frontEnd .testi_img {
    font-size: 0;
    width: 100%;
    max-width: 700px;
    display: inline-block;
}

.section-frontEnd .testi_img img {
    border: 30px solid #029daf;
    max-width: 255px;
    width: 100%;
}

.section-frontEnd .testi_author {
    margin-top: 30px;
    color: #fff;
    font-size: 20px;
    text-align: center;
}

.section-frontEnd .testi_author strong {
    font-size: 24px;
    font-weight: 600;
}

.section-frontEnd #cost {
    background: #e5d599;
}

.section-frontEnd .cost_box {
    background: #fff;
    border-radius: 7px;
    overflow: hidden;
}

.section-frontEnd .cost_box .pkg_title {
    padding: 20px 10px;
    color: #fff;
    text-align: center;
    font-size: 26px;
}

.section-frontEnd .cost_box .pkg_price {
    padding: 18px 10px;
    color: #fff;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
}

.section-frontEnd .junior .pkg_title {
    background: #24a4ab;
}

.section-frontEnd .mid .pkg_title {
    background: #ee892c;
    padding-top: 40px;
}

.section-frontEnd .senior .pkg_title {
    background: #e33f5c;
}

.section-frontEnd .junior .pkg_price {
    background: #05c1d7;
}

.section-frontEnd .mid .pkg_price {
    background: #faa65f;
}

.section-frontEnd .senior .pkg_price {
    background: #f75178;
}

.section-frontEnd .small_box {
    min-height: 690px;
    margin-top: 70px;
}

.section-frontEnd .large_box {
    min-height: 710px;
    margin-top: 50px;
}

.section-frontEnd .cost_item {
    min-height: 55px;
    line-height: 55px;
    padding: 0 30px;
    font-size: 18px;
    border-bottom: 1px solid;
    vertical-align: middle;
}

.section-frontEnd .cost_item:last-child {
    border: none;
}

.section-frontEnd .cost_item span {
    display: inline-block;
    line-height: 40px;
    vertical-align: middle;
}

.section-frontEnd .btn_cost {
    margin: 30px 0;
}

.section-frontEnd .junior .cost_item {
    border-color: #ccc;
}

.section-frontEnd .mid .cost_item {
    border-color: #ccc;
}

.section-frontEnd .senior .cost_item {
    border-color: #ccc;
}

.section-frontEnd .cost_text {
    font-size: 18px;
    font-weight: lighter;
    text-align: center;
    margin-top: 50px;
}

.section-frontEnd #questions .portfolio_control i {
    margin-top: calc(200px - 8vw);
}

.section-frontEnd .question {
    font-size: 24px;
    margin-top: 40px;
    font-weight: bold;
}

.section-frontEnd .questionTooltip {
    display: inline-flex;
    height: 22px;
    width: 22px;
    align-items: center;
    justify-content: center;
    background: #eee;
    border-radius: 100px;
    font-weight: 500;
}

.section-frontEnd .questionTooltip:hover {
    background: #ccc;
    cursor: pointer;
}

.section-frontEnd .troubleUploading {
    color: #002d62;
    font-weight: 600;
}

.section-frontEnd .answer {
    font-size: 18px;
    margin-top: 20px;
}

.section-frontEnd .contact_control {
    background: none;
    border: none;
    color: #333;
    width: 100%;
    line-height: 45px;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 0 20px;
    outline: none;
    border-bottom: 1px solid #333;
    transition: all 0.3s;
    font-family: inherit;
    margin-top: 40px;
    max-width: 100%;
}

.section-frontEnd .contact_control:focus {
    border-color: #029daf;
    color: #029daf;
}

.section-frontEnd textarea.contact_control {
    max-height: 150px;
}

.section-frontEnd ::-webkit-input-placeholder {
    color: #666;
    transition: all 0.3s;
}

.section-frontEnd ::-moz-placeholder {
    color: #666;
    transition: all 0.3s;
}

.section-frontEnd :-ms-input-placeholder {
    color: #666;
    transition: all 0.3s;
}

.section-frontEnd :-moz-placeholder {
    color: #666;
    transition: all 0.3s;
}

.section-frontEnd *:focus::-webkit-input-placeholder,
.section-frontEnd *:hover::-webkit-input-placeholder {
    color: #a3a3a3 !important;
}

.section-frontEnd *:focus::-moz-placeholder,
.section-frontEnd *:hover::-moz-placeholder {
    color: #a3a3a3 !important;
}

.section-frontEnd *:focus:-ms-input-placeholder,
.section-frontEnd *:hover:-ms-input-placeholder {
    color: #a3a3a3 !important;
}

.section-frontEnd *:focus:-moz-placeholder,
.section-frontEnd *:hover:-moz-placeholder {
    color: #a3a3a3 !important;
}

.section-frontEnd .contact_label {
    font-size: 18px;
    color: #029daf;
    margin-top: 50px;
    padding: 0 20px;
}

.section-frontEnd .contact_btn {
    color: #fff;
    background: #029daf;
    transition: all 0.3s;
    margin-top: 60px;
    width: 100%;
    max-width: 300px;
    display: inline-block;
    letter-spacing: 1px;
}

.section-frontEnd .text_left_center {
    text-align: left !important;
}

.section-frontEnd .text_right_center {
    text-align: right !important;
}

.section-frontEnd .bg_alter {
    background: #fdfdfd;
}

.section-frontEnd .error {
    color: red;
}

/*Login page start*/

.section-frontEnd .login_icon {
    margin-top: 40px;
}

.section-frontEnd #header,
.section-frontEnd footer {
    background: #fff;
}

.section-frontEnd .login_text {
    text-align: center;
    margin: 40px auto 0 auto;
    width: 100%;
    max-width: 680px;
    font-size: 22px;
    letter-spacing: 1pt;
    font-weight: 300;
}

.section-frontEnd .using_email {
    color: #15a0cd;
    margin-top: 30px;
    font-size: 20px;
    font-weight: 300;
}

.section-frontEnd .recover_email {
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-top: 25px;
    line-height: 3;
    border: 1px solid #dedede;
    outline: none !important;
}

.section-frontEnd .recover_email:focus {
    border: 1px solid #15a0cd;
}

.section-frontEnd .recover_btn {
    padding: 12.5px 30px;
    font-size: 14px;
    border: none;
    background: #43458b;
    color: #fff;
    margin-top: 25px;
    text-transform: uppercase;
}

.section-frontEnd .first_section.login_sec {
    padding-bottom: 70px;
}

.section-frontEnd .recover_email::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #9b9b9b;
}

.section-frontEnd .recover_email::-moz-placeholder {
    /* Firefox 19+ */
    color: #9b9b9b;
}

.section-frontEnd .recover_email:-ms-input-placeholder {
    /* IE 10+ */
    color: #9b9b9b;
}

.section-frontEnd .recover_email:-moz-placeholder {
    /* Firefox 18- */
    color: #9b9b9b;
}

.section-frontEnd .navbar .nav li.pull-right a {
    background: url("../img/logout.png") no-repeat center right;
    padding-right: 30px;
}

.section-frontEnd .navbar.pos_abs .nav li.pull-right a {
    background-image: url("../img/logout_w.png");
}

.section-frontEnd .no_slider .navbar .navbar-brand img {
    height: 48px;
}

/*Frontend CSS Start*/
.section-frontEnd .nav li a {
    text-transform: uppercase;
}

.section-frontEnd .navbar-default .navbar-nav > li.loginIcon > a,
.section-frontEnd .no_slider .navbar-default .navbar-nav > li.loginIcon > a {
    background: url("../img/login_frontEnd.png") no-repeat 10px center;
    padding-left: 40px;
}

.section-frontEnd .navbar-default .navbar-nav > li.signUp > a,
.section-frontEnd .no_slider .navbar-default .navbar-nav > li.signUp > a {
    background: #61bc7e;
    color: #fff !important;
    margin-top: 14px;
    padding: 5px 10px;
    border-radius: 2px;
    transition: 0.3s all;
}

.section-frontEnd .navbar-default .navbar-nav > li.signUp > a:after,
.section-frontEnd .no_slider .navbar-default .navbar-nav > li.signUp > a:after {
    display: none;
}

.section-frontEnd .navbar-default .navbar-nav > li.signUp > a:hover,
.section-frontEnd .no_slider .navbar-default .navbar-nav > li.signUp > a:hover {
    background: #529a69;
}

.section-frontEnd #footer {
    border-top: 1px solid #e8e8e8;
}

.section-frontEnd #footer .socialIcons a {
    display: inline-block;
    margin: 5px 10px;
    font-size: 18px;
    color: #464646;
    text-decoration: none;
}

.section-frontEnd #footer .socialIcons a:nth-child(1):hover {
    color: #4267b2;
    text-decoration: none;
}
.section-frontEnd #footer .socialIcons a:nth-child(2):hover {
    color: #23abe2;
    text-decoration: none;
}
.section-frontEnd #footer .socialIcons a:nth-child(3):hover {
    color: #2581bc;
    text-decoration: none;
}
.section-frontEnd #footer .socialIcons a:nth-child(4):hover {
    color: #b12f25;
    text-decoration: none;
}

.section-frontEnd a.link_hover,
.section-frontEnd .link_hover a {
    position: relative;
    font-weight: 500;
    display: inline-block;
}

.section-frontEnd a.link_hover:after,
.section-frontEnd .link_hover a:after {
    content: "";
    width: 0;
    height: 1pt;
    /* Permalink - use to edit and share this */
    background: #8a4c9e; /* Old browsers */
    background: -moz-linear-gradient(
        left,
        #8a4c9e 0%,
        #c31d8b 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        #8a4c9e 0%,
        #c31d8b 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        #8a4c9e 0%,
        #c31d8b 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a4c9e', endColorstr='#c31d8b',GradientType=1 ); /* IE6-9 */
    display: block;
    transition: 0.3s all;
    left: 0;
    bottom: -1pt;
    position: absolute;
}

.section-frontEnd a.link_hover:hover:after,
.section-frontEnd .link_hover a:hover:after {
    width: 100% !important;
}

.section-frontEnd .flexElem {
    display: flex;
}

#video .vidContainer {
    background: #fff;
    padding: 20px;
    border: 1px solid #ddd;
}

.howItWorksPoints {
    font-weight: 500;
}

span.howItWorksNumber {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    margin-right: 10px;
    background-color: #333;
    color: #fff;
}

span.howItWorksNumber:empty {
    height: 13px;
    width: 13px;
    margin-top: 4px;
    border-radius: 4px;
    transform: rotate(45deg);
}

.section-frontEnd .inlineFlexElem {
    display: inline-flex;
}

.section-frontEnd #footer .footerCol {
    padding-top: 50px;
    padding-bottom: 40px;
}

.section-frontEnd .flexElem .footerCol:last-child {
    width: 100%;
}

.section-frontEnd .slider_main {
    background: url("../img/main_banner.png") no-repeat right bottom, #fff;
    background-size: 100% !important;
    min-height: 570px;
    align-items: center;
    border-bottom: 1px solid #ececec;
}

.section-frontEnd .useBox {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-top: 40px;
}
.section-frontEnd .useBox img {
    margin: 10px 0;
}
.section-frontEnd .useBox .boxHeading {
    margin: 20px 0 10px 0;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
}
.section-frontEnd .useBox .boxHeading + p {
    margin-top: 0;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
}
.section-frontEnd .useBox .view_more {
    display: inline-block;
    padding: 10px;
    border: 1px solid #8b817e;
    margin-top: 30px;
    border-radius: 22px;
    font-size: 12px;
    text-transform: uppercase;
    width: 100%;
    width: 120px;
    color: #464646;
    transition: 0.3s all;
}
.section-frontEnd .useBox .view_more:hover {
    background: #8b817e;
    color: #fff;
}
.section-frontEnd #since .since_text {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    margin-top: 40px;
    letter-spacing: 1px;
}
.section-frontEnd #since .since_text.smaller {
    font-size: 16px;
    padding: 0 1vw;
    margin-bottom: 20px;
}

.section-frontEnd #since .since_icon {
    margin-top: 30px;
}

.section-frontEnd #since .sinceBtns > a {
    width: 100%;
    max-width: 250px;
    margin: 10px 20px;
}

.section-frontEnd .themeBtn_14 {
    display: inline-block;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    color: #fff;
    padding: 15px 20px;
    border-radius: 50px;
    letter-spacing: 0.5pt;
}

.section-frontEnd .no_radius {
    border-radius: 0px !important;
}

.section-frontEnd .themeInput {
    display: inline-block;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    padding: 10px 15px;
    border: 1px solid #bbbbbb;
    line-height: 19px;
}
.section-frontEnd .themeInput.nameIcon {
    background: url("../img/name_icon.png") no-repeat 16px center, #fff;
}
.section-frontEnd .themeInput.hasIcon {
    padding-left: 40px;
}

.section-frontEnd .themeInput.big {
    padding: 13px 17px;
    font-size: 16px;
    line-height: 25px;
}

.section-frontEnd .themeInput.roundedInput {
    border-radius: 100px;
}

.theme_table thead {
    background: #999;
}

.table.theme_table thead tr th {
    color: #fff;
    font-size: inherit;
}

.section-frontEnd .alignCenter {
    align-items: center;
}

.section-frontEnd .spaceBetween {
    justify-content: space-between;
}
.section-frontEnd .justifyCenter {
    justify-content: center;
}
.section-frontEnd .display_ib {
    display: inline-block !important;
}

.section-frontEnd .frontEndLogo {
    width: 100%;
    max-width: 155px;
    max-height: 48px;
}

.ReactModal__Body--open .ril__captionContent {
    width: 100%;
}

.section-frontEnd .themeBtn_14,
.section-frontEnd .themeBtn_12 {
    transition: 0.3s all;
    border: none;
    text-decoration: none;
    line-height: normal;
    outline: none;
    max-width: 100%;
    cursor: pointer;
}
.section-frontEnd .themeBtn_14.block,
.section-frontEnd .themeBtn_12.block {
    width: 100%;
    text-align: center;
}

.section-frontEnd .block,
.section-frontEnd .block {
    width: 100% !important;
}
.section-frontEnd .themeBtn_14 svg,
.section-frontEnd .themeBtn_12 svg {
    vertical-align: middle;
    margin-left: 7px;
}

.section-frontEnd .themeBtn_14 svg {
    font-size: 18px;
}

.section-frontEnd .themeBtn_12 svg {
    font-size: 16px;
}

.section-frontEnd .themeBtn_12 {
    display: inline-block;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    color: #fff;
    padding: 12px 15px;
    border-radius: 50px;
    letter-spacing: 0.5pt;
}

.section-frontEnd .gradient_btn {
    background: #999;
    transition: 0.3s all;
}

.section-frontEnd .hover_light:hover {
    box-shadow: inset 0 0 0 100px rgba(255, 255, 255, 0.3);
}

.section-frontEnd .hover_dark:hover {
    box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.3);
}

.section-frontEnd #benefits .slider_container {
    display: flex;
    min-height: 450px;
    align-items: center;
    position: relative;
}

.section-frontEnd #benefits .slider_container .container {
    display: flex;
    align-items: center;
}

.section-frontEnd #benefits .slider_container .container .flexCol {
    width: 100%;
}

.section-frontEnd #benefits .slider_container .container .side_slider {
    margin-bottom: 30px;
    width: 100%;
    max-width: 570px;
}

.section-frontEnd #benefits .slider_container .container .smaller_heading {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    color: #fff;
    letter-spacing: 1px;
    position: relative;
}

.section-frontEnd
    #benefits
    .slider_container
    .container
    .smaller_heading:after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    max-width: 86px;
    background: #fff;
    margin: 20px 0;
}

.section-frontEnd #benefits .slider_container .container .bigger_heading {
    font-size: 48px;
    color: #fff;
    line-height: 60px;
    font-family: "GOTHICB", sans-serif;
    letter-spacing: 1pt;
}

.section-frontEnd #benefits .slider_container .container .benefitsBtn {
    font-size: 14px;
    color: #2a9cc1;
    display: inline-block;
    padding: 13px 25px;
    background: #fff;
    border-radius: 50px;
    margin-top: 30px;
    transition: 0.3s all;
    text-transform: uppercase;
}

.section-frontEnd .section_heading {
    font-size: 36px;
    font-family: "Poppins", sans-serif;
}

.section-frontEnd #features .feature_heading + p {
    margin-top: 25px;
}

.section-frontEnd #features .features_container {
    position: relative;
}

.section-frontEnd #features .features_container:before {
    content: "";
    position: absolute;
    left: 30px;
    right: 20%;
    top: 110px;
    height: 1px;
    background: #d9d9d9;
    display: block;
}

.section-frontEnd .lh_1-5 {
    line-height: 1.5;
}

.section-frontEnd .lh_2 {
    line-height: 2;
}

.section-frontEnd .lh_3 {
    line-height: 3;
}

.section-frontEnd .text_elipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 100%;
}

.text_14,
.moving .text_14 {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5pt;
}

.moving .uploadedImageSize img {
    position: relative;
    z-index: 2;
    opacity: 0.8;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.moving .uploadedImageSize {
    opacity: 1 !important;
}

.moving .uploadedImageSize .selectedCount {
    position: absolute;
    z-index: 1000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 100%;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

.uploadedImageSize .delete_img.delete_icon,
.uploadedImageSize .delete_img.zoom_icon,
.uploadedImageSize .approvalChk {
    display: none !important;
}

.uploadedImageSize:hover .delete_img.delete_icon,
.uploadedImageSize:hover .delete_img.zoom_icon,
.uploadedImageSize:hover .approvalChk {
    display: block !important;
    animation: zoomIn 0.3s ease;
}

.uploadedImageSize.notApproved {
    overflow: hidden;
}

.uploadedImageSize.notApproved:before {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    /* background: url("../img/notApproved.png") no-repeat center, rgba(0, 0, 0, 0.5); */
    background: rgba(0, 0, 0, 0.5);
    background-size: 100px;
    transition: 0.2s all;
}

#uploadFileInfo.popover {
    max-width: 400px;
}

.uploadedImageSize.notApproved:hover:before {
    height: 0%;
}

.moving .uploadedImageSize .appendedImgs {
    position: absolute;
    opacity: 1;
    z-index: 1;
    transform: scale(1.1);
    top: -10px;
}

.moving .uploadedImageSize .appendedImgs:nth-child(even) {
    left: -10px;
}

.moving .uploadedImageSize .appendedImgs:nth-child(odd) {
    right: -10px;
}

.companyImagesGrid .selectedDiv {
    background: rgba(124, 210, 240, 0.2);
    transition: 0.3s all;
}

a.inheritLink {
    color: inherit !important;
    font: inherit !important;
    text-decoration: inherit !important;
}

.text_14.light {
    font-weight: 300;
}

.text_16,
.moving .text_16 {
    font-size: 16px !important;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5pt;
}
.text_16.light {
    font-weight: 300;
}
.text_18,
.moving .text_18 {
    font-size: 18px !important;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5pt;
    font-weight: 500;
}

.text_22,
.moving .text_22 {
    font-size: 22px !important;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5pt;
    font-weight: 500;
}

.text_24,
.moving .text_24 {
    font-size: 24px !important;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5pt;
    font-weight: 500;
}
.text_36 {
    font-size: 36px !important;
}
.text-muted.dark {
    color: #777 !important;
}

.text-white {
    color: #fff !important;
}

.text-black {
    color: #4a4a4a !important;
}

.gothic {
    font-family: "GOTHIC", sans-serif !important;
}

.gothicBold {
    font-family: "GOTHICB", sans-serif !important;
}

.text_18.light {
    font-weight: 300;
}

.section-frontEnd .text_white {
    color: #fff !important;
}

.section-frontEnd .features_container > div .feature_box {
    padding-top: 135px;
    margin-top: 60px;
    background-position: left top;
}

.section-frontEnd .features_container > div:nth-child(1) .feature_box {
    background: url("../img/feature_1.png") no-repeat;
}
.section-frontEnd .features_container > div:nth-child(2) .feature_box {
    background: url("../img/feature_2.png") no-repeat;
}
.section-frontEnd .features_container > div:nth-child(3) .feature_box {
    background: url("../img/feature_3.png") no-repeat;
}
.section-frontEnd .features_container > div:nth-child(4) .feature_box {
    background: url("../img/feature_4.png") no-repeat;
}

.section-frontEnd #video {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

.section-frontEnd #video .section_heading {
    font-size: 24px;
    font-family: "GOTHICB", sans-serif;
    margin-top: 20px;
    margin-bottom: 30px;
}

.section-frontEnd #video .video_placeholder,
.section-frontEnd .video_placeholder {
    margin-bottom: 20px;
    position: relative;
}

.section-frontEnd #video .video_placeholder .video_play,
.section-frontEnd .video_placeholder .video_play {
    position: absolute;
    width: 70px;
    height: 70px;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    transition: 0.3s all;
}

.section-frontEnd #video .video_placeholder .video_play:after,
.section-frontEnd .video_placeholder .video_play:after {
    content: "";
    display: block;
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 34px;
    border-color: transparent transparent transparent #fff;
    transition: 100ms all ease;
    border-style: solid;
    border-width: 17px 0 17px 20px;
    margin-left: 5px;
}

.section-frontEnd #students {
    background: url("../img/students_travel_img.png") no-repeat right center;
    background-size: 650px;
}

.section-frontEnd #students .section_icon {
    margin-bottom: 20px;
}

.section-frontEnd #students .student_text p,
.section-frontEnd #family .family_text p {
    margin-top: 25px;
}

.section-frontEnd #students .view_btn,
.section-frontEnd #family .view_btn {
    margin-top: 30px;
    transition: 0.3s all;
}

.section-frontEnd #family {
    background: url("../img/family_staying_img.png") no-repeat left center;
    background-size: 640px;
}

.section-frontEnd #family .section_icon {
    margin-top: 40px;
    margin-bottom: 20px;
}

.section-frontEnd #the_journal .useBox {
    padding: 0;
    border-radius: 2px;
}

.section-frontEnd #the_journal .useBox img {
    margin-top: 0;
    border-radius: 2px 2px 0px 0px;
    width: 100%;
}

.section-frontEnd #the_journal .useBox img {
    margin-top: 0;
    border-radius: 2px 2px 0px 0px;
}

.section-frontEnd #the_journal .useBox .boxHeading {
    font-family: "GOTHICB", sans-serif;
    font-size: 18px;
    padding: 0 20px;
}

.featureAfter {
    margin-top: 30px;
}

.featuresList {
    list-style: none;
    font-family: "Poppins", sans-serif;
    color: #fff;
}

.magnifier img {
    height: auto !important;
}

ul.diamond li {
    position: relative;
    padding: 0 25px;
    word-break: break-word;
}

ul.diamond li::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: rgba(255, 255, 255, 0.9);
}

a[href^="mailto"] {
    word-wrap: break-word;
    word-break: break-all;
}

ul.diamond li::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 10px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: rgba(255, 255, 255, 0.6);
}

.hasFieldIcon {
    position: relative;
}

.hasFieldIcon .fieldIcon {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 40px;
    display: inline-block;
}

.contactFormContainer .form-control {
    font-family: "Poppins", sans-serif;
}

.hasFieldIcon .fieldIcon.userIcon {
    background: url("../img/input_user.png") no-repeat center;
}

.hasFieldIcon .fieldIcon.emailIcon {
    background: url("../img/input_email.png") no-repeat center;
}

.hasFieldIcon .fieldIcon.companyIcon {
    background: url("../img/input_company.png") no-repeat center;
}

.hasFieldIcon input {
    text-indent: 28px;
}

.contactFormContainer textarea.form-control {
    line-height: 2;
}

.featuresList li + li {
    margin-top: 20px;
}

.featureAfter {
    padding: 10px;
}

.featureAfter .boxHeading {
    font-family: "GOTHIC", sans-serif;
    font-size: 18px;
    padding: 0 20px;
    margin-top: 20px;
}

.section-frontEnd #the_journal .useBox .boxHeading + p {
    padding: 0 20px;
    font-family: "GOTHIC", sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5pt;
}

.section-frontEnd #the_journal .useBox .boxHeading + p + div {
    padding: 0 20px;
    padding-bottom: 20px;
}
.section-frontEnd #the_journal .useBox .boxHeading + p + div .themeBtn_12 {
    text-align: center;
    border: none;
    margin-top: 30px;
    transition: 0.3s all;
}

.leftIconBtn,
.rightIconBtn {
    display: inline-flex !important;
    align-items: center !important;
}

.leftIconBtn > svg,
.leftIconBtn > i {
    margin-right: 5px !important;
    margin-left: 0 !important;
}

.rightIconBtn > svg,
.rightIconBtn > i {
    margin-left: 5px !important;
    margin-right: 0 !important;
}

.section-frontEnd #testimonial {
    color: #fff;
    text-align: center;
}
.section-frontEnd #testimonial .testi_container p {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.5pt;
    font-family: "GOTHIC", sans-serif;
    margin: 20px 0;
}
.section-frontEnd #testimonial .info {
    letter-spacing: 0.5pt;
    font-size: 16px;
    font-family: "GOTHIC", sans-serif;
}

.slick-slide div,
.slick-slide p {
    outline: none !important;
}

.section-frontEnd .slick-slide {
    height: auto;
}

.section-frontEnd .testi_slider .slick-dots {
    position: relative;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.section-frontEnd .testi_slider .slick-dots:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: #fff;
}

.section-frontEnd .testi_slider .slick-prev:before,
.section-frontEnd .testi_slider .slick-next:before {
    display: none;
}

.section-frontEnd .testi_slider .slick-slider .slick-arrow {
    top: auto;
    bottom: -5px;
    background: #fff;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    z-index: 100;
}

.section-frontEnd .testi_slider .testi_info.slick-slider .slick-arrow {
    display: none !important;
}

.section-frontEnd .testi_slider .slick-slider .slick-arrow.slick-prev {
    left: 0;
}

.section-frontEnd .testi_slider .slick-slider .slick-arrow.slick-next {
    right: 0;
}

.section-frontEnd .testi_slider .testi_info.slick-slider {
    margin-top: 50px;
}

.section-frontEnd .testi_slider .slick-dots li {
    width: 60px;
    position: relative;
    z-index: 10;
    height: 60px;
    background: brown;
    margin: 10px 30px;
    transform: rotate(45deg);
    overflow: hidden;
    border-radius: 15px;
    transition: 0.3s all;
}

.section-frontEnd .testi_slider .slick-dots li.slick-active {
    height: 80px;
    width: 80px;
}

.section-frontEnd .testi_slider .slick-dots li .thumb {
    height: 157%;
    width: 157%;
}

.section-frontEnd .testi_slider .slick-dots li .thumb {
    transform: rotate(-45deg) translateY(-19px);
    display: block;
}

/* .section-frontEnd #benefits .side_slider .slick-slider img{
  width: auto !important
} */

.section-frontEnd #benefits .side_slider .slick-slider .slick-dots {
    position: absolute;
    left: 16px;
    width: auto;
    bottom: 16px;
}

.section-frontEnd #benefits .side_slider .slick-slider .slick-dots li + li {
    margin-left: 10px;
}
.section-frontEnd #benefits .side_slider .slick-slider .slick-dots li button {
    background: #fff;
}
.section-frontEnd
    #benefits
    .side_slider
    .slick-slider
    .slick-dots
    li
    button:before {
    content: "";
    opacity: 1 !important;
    background: #fff;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    transition: 0.3s all;
}

.section-frontEnd
    #benefits
    .side_slider
    .slick-slider
    .slick-dots
    li.slick-active
    button:before {
    background: #7dd7d4;
}

.section-frontEnd #benefits .side_slider .slick-slider .slick-arrow:before {
    display: none;
}

.section-frontEnd #benefits .side_slider .slick-slider .slick-arrow {
    color: #fff;
    font-size: 24px;
    background: rgba(0, 0, 0, 0.5);
    width: auto;
    padding: 5px;
    height: auto;
    z-index: 100;
    opacity: 0;
    transition: 0.3s all;
}

.section-frontEnd #benefits .side_slider .slick-slider:hover .slick-arrow {
    opacity: 1;
}

.section-frontEnd #benefits .side_slider .slick-slider .slick-arrow.slick-next {
    right: 0;
}

.section-frontEnd #benefits .side_slider .slick-slider .slick-arrow.slick-prev {
    left: 0;
}

.section-frontEnd #clients .clients {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section-frontEnd #clients .clients .client {
    padding: 6px;
    transition: 0.3s all;
}

.section-frontEnd #clients .panel_heading {
    margin-top: 20px;
}

.section-frontEnd #clients .container {
    margin-top: 30px;
    margin-bottom: 20px;
}

.section-frontEnd .client {
    background: #f5f5f5;
}

.galleryInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.galleryInfo .galleryId {
    display: inline-flex;
    padding: 3px 7px;
    background: tomato;
    color: #fff;
    border-radius: 24px;
}

.section-frontEnd #photo_gallery .uploader_box {
    background: #fff;
    border: 1px solid #d3d6dc;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 60px;
    position: relative;
}

.section-frontEnd #photo_gallery .doneBtn {
    position: absolute;
    bottom: -79px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 130px;
    text-align: center;
}

.section-frontEnd #photo_gallery .uploader_box .close_uploader {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    font-size: 30px;
    color: #fff;
    z-index: 100;
    cursor: pointer;
}

.section-frontEnd #photo_gallery .uploader_box .uploader_box_col {
    width: 100%;
    margin-top: 20px;
}

.section-frontEnd .infoBox {
    background: #eee;
    padding: 20px 15px;
    font-family: "Poppins", sans-serif;
    color: #464646;
    font-size: 14px;
    letter-spacing: 0.6pt;
}

.section-frontEnd .infoBox.redBox {
    background: #f2dede;
}

.section-frontEnd .red_bold {
    color: #c83231;
    font-weight: 600;
}

.section-frontEnd .uploaderProgBars {
    max-height: 300px;
    overflow-y: auto;
}

.prevImgContainer .imgContainer {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
}

.prevImgContainer .imgContainer .prevImage {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
}

.section-frontEnd .uploaderProgBars .table tbody tr td {
    border-top: none;
}

.section-frontEnd .uploaderProgBars .progress {
    margin-bottom: 0;
    text-align: center;
}

.section-frontEnd .uploaderProgBars .uploaderFileAction {
    text-align: center;
    color: tomato;
}

.section-frontEnd .uploaderProgBars .uploaderFileAction i {
    cursor: pointer;
}

.section-frontEnd #photo_gallery .uploader_box .addName .addNameForm > div {
    width: 100%;
    margin-top: 20px;
}

.section-frontEnd #photo_gallery .uploadActions {
    align-items: stretch;
}

.section-frontEnd #photo_gallery .uploadActions > div {
    width: 100%;
}

.section-frontEnd #photo_gallery .uploadActions > div > .currAction {
    display: flex;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #fff;
    padding: 15px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 20px;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s all;
    border: none;
    outline: none;
    width: 100%;
}

.section-frontEnd #photo_gallery .uploadActions > div > a > span {
    margin-left: 10px;
}

.section-frontEnd #photo_gallery .companyContainer .companyLogo {
    width: 100%;
    max-width: 200px;
    height: auto;
}
.section-frontEnd #photo_gallery .companyContainer .companyLogo + div {
    margin-left: 20px;
}
.section-frontEnd
    #photo_gallery
    .companyContainer
    .companyLogo
    + div
    .companyName {
    font-size: 22px;
    font-family: "Poppins", sans-serif;
}

.section-frontEnd #photo_gallery .uploadDetails > table {
    width: 100%;
    margin-top: 20px;
}

.section-frontEnd #photo_gallery .uploadDetails > table tr td {
    padding: 5px 3px;
}

.section-frontEnd #photo_gallery .uploadDetails * {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
}

.section-frontEnd
    #companyImagesInfo
    .container
    > div:first-child
    > div:last-child {
    width: 100%;
    max-width: 223px;
}

.section-frontEnd #companyImagesInfo .companyImagesGrid > div {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 30px;
    text-align: center;
}

.section-frontEnd #companyImagesInfo .companyImagesGrid.grid_0 > div {
    grid-template-columns: repeat(6, 1fr);
}

.section-frontEnd #companyImagesInfo .companyImagesGrid.grid_1 > div {
    grid-template-columns: repeat(5, 1fr);
}

.section-frontEnd #companyImagesInfo .companyImagesGrid.grid_2 > div {
    grid-template-columns: repeat(4, 1fr);
}

.section-frontEnd #companyImagesInfo .companyImagesGrid.grid_3 > div {
    grid-template-columns: repeat(3, 1fr);
}

.section-frontEnd #companyImagesInfo .companyImagesGrid.grid_4 > div {
    grid-template-columns: repeat(2, 1fr);
}

.section-frontEnd #companyImagesInfo .companyImagesGrid.grid_5 > div {
    grid-template-columns: repeat(1, 1fr);
}

.section-frontEnd #companyImagesInfo .companyImagesGrid .uploadedImageSize,
.moving .uploadedImageSize {
    min-height: 130px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../img/main_loader3.gif") no-repeat center, #000;
    background-size: cover;
    position: relative;
}

.moving {
    top: auto;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.3);
}
.section-frontEnd #companyImagesInfo .companyImagesGrid .uploadedImageSize img,
.moving .uploadedImageSize img {
    max-width: 100%;
    max-height: 100%;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.section-frontEnd
    #companyImagesInfo
    .companyImagesGrid
    .uploadedImageSize
    .delete_img,
.moving .uploadedImageSize .delete_img {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    height: 35px;
    width: 35px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    z-index: 100;
}

.section-frontEnd
    #companyImagesInfo
    .companyImagesGrid
    .uploadedImageSize
    .delete_img.multiSelect,
.moving .uploadedImageSize .delete_img.multiSelect {
    right: auto;
    left: 10px;
    transition: 0.3s all;
}

.panel_heading [alt="Create A Video"] {
    transition: 0.3s all;
}

.panel_heading:hover [alt="Create A Video"] {
    transform: rotate(11deg);
}

.delete_img.delete_icon {
    background: url("../img/delete_gallery_img.png") no-repeat center, #f07f70; /* Old browsers */
    background: url("../img/delete_gallery_img.png") no-repeat center,
        -moz-linear-gradient(-45deg, #f07f70 0%, #e1422e 100%); /* FF3.6-15 */
    background: url("../img/delete_gallery_img.png") no-repeat center,
        -webkit-linear-gradient(-45deg, #f07f70 0%, #e1422e 100%); /* Chrome10-25,Safari5.1-6 */
    background: url("../img/delete_gallery_img.png") no-repeat center,
        linear-gradient(135deg, #f07f70 0%, #e1422e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.delete_img.zoom_icon {
    background: url("../img/zoom_in_gallery.png") no-repeat center, #9a75e6; /* Old browsers */
    background: url("../img/zoom_in_gallery.png") no-repeat center,
        -moz-linear-gradient(-45deg, #9a75e6 0%, #d858bb 100%); /* FF3.6-15 */
    background: url("../img/zoom_in_gallery.png") no-repeat center,
        -webkit-linear-gradient(-45deg, #9a75e6 0%, #d858bb 100%); /* Chrome10-25,Safari5.1-6 */
    background: url("../img/zoom_in_gallery.png") no-repeat center,
        linear-gradient(135deg, #9a75e6 0%, #d858bb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.delete_img.multiSelect {
    background: url("../img/select_gallery.png") no-repeat center, #666; /* Old browsers */
    background: url("../img/select_gallery.png") no-repeat center,
        -moz-linear-gradient(-45deg, #666 0%, #999 100%); /* FF3.6-15 */
    background: url("../img/select_gallery.png") no-repeat center,
        -webkit-linear-gradient(-45deg, #666 0%, #999 100%); /* Chrome10-25,Safari5.1-6 */
    background: url("../img/select_gallery.png") no-repeat center,
        linear-gradient(135deg, #666 0%, #999 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.delete_img.multiSelect.imgSelected {
    background: url("../img/select_gallery.png") no-repeat center, #3b9ae3; /* Old browsers */
    background: url("../img/select_gallery.png") no-repeat center,
        -moz-linear-gradient(-45deg, #3b9ae3 0%, #2cd0d2 100%); /* FF3.6-15 */
    background: url("../img/select_gallery.png") no-repeat center,
        -webkit-linear-gradient(-45deg, #3b9ae3 0%, #2cd0d2 100%); /* Chrome10-25,Safari5.1-6 */
    background: url("../img/select_gallery.png") no-repeat center,
        linear-gradient(135deg, #3b9ae3 0%, #2cd0d2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.sampleMusicContainer {
    background: #fff;
    padding: 13px 10px;
    border: 1px solid #ccc;
}

.playMusic {
    margin-bottom: 0;
}

.playMusic li {
    background: none;
    border: none;
    transition: 0.3s all;
    color: #999;
}

.playMusic li:hover,
.playMusic li.active:hover {
    background-color: #fff !important;
    border: none !important;
    color: #2f4575 !important;
}

.playMusic li.active {
    background: url("../img/play_music.gif") no-repeat left 5px;
    background-size: 20px;
    padding-left: 30px;
    border: none;
    color: #2f4575;
}

.section-frontEnd
    #companyImagesInfo
    .companyImagesGrid
    .uploadedImageSize
    .approvalChk,
.moving .uploadedImageSize .approvalChk {
    /* padding: 5px 10px; */
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
}
.section-frontEnd
    #companyImagesInfo
    .companyImagesGrid
    .uploadedImageSize
    .approvalChk
    button.checkBtn,
.moving .uploadedImageSize .approvalChk button.checkBtn {
    padding: 5px 10px;
    background: inherit;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
}

.section-frontEnd
    #companyImagesInfo
    .companyImagesGrid
    .uploadedImageSize
    .approvalChk
    button.checkBtn:before,
.moving .uploadedImageSize .approvalChk button.checkBtn:before {
    content: "";
    display: inline-flex;
    height: 10px;
    width: 10px;
    background: transparent;
    box-shadow: 0 0 0 2px #fff;
    margin-right: 9px;
}
.section-frontEnd
    #companyImagesInfo
    .companyImagesGrid
    .uploadedImageSize
    .approvalChk
    button.checkBtn.checkedBtn:before,
.moving .uploadedImageSize .approvalChk button.checkBtn.checkedBtn:before {
    background: #b9d54a; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b9d54a', endColorstr='#c4d134',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.section-frontEnd .approvalChk .customChk input[type="checkbox"] + label,
.moving .approvalChk .customChk input[type="checkbox"] + label {
    color: #fff !important;
    vertical-align: sub;
}

.section-frontEnd .approvalChk .customChk input[type="checkbox"] + label:before,
.moving .approvalChk .customChk input[type="checkbox"] + label:before {
    border-color: #fff;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    margin-left: 10px;
}

.section-frontEnd
    .approvalChk
    .customChk
    input[type="checkbox"]:checked
    + label:before,
.moving .approvalChk .customChk input[type="checkbox"]:checked + label:before {
    background: #b9d54a; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b9d54a', endColorstr='#c4d134',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    border-radius: 0;
}

.section-frontEnd
    #companyImagesInfo
    .companyImagesGrid
    .uploadedImageSize
    .delete_img
    img,
.moving .uploadedImageSize .delete_img img {
    max-width: 100%;
    max-height: 100%;
}

.section-frontEnd #companyImagesInfo .companyImagesGrid.hasMargin {
    margin-top: 50px;
}

.section-frontEnd .subNav {
    background: #3e4755;
}

.section-frontEnd .subNav ul {
    width: 100%;
    padding: 10px 15px;
}

.section-frontEnd .sideNavBtn {
    border: none;
    background: none;
    color: #fff;
    font-size: 30px;
    outline: none !important;
}
.section-frontEnd aside {
    z-index: 101 !important;
}
.section-frontEnd .sideNavBtn.dark {
    color: #3e4755;
}

.section-frontEnd aside > nav {
    overflow-y: auto !important;
}

.section-frontEnd aside > nav h1 {
    background: #fff !important;
}

.section-frontEnd aside > nav ul li {
    padding: 0 !important;
}

.section-frontEnd aside > nav ul li a {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    color: #4a4a4a;
    font-weight: 400;
    transition: 0.3s all;
    padding: 15px 20px;
}
.section-frontEnd aside > nav ul li a i {
    margin-right: 15px;
}
.section-frontEnd aside > nav ul li + li a {
    border-top: 1px solid #e9e9e9;
}

.section-frontEnd aside > nav ul li a:hover {
    background: -webkit-linear-gradient(
        -45deg,
        #9a75e6 0%,
        #d858bb 100%
    ); /* Chrome10-25,Safari5.1-6 */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    background-clip: text;
}
_:-ms-lang(x) .section-frontEnd aside > nav ul li a:hover {
    background: -webkit-linear-gradient(
        -45deg,
        #9a75e6 0%,
        #d858bb 100%
    ); /* Chrome10-25,Safari5.1-6 */
    -webkit-background-clip: text;
    -webkit-text-fill-color: #9a75e6;
    -moz-background-clip: text;
    -moz-text-fill-color: #9a75e6;
    background-clip: text;
    color: #9a75e6;
}
.section-frontEnd aside > nav ul li a [class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
.section-frontEnd aside > nav ul li a [class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-size: 30px;
}

.section-frontEnd .features_grid {
    display: grid;
    column-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 10px;
    margin-bottom: 100px;
}

.section-frontEnd .features_grid .useBox.featureBox {
    text-align: center;
}

.section-frontEnd .features_grid .useBox.featureBox h2 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    margin-top: 20px;
}
.section-frontEnd .features_grid .useBox.featureBox p {
    margin: 15px 0;
}

.section-frontEnd .panel_heading.userLogin_heading {
    margin-top: 50px;
}

.section-frontEnd .form_tile {
    background: #fafbfc;
    border: 1px solid #bebebe;
    margin-top: 30px;
}

.section-frontEnd .theme_color {
    color: #2f4575 !important;
}

.section-frontEnd .form_tile .form_tile_heading {
    background: #2f4575;
    padding: 10px 20px;
    color: #fff;
    font-size: 20px;
    display: flex;
    font-family: "Poppins", sans-serif;
    align-items: center;
    font-weight: 300;
    letter-spacing: 0.5pt;
}
.section-frontEnd .form_tile .form_tile_heading span {
    font-weight: 300;
}

.section-frontEnd .form_tile .form_tile_content {
    padding: 20px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #545454;
}

.section-frontEnd .form_tile .form_tile_heading img {
    margin-right: 10px;
}

.section-frontEnd .form_tile .form_tile_content .form_tile_row {
    margin-left: -10px;
    margin-right: -10px;
}

.section-frontEnd
    .form_tile
    .form_tile_content
    .form_tile_row
    + .form_tile_row {
    margin-top: 15px;
}
.section-frontEnd
    .form_tile
    .form_tile_content
    .form_tile_row
    .form_tile_input {
    border-radius: 2px;
    border: 1px solid #bebebe;
    padding: 10px;
    font-family: "Poppins", sans-serif;
}

.frontEndModal .modal-header {
    background: #fff;
}

.frontEndModal .modal-title,
.frontEndModal label {
    font-family: "Poppins", sans-serif;
}

div.tooltip {
    font-family: "Poppins", sans-serif !important;
    text-shadow: none !important;
    font-size: 1rem !important;
    font-weight: lighter;
    letter-spacing: 0.5pt;
}

div.tooltip strong {
    color: aqua !important;
}

.frontEndModal .modal-title {
    font-size: 1.4rem;
}

.frontEndModal label {
    font-size: 14px;
    letter-spacing: 0.5pt;
}

.frontEndModal .section-frontEnd .themeBtn_12 {
    margin: 5px 10px;
}

.frontEndModal .modal-content {
    border-radius: 2px;
    background: #fafbfc;
}

.frontEndModal .form-control {
    border-radius: 2px;
    border: 1px solid #bebebe;
    padding: 10px;
    font-family: "Poppins", sans-serif;
    background: #fff;
    font-size: 14px;
}

.section-frontEnd
    .form_tile
    .form_tile_content
    .form_tile_row
    .form_tile_input.tile_textarea {
    min-height: 131px;
}

.section-frontEnd
    .form_tile
    .form_tile_content
    .form_tile_row
    .form_tile_input.date_input {
    background: url("../img/calender_gradient.png") no-repeat calc(100% - 10px)
            center,
        #fff;
}

.section-frontEnd .customChk label,
.moving .customChk label {
    font-weight: 400 !important;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: inherit;
    display: inline-flex;
    align-items: center;
}

.section-frontEnd .customChk,
.moving .customChk {
    display: inline-block;
    text-align: left;
}

.section-frontEnd .customChk input,
.moving .customChk input {
    display: none;
}

.section-frontEnd .customChk input + label:before,
.moving .customChk input + label:before {
    display: block;
    content: "";
    height: 14px;
    width: 14px;
    min-width: 14px;
    border: 2px solid #fff;
    background: #ccc;
    box-shadow: 0 0 0 2px #2f4575;
    margin-right: 7px;
    transition: 0.3s all;
}

.section-frontEnd .customChk input[type="checkbox"] + label:before,
.moving .customChk input[type="checkbox"] + label:before {
    border-radius: 2px;
}

.section-frontEnd .customChk input[type="radio"] + label:before,
.moving .customChk input[type="radio"] + label:before {
    border-radius: 100px;
}

.section-frontEnd .customChk input:checked + label:before,
.moving .customChk input:checked + label:before {
    border: 2px solid #fff;
    box-shadow: 0 0 0 2px #2f4575;
    background: #2f4575;
}

.section-frontEnd .form_tile_content .table_tile {
    width: 100%;
    table-layout: fixed;
}

.section-frontEnd .form_tile_content .table_tile tr th {
    color: #0d8ee1;
}

.section-frontEnd .form_tile_content .table_tile tr td,
.section-frontEnd .form_tile_content .table_tile tr th {
    padding: 5px 10px;
    vertical-align: middle;
    font-family: "Poppins", sans-serif;
}

.section-frontEnd .form_tile_content .table_tile tr.border-bottom td,
.section-frontEnd .form_tile_content .table_tile tr.border-bottom th {
    border-bottom: 1px solid #999;
}

.section-frontEnd .form_tile_content .form_tile_title {
    font-family: "Poppins", sans-serif;
    color: #2f4575;
    font-size: 20px;
}

.section-frontEnd .form_tile_content .customFile {
    font-family: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5pt;
    display: flex;
    font-family: "Poppins", sans-serif;
    align-items: center;
    justify-content: center;
    border: 1px solid #bebebe;
    background: #fff;
    padding: 10px 5px;
    font-weight: 600;
    outline: none;
    width: 100%;
}

.section-frontEnd .form_tile_content .customFile > img {
    margin-right: 10px;
}

.section-frontEnd .form_tile_content .customFile > span {
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
}

.section-frontEnd .form_tile_content .flyer_links a {
    display: inline-block;
    margin: 10px;
}

.section-frontEnd .flexElem .flexThreeCols > div {
    width: calc(33.3333333% - 10px);
}

.section-frontEnd .noMarginTable .ReactTable {
    margin: 0 !important;
}

.section-frontEnd .noBorderTable .ReactTable .rt-thead .rt-th,
.section-frontEnd .noBorderTable .ReactTable .rt-thead .rt-td {
    border: none;
}
/* REMOVING SORT IN TABLE HIGHLIGHTS */
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: none;
}
.section-frontEnd .filterBox .inlineFlexElem > div:first-child {
    margin-right: 10px;
}

.section-frontEnd .noBreakLabel label {
    white-space: nowrap;
}

.section-frontEnd .trStatusBlue {
    background: #dde3f1;
}

.section-frontEnd .trStatusLightBlue {
    background: #d4f3ed;
}

.section-frontEnd .trStatusRed {
    background: #f1dddd;
}

.section-frontEnd .trStatusOrange {
    background: #f9e8d5;
}

.section-frontEnd .frontEndTable .ReactTable {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}

.section-frontEnd #uploaderTabs .tabsContainer {
    border: 1p solid #d3d6dc;
}

.section-frontEnd #uploaderTabs .tabsContainer .tabsHeader {
    border: 1px solid #d3d6dc;
    padding-right: 15px;
}

.section-frontEnd #uploaderTabs .tabsContainer .tabsContent {
    border: 1px solid #d3d6dc;
    border-top: none;
    padding: 20px;
}

.section-frontEnd #uploaderTabs .tabsContainer .tabsHeader .tabsCollapser {
    display: inline-flex;
    height: 20px;
    width: 20px;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding-top: 1px;
    transition: 0.3s all;
}

.section-frontEnd
    #uploaderTabs
    .tabsContainer
    .tabsHeader
    .tabsCollapser.closed {
    transform: rotate(180deg);
}

.section-frontEnd #uploaderTabs .tabsContainer .tabsHeader .tabBtn {
    zoom: 0.9;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    padding-left: 5vw;
    padding-right: 5vw;
    color: #fff;
    transition: 0.3s all;
    border: none;
    outline: none;
}

.section-frontEnd #uploaderTabs .tabsContainer .tabsHeader .tabBtn {
    width: 100%;
}

.section-frontEnd
    #uploaderTabs.sampleVideos
    .tabsContainer
    .tabsHeader
    .tabBtn {
    padding-left: 3vw;
    padding-right: 3vw;
    overflow: hidden;
}

.sampleVideosList {
    font-family: "Poppins", sans-serif;
    padding-left: 7px !important;
    min-width: 180px;
}

.sampleVideosList li {
    font-size: 1.1rem;
    color: #888;
    margin-top: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.sampleVideosList li span {
    display: inline-flex;
    margin-left: 10px;
}

.sampleVideosList li svg {
    transition: 0.2s all;
}

.sampleVideosList li svg.fa-spin {
    font-size: 1.5rem;
}

.sampleVideosList li svg.fa-spin + span {
    color: #3b9ae3;
    transition: 0.3s all;
    font-weight: 500;
}

.section-frontEnd
    #uploaderTabs.sampleVideos
    .tabsContainer
    .tabsHeader
    .tabBtn:hover
    > svg {
    animation: zoomOut 0.2s ease;
}

.section-frontEnd .tipIcon {
    display: inline-flex;
    background: tomato;
    transition: 0.3s all;
    color: #fff;
    height: 18px;
    width: 18px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-weight: 500;
    cursor: default;
    font-size: 14px;
    margin-left: 5px;
}

.section-frontEnd .tipIcon:hover {
    background: red;
}

.section-frontEnd #uploaderTabs .tabsContainer .tabsHeader .tabBtn.activeTab {
    zoom: 1;
}

.section-frontEnd .accountUsersContainer {
    flex-wrap: wrap;
}

.section-frontEnd .accountUsersContainer .col-md-6 {
    flex-basis: 50%;
    margin-bottom: 10px;
}

.section-frontEnd .favoriteOptions .favoriteOptionsGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    text-align: center;
}

.section-frontEnd .favoriteOptions .favoriteOptionsGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
}

.section-frontEnd .favoriteOptions .favoriteOptionsGrid .customChk {
    margin-top: 20px;
}

.section-frontEnd .grpTravelApp {
    background: #eaeef6;
}

.section-frontEnd
    .approvalOptions
    .approvalChk
    .customChk
    input[type="checkbox"]
    + label {
    letter-spacing: 0.5pt;
    align-items: flex-start;
}

.section-frontEnd
    .approvalOptions
    .approvalChk
    .customChk
    input[type="checkbox"]
    + label:before {
    height: 17px;
    width: 17px;
    min-width: 17px;
    margin-top: 2px;
}

.section-frontEnd [class$="-placeholder"] {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.section-frontEnd .form_tile .flexThreeCols [class$="-menu"] {
    width: auto !important;
    max-width: auto !important;
    white-space: nowrap !important;
}
.orderHistoryActions > span {
    display: block;
    cursor: pointer;
}

.orderHistoryActions > *:not(:first-child) {
    margin-left: 7px;
}

.orderHistoryActions > span,
.orderHistoryActions > button {
    transition: 0.3s all;
}

.orderHistoryActions > span:hover,
.orderHistoryActions > button:hover {
    animation: heartbeat 0.3s ease;
}

.lightBoxDownload {
    color: #898989;
    vertical-align: middle;
    font-size: 20px;
}
.lightBoxDownload:hover {
    color: #fff;
    cursor: pointer;
}
.section-frontEnd .companyImagesGrid .pagination > li {
    display: inline-block;
}

.liveChatBanner {
    background: url("../img/contactUs.jpg") no-repeat left center;
    background-size: cover;
    min-height: 150px;
    color: #5a16bd;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    padding: 30px;
    display: flex;
    align-items: center;
}

.liveChatBanner p {
    font-family: inherit;
}

#contactUs .supportBox {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 30px 18px;
}

#contactUs .contactFormContainer .flexElem > div {
    width: 100%;
}

#contactUs .contactFormContainer .flexElem > div textarea {
    min-height: 250px;
    max-height: 250px;
    max-width: 100%;
    min-width: 100%;
}

.contactFormContainer .form-control {
    min-height: 50px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    border: none;
}

.speak-bubble {
    border-radius: 100%;
    height: 40px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: /*0 0 0 4px #ccc,*/ 0 0 7px rgba(0, 0, 0, 0.3);
    color: #bbb;
    top: 0;
    position: absolute;
}

.testimonialsTimeline:nth-child(odd) .col-md-6 .speak-bubble {
    left: -20px;
}

.testimonialsTimeline .col-md-6 .speak-bubble:after {
    content: "";
    display: block;
    height: 1px;
    width: 200px;
    top: calc(50% - 1px);
    position: absolute;
    background: #ddd;
    z-index: -1;
}

.testimonialsTimeline:nth-child(odd) .col-md-6 .speak-bubble:after {
    left: 0;
}

.testimonialsTimeline:nth-child(even) .col-md-6 .speak-bubble:after {
    right: 0;
}

.testimonialsTimeline:nth-child(even) .col-md-6 .speak-bubble {
    right: -20px;
}

.testimonialsTimeline:nth-child(even) .col-md-6 .speak-bubble span {
    transform: scale(-1, -1);
    margin-bottom: 20px;
    margin-left: 2px;
}

.speak-bubble span {
    font-size: 50px;
    margin-bottom: -18px;
}

.testiAuthor {
    margin-top: 20px;
    color: #5a16bd;
}

.testiAuthor {
    padding: 10px;
    border-top: 1px solid #eee;
    display: inline-block;
}

.testiAuthor .name {
    font-weight: 600;
}

.testiAuthor .company,
.testiAuthor .date {
    font-style: italic;
    color: #888;
    font-size: smaller;
}

#memberAffliate .member {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 20px;
    margin-top: 30px;
}

#memberAffliate .member .memberLogo {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
}

#memberAffliate .member .memberLogo img {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
}

#faq.panel-group .panel {
    border-radius: 0;
    margin-top: 10px;
}

#faq.panel-group .panel-heading {
    padding: 0;
}

#faq.panel-group .panel-title.text_16 a {
    font-family: inherit;
    position: relative;
    display: block;
    padding: 15px 20px;
    padding-left: 44px;
    transition: 0.2s all;
    color: #fff;
    background: #3b9ae3; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #3b9ae3 0%,
        #2cd0d2 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #3b9ae3 0%,
        #2cd0d2 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #3b9ae3 0%,
        #2cd0d2 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b9ae3', endColorstr='#2cd0d2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

#faq.panel-group .text_16 a:hover {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

#faq.panel-group .panel-title.text_16 a:before {
    content: "";
    display: block;
    height: 2px;
    background: #fff;
    width: 22px;
    position: absolute;
    left: 12px;
    top: calc(50% - 1px);
}

#faq.panel-group .panel-title.text_16 a:after {
    content: "";
    display: block;
    height: 2px;
    background: #fff;
    width: 22px;
    position: absolute;
    left: 12px;
    top: calc(50% - 1px);
    transition: 0.3s all;
    transform: rotate(0deg);
}

#faq.panel-group .panel .text_16 a.collapsed {
    background: #f5f5f5; /* Old browsers */
    background: -moz-linear-gradient(
        left,
        #eee 0%,
        #f5f5f5 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        #eee 0%,
        #f5f5f5 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        #eee 0%,
        #f5f5f5 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eee', endColorstr='#f5f5f5',GradientType=1 ); /* IE6-9 */
    color: #333;
}

#faq.panel-group .panel-title.text_16 a.collapsed:after,
#faq.panel-group .panel-title.text_16 a.collapsed:before {
    background: #333;
}
#faq.panel-group .panel-title.text_16 a.collapsed:after {
    transform: rotate(-90deg);
}

.section-frontEnd .srv-validation-message {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    padding: 10px;
}

.section-frontEnd .profilePic {
    display: inline-block;
    height: 200px;
    width: 200px;
    background-size: cover !important;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.section-frontEnd .profilePic.dave {
    background: url("../img/profile_pic_dave.jpg") no-repeat center;
}

.section-frontEnd .profilePic.john {
    background: url("../img/profile_pic_john.jpg") no-repeat center;
}

.section-frontEnd .profilePic.alex {
    background: url("../img/profile_pic_alex.jpg") no-repeat center;
}

.section-frontEnd .profilePic.sherri {
    background: url("../img/profile_pic_sherri.jpg") no-repeat center;
}

.section-frontEnd .profilePic.phil {
    background: url("../img/profile_pic_phil.jpg") no-repeat center;
}

.section-frontEnd .profilePic.katie {
    background: url("../img/profile_pic_katie.jpg") no-repeat center;
}

.section-frontEnd .profilePic.clint {
    background: url("../img/profile_pic_clint.jpg") no-repeat center;
}

.section-frontEnd .teamMember {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    min-height: 200px;
    margin-top: 150px;
    position: relative;
}

.section-frontEnd .teamMember:hover {
    box-shadow: 0 0 10px rgba(120, 94, 168, 0.5);
}

.section-frontEnd .teamMember .teamMemberName {
    text-align: center;
    position: relative;
    top: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.section-frontEnd .teamMember .teamMemberContent {
    margin-top: -100px;
    padding: 30px;
}

.ril__toolbarRightSide {
    display: flex;
    align-items: center;
}
/*Frontend CSS End*/
/* STYLE  ADDED  BY  KALEEM */

.mb-0 {
    margin-bottom: 0 !important;
}
.mb-50 {
    margin-bottom: 0.5rem !important;
}
.mb-1 {
    margin-bottom: 1rem !important;
}
.mb-2 {
    margin-bottom: 2rem;
}
.mb-4r {
    margin-bottom: 4rem;
}
.mt-1 {
    margin-top: 1rem !important;
}
.my-1 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}
.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.me-50 {
    margin-right: 0.5rem !important;
}
.me-1 {
    margin-right: 1rem !important;
}
.ms-2 {
    margin-left: 1.5rem !important;
}
.ms-1 {
    margin-left: 1rem;
}
.ms-auto {
    margin-left: auto;
}
.px-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-2 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.px-4 {
    padding-left: 4rem;
    padding-right: 4rem;
}
.pl-2-5F {
    padding-left: 2.5rem !important;
}
.px-0 {
    padding-left: 0;
    padding-right: 0;
}
.px-0F {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.py-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.py-4r {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.pb-05 {
    padding-bottom: 0.5rem;
}
.pb-2 {
    padding-bottom: 2rem;
}
.pt-2 {
    padding-top: 2rem;
}
.py-50 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.p-1 {
    padding: 1rem;
}
.relative {
    position: relative;
}
/* .btn-primary {
    color: #fff !important;
} */
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    color: #3472f7 !important;
}
.borderT-0 {
    border-top: 0 !important;
}
.borderB {
    border-bottom: 1px solid #d8d8d8;
}
.loginSelection {
    background-color: #ffc90e;
    color: #12064b;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 5px;
    font-weight: 600;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.loginSelection .loginMenu {
    list-style: none;
    position: absolute;
    top: calc(100% + 12px);
    right: 0;
    margin: 0;
    min-width: 180px;
    background-color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: all 0.4s ease-in-out;
    border-radius: 0.25rem;
    padding: 0;
}
.loginSelection:hover,
.loginSelection:focus,
.loginSelection:active {
    color: #12064b;
}
.loginSelection:hover .loginMenu,
.loginSelection:focus .loginMenu,
.loginSelection:active .loginMenu,
.loginSelection:focus-visible .loginMenu {
    top: calc(100% + 4px);
    opacity: 1;
}
.loginSelection .loginMenu li {
    width: 100%;
}
.loginSelection .loginMenu li a {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0.75rem 1rem;
    color: #12064b;
}
.loginSelection .loginMenu li a:hover,
.loginSelection .loginMenu li a:focus,
.loginSelection .loginMenu li a:active {
    background-color: #ffc90e;
}
.clm-search {
    width: 100%;
    max-width: 1000px;
    margin: 1.4rem auto;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 1px solid #d0d0d0;
    border-radius: 2rem;
    box-shadow: 0 0 0.35rem rgba(0, 0, 0, 0.15);
}
.clm-search input {
    border: none;
    transition: all 0.25s ease-in-out;
}
.clm-search input:focus,
.clm-search input:focus-visible {
    box-shadow: none;
    border: none;
    outline: none;
    text-indent: 5px;
    letter-spacing: 0.4px;
}
.clm-search .searchBtn {
    white-space: nowrap;
    background-color: #806bff;
    font-size: 14px;
    color: white;
    padding: 0.7rem 1.5rem;
    border-radius: 2.5rem;
    transition: all 0.4s ease-in-out;
}
.clm-search .searchBtn:hover,
.clm-search .searchBtn:focus,
.clm-search .searchBtn:active {
    background-color: #6350ce;
}
.cartItem {
    position: relative;
}
.cartItem button.btn-secondary:hover,
.cartItem button.btn-secondary:focus,
.cartItem button.btn-secondary:active {
    background-color: #549e4c;
}
.cartItem button {
    height: 100%;
    background-color: #76c16e;
}
.cartItem button > img {
    filter: invert(1);
}
.cartItem .cartChecked {
    position: absolute;
    top: 0;
    right: 0;
}
.cartFilter img {
    filter: invert(0.4);
}
.cartFilter:hover img,
.cartFilter:focus img,
.cartFilter:active img {
    filter: none;
}
.altBG {
    background: #f0f3ff;
    box-shadow: none;
}
.flexElemInline {
    display: inline-flex;
}
.flexElem {
    display: flex !important;
}
.flexColumn {
    flex-direction: column;
}
.flexRowF {
    flex-direction: row !important;
}
.flexNoWrap {
    flex-wrap: nowrap;
}
.flexWrap {
    flex-wrap: wrap;
}
.alignStart {
    align-items: flex-start;
}
.alignCenter {
    align-items: center;
}
.alignEnd {
    align-items: flex-end;
}
.justifyCenter {
    justify-content: center;
}
.justifyStart {
    justify-content: flex-start !important;
}
.justifyEnd {
    justify-content: flex-end !important;
}
.spaceBetween {
    justify-content: space-between;
}
.gap10p {
    gap: 10px;
}
.gap25 {
    gap: 25px;
}
.gridCard {
    border: none;
    box-shadow: 0px 1px 0.3rem rgba(0, 0, 0, 0.2);
    border-radius: 0.4rem;
}
.selectCaret {
    padding: 1rem 1.5rem;
    border: 1px solid #f2f5f6;
    cursor: pointer;
    box-shadow: 0 0 0.7rem rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-out;
}
.selectedCaret,
.selectCaret:hover,
.selectCaret:focus {
    background-color: #2c3356;
    color: white;
}
.watermark {
    width: 120px;
    height: 100px;
    padding: 0.4rem;
    border: 1px solid #c8c8c8;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.watermark.currentWatermark {
    border-color: #806bff;
    background-color: #f1f1f1;
}
.videosTable .ReactTable .rt-thead .rt-th,
.videosTable .ReactTable .rt-thead .rt-td {
    padding-top: 12px;
    padding-bottom: 12px;
}
.cardTitle {
    font-weight: 600;
    font-size: 18px;
}
.selectBtn {
    color: #806bff;
    border: 1px solid #806bff;
    border-radius: 4px;
    transition: all 0.3s ease-out;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #fff;
}
.bcCelectBtn {
    color: #fff;
    border: 1px solid #806bff;
    border-radius: 4px;
    transition: all 0.3s ease-out;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #806bff;
}

.splashGrid {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(110px, 1fr)); */
    grid-template-columns: repeat(8, 1fr);
    gap: 0.6rem;
}
.selectBtn.defaultSelected,
.selectBtn:hover,
.selectBtn:focus {
    color: #fff;
    background-color: #806bff;
    border: 1px solid #806bff;
    border-radius: 4px;
}
.videoGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 800px;
    margin: 0 auto;
    align-items: center;
}
.videoGrid .appuser,
.videoGrid .appuser-reverse {
    font-weight: 600;
    color: #0382f2;
    font-size: 14px;
    display: flex;
    gap: 5px;
}
.videoGrid .appuser {
    text-align: right;
}
.videoGrid .appuser-reverse {
    text-align: left;
}
.videoGrid .appuser-videoBanner {
    object-fit: cover;
    object-position: center;
    width: 200px;
    height: 300px;
}

.wrapperOverride {
    position: absolute;
    width: 100%;
    left: 0;
}
.loginSection {
    /* background: url(../img/landing/hero-bg.png) no-repeat center / cover !important; */
    background: url(../img/clm-login-banner.png) no-repeat center / cover !important;
    padding: 2.5rem 0 !important;
}

.deleteSection {
    background: url(../img/landing/delBG.png) no-repeat center / cover !important;
    padding: 2.5rem 0 0 !important;
}
.invoiceData {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.invoiceStatus,
.invoiceStatusActive {
    display: inline-flex;
    padding: 2px 12px;
    border-radius: 10rem;
    font-size: 85%;
}
.invoiceStatus {
    background: #ffc90e;
    color: white;
}
.invoiceStatusActive {
    background: #31db86;
    color: white;
}
.mainResult {
    padding: 1rem 2rem;
    border: 1px solid #f1eeee;
    border-radius: 0.6rem;
}
.mainResult .price {
    font-size: 2.4rem;
    font-weight: 600;
    margin-top: 0;
    color: #ca34ff;
}
.suggestionsList {
    border-top: 1px solid #f1eeee;
    padding: 0.75rem 0;
}
.suggestionsList .cartFilter {
    width: 0;
    overflow: hidden;
    /* transition: cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.3s; */
}
.suggestionsList:hover .cartFilter {
    width: auto;
    transition: cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.3s;
    transition: all 0.3s ease-in-out;
}
footer.caretFooter {
    padding: 2.5rem 0 0;
    margin-top: 2rem;
    border-top: 1px solid #d8d8d8;
}
footer.caretFooter h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #050038;
}
footer.caretFooter ul li {
    color: #444646;
    font-size: 14px;
    line-height: 1.5;
    display: block;
    margin-bottom: 0.8rem;
    letter-spacing: 0.4px;
}
footer.caretFooter .socialIcons {
    display: grid;
    grid-template-columns: repeat(4, minmax(30px, 40px));
}
footer.caretFooter .socialIcons img {
    height: 24px;
}
.searchBadge {
    display: inline-flex;
    background: #ffc90e;
    border-radius: 0.25rem;
    color: white;
}
/* STYLE  ADDED  BY  KALEEM */
.activeAdCheck {
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
}

input.cssSwitch[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
}

label.cssSwitch {
    cursor: pointer;
    text-indent: -9999px;
    width: 60px;
    height: 30px;
    background: #666; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #666 0%, #999 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #666 0%,
        #999 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #666 0%,
        #999 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    display: block;
    border-radius: 100px;
    position: relative;
}

label.cssSwitch:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

input.cssSwitch:checked + label {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b9d54a+0,c4d134+100 */
    background: #b9d54a; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b9d54a', endColorstr='#c4d134',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

input.cssSwitch:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

label:active:after {
    width: 30px;
}
.appBtn {
    padding: 0.5rem 1.25rem !important;
    background-color: #12064b !important;
}
.uploadBtn {
    /* width: 100%; */
    height: 70px; /* Set a specific height for the drop area */
    border: 2px dashed rgba(0, 0, 0, 0.5); /* Light border to define the area */
    border-radius: 8px; /* Rounded corners for a softer look */
    display: flex;
    align-items: center; /* Center align items vertically */
    justify-content: center; /* Center align items horizontally */
    transition: all 0.4s ease-out; /* Smooth transition for hover and dragging effects */
    position: relative; /* Needed for absolutely positioned input */
    margin-bottom: 10px; /* Space below the drop area */
}
.uploadBtn.dragging {
    /* border: 5px dashed #007bff;
    background-color: #e6f7ff;
    padding: 15px;
    transition: all 0.4s ease-out; */

    border: 5px dashed #007bff; /* Make the border more visible when dragging */
    background-color: rgba(
        0,
        123,
        255,
        0.1
    ); /* Slightly transparent background on drag */
    box-shadow: 0 0 15px rgba(0, 123, 255, 0.5);
}
.uploadBtn input[type="file"] {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background-color: white;
    cursor: pointer;
    margin-bottom: 10px;
    position: absolute;
    top: -9999px;
    left: -9999px;
}
.uploadBtn label {
    background: #2f4576;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 20px;
    cursor: pointer;
    border-radius: 2px;
    transition: all ease-out 200ms;
}
.uploadBtn label img {
    margin-right: 15px;
}
.uploadBtn label.chosen {
    background-color: #4dc19c;
}
.uploadBtn label:not(.chosen):hover {
    background-color: #4dc19c;
}
.inputFile {
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
    position: relative;
    z-index: 0;
}

.dragging {
    border: 2px dashed #007bff; /* Make the border more visible when dragging */
    background-color: rgba(
        0,
        123,
        255,
        0.1
    ); /* Slightly transparent background on drag */
    box-shadow: 0 0 15px rgba(0, 123, 255, 0.5);
    height: 45px;
}
.inputFile .file-input {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    padding: 9px 0rem 8px 37px;
    z-index: 0;
}
.inputFile .file-input-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #2f4576;
    font-size: 13px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 0;
    z-index: 10;
    color: white !important;
}

.inputFile .file-input-label:hover {
    background-color: #0056b3;
}
.inputFile .file-input-label:active {
    background-color: #00408d;
}
.adOrderPosition {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0;
    margin-top: 2rem;
    border: 1px solid #2e9bfc;
}
.adOrderPosition .adWrap {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.adOrderPosition .adWrap.yourAd {
    border-left: 1px solid #2e9bfc;
    border-right: 1px solid #2e9bfc;
}
.adOrderPosition .adWrap.yourAd:last-child {
    border-right: 0;
}
.adOrderPosition .adPreview {
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    flex-direction: column;
    gap: 1.2rem;
    margin-top: auto;
    /* padding-top: 1rem; */
    padding-left: 10px;
    padding-right: 10px;
    /* border-top: 1px solid #2e9bfc; */
    position: relative;
    /* width: calc(100% + 8px); */
}
.adOrderPosition .adWrap.yourAd .adPreview {
    padding-top: 40px;
}
/* .adOrderPosition .adPreview.yourAd::before,
.adOrderPosition .adPreview.yourAd::after {
    content: "";
    position: absolute;
    left: 4px;
    width: 1px;
    height: 48px;
    background: #2e9bfc;
    top: -4px;
}
.adOrderPosition .adPreview.yourAd::after {
    right: 4px;
    left: auto;
} */
.adOrderPosition .adWrap .adPreview img {
    height: 250px;
    margin-top: auto;
}
.adOrderPosition .adWrap.yourAd .adPreview img {
    height: 290px;
}
.adOrderPosition .adWrap h5 {
    font-weight: 700 !important;
    padding-bottom: 1rem;
    font-size: 1.4rem;
    border-bottom: 1px solid #2e9bfc;
}
.adOrderPosition .adWrap h6 {
    font-weight: 600 !important;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    font-size: 1.2rem;
    text-transform: none;
}
.adOrderPosition .adWrap.yourAd h5 {
    color: #806bff;
    position: relative;
}
.adOrderPosition .adWrap.yourAd img.addPointer {
    height: auto !important;
    width: 40px;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
}
.paymentReceipt {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
    width: 100%;
    max-width: 740px;
    z-index: 1000;
}
.modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.modalHeader .close {
    position: absolute;
    top: -1rem;
    right: -1rem;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.3);
    opacity: 1;
}
.modalHeader .close:hover {
    background-color: #e42a61;
    color: white;
}
.modalHeader,
.modalContent {
    padding: 25px;
}
.modalHeader h5 {
    font-weight: 700;
    margin: 0;
}
.paymentReceipt .titalArea {
    padding: 1rem 1.5rem;
    background-color: #e0e7f6;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.paymentReceipt .titalArea span {
    padding: 0 0.5rem;
}
.modalContent .payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    border-top: 1px solid #ababab;
    flex-wrap: wrap;
    gap: 15px;
}
.modalContent .payment:first-of-type {
    border-top: 0;
}
.modalContent .payment h5 {
    margin: 0;
    font-weight: 600;
}
.campaignTitleWrap {
    background: #ecf6ff;
    padding: 1rem 0.95rem;
    display: flex;
    font-size: 1.2rem;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}
.campaignTitleWrap strong {
    color: #2f4576;
}
.campaignTitleWrap .campaignTarget {
    margin-left: 0;
    display: flex;
    gap: 1.3rem;
}
.paymentCards {
    padding: 1rem;
    border: 1px solid #d1d1d1;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
}
.paymentCards > .row {
    display: flex;
    align-items: center;
}
.savePayment label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 1.3rem;
    margin-top: 0.5rem;
}

.btnDanger {
    background-color: #e42a61 !important;
    color: white !important;
    border: 1px solid #e42a61 !important;
}
.btnDanger:focus,
.btnDanger:hover,
.btnDanger:active {
    background-color: white !important;
    color: #e42a61 !important;
    border: 1px solid #e42a61 !important;
}
.btnDanger {
    background-color: #e42a61 !important;
    color: white !important;
    border: 1px solid #e42a61 !important;
}
.btnDanger:focus,
.btnDanger:hover,
.btnDanger:active {
    background-color: white !important;
    color: #e42a61 !important;
    border: 1px solid #e42a61 !important;
}
.btnInfoCustom {
    background-color: #0ed4ff !important;
    color: white !important;
    border: 1px solid #0ed4ff !important;
}
.btnInfoCustom:focus,
.btnInfoCustom:hover,
.btnInfoCustom:active {
    background-color: white !important;
    color: #0ed4ff !important;
    border: 1px solid #0ed4ff !important;
}
.innerCustomContainer {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 991px) {
    .fullPrint {
        margin-bottom: 50px;
    }
    .testimonialsTimeline {
        position: relative;
    }

    .testimonialsTimeline .col-md-6 .text_16 {
        box-shadow: /*0 0 0 1px #ccc,*/ 0 0 10px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        padding: 20px;
        background: #fff;
    }

    .testimonialsTimeline:before {
        content: "";
        width: 1px;
        background: #ddd;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block;
        position: absolute;
    }

    .testimonialsTimeline:last-child:before {
        display: none;
    }

    .testimonialsTimeline:nth-child(odd) {
        margin-top: -17%;
    }

    .testimonialsTimeline:nth-child(odd) .col-md-6 {
        padding-left: 100px;
        float: right;
    }

    .testimonialsTimeline:nth-child(even) .col-md-6 {
        padding-right: 100px;
        text-align: right;
    }

    .testimonialsTimeline .col-md-6 {
        position: relative;
    }

    .contactFormContainer .flexElem {
        margin-bottom: 20px;
    }

    #contactUs .flexElem > div:first-child {
        margin-right: 10px;
    }
    #contactUs .flexElem > div:last-child {
        margin-left: 10px;
    }

    #contactUs .flexElem > div:only-child {
        margin-left: 0;
        margin-right: 0;
    }

    #video .vidContainer {
        margin-left: 20px;
    }
    .section-frontEnd .trevlerLoginDetails > div {
        padding-left: 3vw;
    }
    .section-frontEnd #students .student_text {
        padding-right: 610px;
    }
    .section-frontEnd #family .container {
        padding-left: 610px;
    }
    .section-frontEnd #students .section_icon {
        margin-top: 40px;
    }
    .section-frontEnd #benefits .slider_container {
        height: 450px;
    }
    .section-frontEnd #uses_of_gtv .panel_heading {
        margin-top: 50px;
    }
    .section-frontEnd #benefits {
        padding-top: 130px;
    }
    .section-frontEnd #benefits .slider_container .container .side_slider {
        position: relative;
        right: 0;
        bottom: 80px;
        margin-bottom: 0;
    }

    .section-frontEnd .downloadBtns > a + a {
        margin-left: 10px;
    }

    .section-frontEnd .feature_box p {
        padding-right: 30px;
    }

    .section-frontEnd #photo_gallery .uploader_box .uploader_box_col.small {
        margin-left: 50px;
        width: 90%;
    }

    .section-frontEnd .flexElem .footerCol:first-child {
        padding-right: 100px;
        margin-right: 100px;
        border-right: 1px solid #e8e8e8;
    }

    .section-frontEnd
        #photo_gallery
        .uploader_box
        .addName
        .addNameForm
        > div
        + div {
        margin-left: 10px;
    }
    .section-frontEnd
        #photo_gallery
        .uploader_box
        .addName
        .addNameForm
        > div:last-child {
        width: 60%;
    }

    .section-frontEnd #photo_gallery .uploadActions > div + div {
        margin-left: 10px;
    }

    .section-frontEnd #companyImagesInfo .container {
        padding: 0;
    }

    .section-frontEnd .filterBox > div:nth-child(1) {
        flex-basis: 28%;
    }

    .section-frontEnd .filterBox > div:nth-child(2) {
        flex-basis: 34%;
    }

    .section-frontEnd .filterBox > div:nth-child(3) {
        flex-basis: 28%;
    }

    .section-frontEnd .filterBox > div:nth-child(4) {
        flex-basis: 10%;
    }

    .section-frontEnd .filterBox .inlineFlexElem + .inlineFlexElem {
        margin-left: 10px;
    }

    .section-frontEnd
        #uploaderTabs
        .tabsContainer
        .tabsHeader
        > div
        > div
        + div {
        margin-left: 5px;
    }
    .section-frontEnd .padding_left_3 {
        padding-left: 3px;
    }

    .section-frontEnd .padding_right_3 {
        padding-right: 3px;
    }
    .campaignTitleWrap .campaignTarget {
        margin-left: auto;
    }
}

.section-frontEnd .text_left_center_767,
.section-frontEnd .text_left_center_991 {
    text-align: left !important;
}

.section-frontEnd .justify_left_center_767,
.section-frontEnd .justify_left_center_991 {
    justify-content: flex-start !important;
}

.section-frontEnd .text_right_center_767,
.section-frontEnd .text_right_center_991 {
    text-align: right !important;
}

.section-frontEnd .justify_right_center_767,
.section-frontEnd .justify_right_center_991 {
    justify-content: flex-end !important;
}

@media screen and (max-width: 991px) {
    .section-frontEnd #photo_gallery .uploadDetails > table tr + tr {
        border-top: 1px solid #ccc;
    }
    .speak-bubble:after {
        display: none !important;
    }
    .speak-bubble {
        top: -20px;
        transform: scale(0.7);
    }

    .testimonialsTimeline .col-md-6 {
        padding: 20px;
        border-radius: 4px;
    }

    .testimonialsTimeline:nth-child(even) .col-md-6 .speak-bubble {
        right: 0;
    }

    .testimonialsTimeline:nth-child(odd) .col-md-6 .speak-bubble {
        left: 0;
    }

    .testimonialsTimeline:nth-child(even) .col-md-6 {
        background: #f5f5f5;
    }
    .testimonialsTimeline + .testimonialsTimeline {
        margin-top: 30px;
    }
    #contactUs .contactFormContainer .flexElem {
        margin-top: 20px;
    }
    .section-frontEnd .navbar-collapse.collapse {
        height: auto !important;
    }

    .section-frontEnd #benefits .slider_container .container .side_slider {
        max-width: 82vw;
    }
    .section-frontEnd .favoriteOptions .favoriteOptionsGrid {
        grid-template-columns: repeat(3, 1fr);
    }
    .section-frontEnd .features_grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .section-frontEnd .flexElem.respFlex {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    .section-frontEnd .form_tile_row.flexElem.respFlex {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
        text-align: left;
    }

    .section-frontEnd .form_tile_row.flexElem.respFlex > div + div,
    .section-frontEnd .flexElem.respFlex > div + div {
        margin-top: 20px;
    }

    .section-frontEnd
        .orderInformationBox
        .form_tile_row.flexElem.respFlex
        > div
        + div {
        margin-top: 40px;
    }

    .section-frontEnd .flexElem.respFlex.spacing > div + div {
        margin-top: 20px;
    }

    .section-frontEnd .banner_text_big:after {
        margin: 0 auto;
    }

    .section-frontEnd .flexElem.slider_main.respFlex {
        justify-content: flex-start;
        text-align: center;
    }

    .section-frontEnd .flexElem.respFlex > div {
        width: 100%;
    }

    .section-frontEnd #footer .privacy {
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    .section-frontEnd #benefits .slider_container .container .smaller_heading {
        margin-top: 30px;
    }
    .section-frontEnd
        #benefits
        .slider_container
        .container
        .smaller_heading:after {
        margin-left: auto;
        margin-right: auto;
    }
    .section-frontEnd
        #main_slider
        .download_btns
        .download_btn:not(:first-child) {
        margin: 20px 0 0 0;
        display: inline-block;
    }

    .section-frontEnd #benefits .flexCol:last-child {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
    .section-frontEnd .features_container > div .feature_box {
        padding-top: 38px;
        margin-top: 60px;
        background-position: left top;
        min-height: 100px;
    }
    .section-frontEnd #features .features_container:before {
        content: "";
        left: 64px;
        right: auto;
        top: 70px;
        bottom: 12%;
        height: auto;
        width: 1px;
    }

    .section-frontEnd #features .features_container .feature_box p {
        padding-left: 125px;
    }

    .section-frontEnd #students {
        background-size: contain;
        background-position: right calc(100% - 30px);
    }

    .section-frontEnd #students .student_text {
        padding-bottom: 70vw;
    }

    .section-frontEnd #family {
        background-size: contain;
        background-position: left calc(100% - 30px);
    }

    .section-frontEnd #family .container {
        padding-bottom: 70vw;
    }

    .section-frontEnd #photo_gallery .uploader_box .uploader_box_col.small {
        margin-top: 50px;
    }

    .section-frontEnd #companyImagesInfo .companyImagesGrid > div {
        grid-template-columns: repeat(3, 1fr);
    }

    /*slick slider*/
    .section-frontEnd .testi_slider .slick-dots li {
        width: 40px;
        height: 40px;
        margin: 10px;
        border-radius: 10px;
    }
    .section-frontEnd .testi_slider .slick-dots li.slick-active {
        width: 50px;
        height: 50px;
        margin: 10px;
    }

    .section-frontEnd .navbar .navbar-collapse.collapse.in {
        display: block !important;
        box-shadow: 0px 5px 11px -3px #ccc;
        animation: fade 0.5s ease alternate;
    }

    .section-frontEnd .navbar .nav li a,
    .section-frontEnd .no_slider .affix.navbar-default .navbar-nav > li > a {
        color: #5e5e5e;
    }

    .section-frontEnd .zoomerContainer {
        margin-bottom: 60px;
    }

    .section-frontEnd .navbar .nav li.pull-right {
        float: none !important;
    }

    .section-frontEnd #header .navbar-default {
        width: 100%;
    }

    .section-frontEnd .text_left_center_991,
    .section-frontEnd .text_right_center_991 {
        text-align: center !important;
    }

    .section-frontEnd .justify_left_center_991,
    .section-frontEnd .justify_right_center_991 {
        justify-content: center !important;
    }

    .section-frontEnd
        #footer
        > .container
        > .row
        > .col-md-3:not(:first-child) {
        margin-top: 40px;
    }

    .section-frontEnd #footer > .container > .row > .col-md-3 {
        text-align: center;
    }

    .section-frontEnd .center_block {
        margin-left: auto !important;
        margin-right: auto !important;
        float: none !important;
        margin-top: 50px !important;
    }

    .section-frontEnd .small_box,
    .large_box {
        min-height: 400px;
    }

    .section-frontEnd .navbar-collapse {
        background: #fff;
    }

    .section-frontEnd #header .navbar-brand {
        padding: 8px;
    }

    .section-frontEnd .navbar-nav {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .section-frontEnd .navbar-toggle {
        min-width: 40px;
        padding: 5px;
        color: #333;
        font-size: 19px;
        border: none;
    }

    .section-frontEnd .no_slider .navbar-toggle {
        color: #5e5e5e;
    }

    .section-frontEnd .no_slider .affix .navbar-toggle {
        color: #fff;
    }
    .adOrderPosition .adWrap {
        border-top: 1px solid #2e9bfc;
    }
    .innerCustomContainer {
        max-width: 100%;
    }
    .mx-mdAuto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .px-md-1 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .splashGrid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (min-width: 769px) {
    .section-frontEnd .statistics-col-mid {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
    }
    .mb-md-0 {
        margin-bottom: 0 !important;
    }
    .me-md-0 {
        margin-right: 0 !important;
    }
    .ms-md-0 {
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .section-frontEnd .features_grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .section-frontEnd #companyImagesInfo .companyImagesGrid > div {
        grid-template-columns: repeat(2, 1fr);
    }

    .section-frontEnd .text_left_center_767,
    .section-frontEnd .text_right_center_767 {
        text-align: center !important;
    }

    .section-frontEnd .justify_left_center_767,
    .section-frontEnd .justify_right_center_767 {
        text-align: center !important;
    }

    .section-frontEnd .hide_md {
        display: none !important;
    }

    .section-frontEnd .show_md {
        display: inline-block !important;
    }

    .section-frontEnd .section_title h2 {
        font-size: 36px;
    }

    .section-frontEnd .portfolio_title h3 {
        font-size: 26px;
    }

    .section-frontEnd .portfolio_txt p {
        font-size: 20px;
    }

    .section-frontEnd .portfolio_control i {
        font-size: 36px;
    }

    .section-frontEnd .hide_sm {
        display: none !important;
    }

    .section-frontEnd .show_sm {
        display: inline-block !important;
    }

    .section-frontEnd .statistics-col {
        margin-top: 50px;
    }
    .paymentReceipt {
        max-width: 90% !important;
        top: 30% !important;
    }
    .adOrderPosition {
        grid-template-columns: repeat(4, 1fr) !important;
    }
    .adOrderPosition .adPreview {
        border-top: 0 !important;
        margin-bottom: 30px !important;
    }
    .adOrderPosition .adWrap.yourAd:last-child {
        border-right: 1px solid #2e9bfc !important;
    }
    /* .adOrderPosition .adPreview.yourAd::before,
    .adOrderPosition .adPreview.yourAd::after {
        content: none !important;
    } */
    .videoGrid {
        grid-template-columns: repeat(2, 1fr);
        max-width: 100%;
    }
    .videoGrid .appuser-videoBanner {
        width: 180px;
        height: 270px;
    }
    video.max-w-md {
        max-width: 96%;
    }
}

@media screen and (max-width: 600px) {
    .section-frontEnd #companyImagesInfo .companyImagesGrid > div {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .section-frontEnd .favoriteOptions .favoriteOptionsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
    .section-frontEnd #main_slider .banner_text_big {
        font-size: 28px;
    }
    .adOrderPosition {
        grid-template-columns: repeat(2, 1fr) !important;
    }
    .splashGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 441px) {
    .section-frontEnd .favoriteOptions .favoriteOptionsGrid {
        grid-template-columns: repeat(1, 1fr);
    }

    .section-frontEnd .hide_xs {
        display: none !important;
    }

    .section-frontEnd .show_xs {
        display: inline-block !important;
    }

    .section-frontEnd #main_slider .btn_container .btn-lg {
        font-size: 14px;
        padding: 12px 10px;
        max-width: 100%;
    }

    .section-frontEnd .btn_container h4 {
        margin-bottom: 30px;
        font-size: 14px;
    }
}

@media (min-width: 768px) {
    .section-frontEnd .navbar-nav > li > a {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
@media (min-width: 1900px) {
    .splashGrid {
        grid-template-columns: repeat(12, 1fr);
    }
}
@keyframes hover_fb {
    from {
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1),
            0px 0px 0px 0px #475993;
    }

    to {
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1),
            0px 0px 0px 10px #fff;
    }
}

@keyframes hover_tw {
    from {
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1),
            0px 0px 0px 0px #76a9ea;
    }

    to {
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1),
            0px 0px 0px 10px #fff;
    }
}

@keyframes hover_dr {
    from {
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1),
            0px 0px 0px 0px #ca2c41;
    }

    to {
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1),
            0px 0px 0px 10px #fff;
    }
}

@keyframes hover_yt {
    from {
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1),
            0px 0px 0px 0px #f61c0d;
    }

    to {
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1),
            0px 0px 0px 10px #fff;
    }
}

@keyframes hover_vm {
    from {
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1),
            0px 0px 0px 0px #69b4e5;
    }

    to {
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1),
            0px 0px 0px 10px #fff;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.section-frontEnd .mt-20 {
    margin-top: 20px;
}

.section-frontEnd .mt-0 {
    margin-top: 0 !important;
}

.section-frontEnd .mb-0 {
    margin-bottom: 0 !important;
}

.section-frontEnd .mt-mb-sl {
    margin-top: 70px;
    margin-bottom: 30px;
}

.reactLoaderFrontEnd {
    margin-bottom: 20px;
}

.messageModal {
    margin-top: 20px;
}

/**
 * Moduler Permissions
 */
.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
}

.loader {
    color: #0000a0;
    font-size: 11px;
    text-indent: -99999em;
    margin: 55px auto;
    position: relative;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 1em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader:before,
.loader:after {
    position: absolute;
    content: "";
}

.loader:before {
    width: 5.2em;
    height: 10.2em;
    background: #0000a0;
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    -webkit-transform-origin: 5.2em 5.1em;
    transform-origin: 5.2em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
}

.loader:after {
    width: 5.2em;
    height: 10.2em;
    background: #0000a0;
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 5.1em;
    -webkit-transform-origin: 0px 5.1em;
    transform-origin: 0px 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
}

@-webkit-keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.Collapsible img {
    transition: transform 0.2s;
}
.Collapsible__trigger {
    padding: 15px 10px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.Collapsible__trigger .Collapsible__contentOuter {
    border: 2px solid #ccc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.Collapsible__trigger.is-closed img {
    transform: rotate(0deg);
}
.Collapsible__trigger.is-open img {
    transform: rotate(180deg);
}

.innerContent .row {
    margin: 0;
}

.innerContent [class^="col-md"] {
    padding: 0;
}

.permissionName {
    font-size: 14px !important;
}

/* .even {
  background-color: #fff;
}

.odd {
  background: #eee;
} */

.permissionCell {
    margin: 0px 10px 5px 10px;
    border: 1px solid #bbb;
    padding: 5px;
    border-radius: 10px;
    transition: all 0.3s;
}

.permissionCell:hover {
    background: rgba(255, 255, 255, 0.7);
}

.permissionSwitch {
    text-align: right;
}

.permissionSwitch > div {
    display: inline-block !important;
    width: auto !important;
    margin-top: 5px;
}

.heading {
    font-size: 32px !important;
    font-weight: 300 !important;
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px;
}
#myCarousel .carousel-indicators {
    text-align: left;
    width: 100%;
}
#myCarousel .carousel-indicators .active {
    height: auto;
    width: 15%;
}
#myCarousel .indicator.active {
    border: 3px solid #2f4576;
}

/**
 * Module Permissions End
 */

.section-frontEnd .purple_gradient,
.moving .purple_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#9a75e6+0,d858bb+100 */
    background: #9a75e6; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #9a75e6 0%,
        #d858bb 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #9a75e6 0%,
        #d858bb 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #9a75e6 0%,
        #d858bb 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a75e6', endColorstr='#d858bb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.section-frontEnd .red_gradient,
.moving .red_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f07f70+0,e1422e+100 */
    background: #f07f70; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #f07f70 0%,
        #e1422e 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #f07f70 0%,
        #e1422e 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #f07f70 0%,
        #e1422e 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f07f70', endColorstr='#e1422e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.section-frontEnd .blue_gradient,
.moving .blue_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6ac6e4+0,9bf3bb+100 */
    background: #6ac6e4; /* Old browsers */
    background: -moz-linear-gradient(
        left,
        #6ac6e4 0%,
        #9bf3bb 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        #6ac6e4 0%,
        #9bf3bb 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        #6ac6e4 0%,
        #9bf3bb 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ac6e4', endColorstr='#9bf3bb',GradientType=1 ); /* IE6-9 */
}

.section-frontEnd .green_gradient,
.moving .green_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e6c088+0,61e494+100 */
    background: #e6c088; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #e6c088 0%,
        #61e494 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #e6c088 0%,
        #61e494 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #e6c088 0%,
        #61e494 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6c088', endColorstr='#61e494',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.section-frontEnd .pink_gradient,
.moving .pink_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f37c7d+0,f8b58d+100 */
    background: #f37c7d; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #f37c7d 0%,
        #f8b58d 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #f37c7d 0%,
        #f8b58d 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #f37c7d 0%,
        #f8b58d 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f37c7d', endColorstr='#f8b58d',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.section-frontEnd .dull_blue_gradient,
.moving .dull_blue_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#56afcc+0,5bd2b2+100 */
    background: #56afcc; /* Old browsers */
    background: -moz-linear-gradient(
        left,
        #56afcc 0%,
        #5bd2b2 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        #56afcc 0%,
        #5bd2b2 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        #56afcc 0%,
        #5bd2b2 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#56afcc', endColorstr='#5bd2b2',GradientType=1 ); /* IE6-9 */
}

.section-frontEnd .limegreen_gradient,
.moving .limegreen_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b9d54a+0,c4d134+100 */
    background: #b9d54a; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #b9d54a 0%,
        #c4d134 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b9d54a', endColorstr='#c4d134',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.section-frontEnd .medium_blue_gradient,
.moving .medium_blue_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3b9ae3+0,2cd0d2+100 */
    background: #3b9ae3; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #3b9ae3 0%,
        #2cd0d2 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #3b9ae3 0%,
        #2cd0d2 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #3b9ae3 0%,
        #2cd0d2 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b9ae3', endColorstr='#2cd0d2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.section-frontEnd .purple_blue_gradient,
.moving .purple_blue_gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#557ceb+0,9e71e4+100 */
    background: #557ceb; /* Old browsers */
    background: -moz-linear-gradient(
        -45deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        -45deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        135deg,
        #557ceb 0%,
        #9e71e4 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#557ceb', endColorstr='#9e71e4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

html,
body {
    scroll-behavior: smooth !important;
}

.section-frontEnd .react-datepicker__input-container {
    width: 100%;
}
.section-frontEnd button.react-datepicker__navigation {
    width: 10px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.moving {
    transition: none !important;
}

@keyframes zoomOut {
    from {
        opacity: 0;
        transform: scale(10);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-11-14 12:56:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.heartbeat {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}
@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
