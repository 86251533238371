.bodyWrap {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}
.m0 {
    margin: 0;
}
.textNormal {
    text-transform: none;
}
.textCenter {
    text-align: center;
    text-transform: none;
}
.certificateTable {
    width: 100%;
}
.certificateTAble th,
.certificateTAble td {
    padding: 6px;
    vertical-align: top;
}
.borderRound {
    background-image: url(../../../assets/img/bl.png),
        url(../../../assets/img/bt.png), url(../../../assets/img/bl.png);
    background-position: left, top, right;
    background-repeat: no-repeat, no-repeat, no-repeat;
    padding: 15px;
    /* background-image: linear-gradient(
        120deg,
        #e3e3e4 25%,
        #ffffff 25%,
        #ffffff 50%,
        #e3e3e4 50%,
        #e3e3e4 75%,
        #ffffff 75%,
        #ffffff 100%
    ); 
    background-size: 40px 69.28px;*/
}
.title {
    padding: 10px 15px;
    margin: 25px auto;
    color: #e5165f;
}
.borderTB {
    border-top: 1px solid #6e6d6d;
    border-bottom: 1px solid #6e6d6d;
}
.logos {
    display: table;
    text-align: center;
    margin: 0 auto;
}
.logoBox {
    display: table-cell;
    padding: 5px 10px;
}
.image {
    max-width: 100px;
    margin: 0 auto;
}
